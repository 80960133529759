import React, {
  Children,
  cloneElement,
  ReactNode,
  ReactElement,
  useState,
} from 'react'
import cx from 'classnames'
import './index.css'

interface ISlide {
  className?: string
  children: ReactNode
}

interface ISlider {
  dark?: boolean
  onChange?: (i: number) => void
  children: ReactNode
}

export const Slide = ({ children, className }: ISlide) => (
  <div className={cx('carousel-item', className)}>{children}</div>
)

export default ({ children, onChange, dark }: ISlider) => {
  const [active, setActiveValue] = useState(0)
  const setActive = (i: number) => {
    setActiveValue(i)
    onChange && onChange(i)
  }
  const count = Children.count(children)
  const onPrev = () => setActive(active ? active - 1 : count - 1)
  const onNext = () => setActive(active + 1 < count ? active + 1 : 0)
  return (
    <div className={cx('carousel slide', { 'carousel-light': !dark })}>
      <div className='carousel-inner'>
        {Children.map(children, (child: ReactElement<any>, i) =>
          cloneElement(child, {
            className: cx(child.props.className, {
              'carousel-item-prev': (active ? active - 1 : count - 1) === i,
              active: active === i,
              'carousel-item-next': (active + 1 < count ? active + 1 : 0) === i,
            }),
          })
        )}
      </div>
      <a
        className='carousel-control-prev cursor-pointer'
        role='button'
        data-slide='prev'
        onClick={onPrev}
      >
        <span className='carousel-control-prev-icon' aria-hidden='true' />
        <span className='sr-only'>Previous</span>
      </a>
      <a
        className='carousel-control-next cursor-pointer'
        role='button'
        data-slide='next'
        onClick={onNext}
      >
        <span className='carousel-control-next-icon' aria-hidden='true' />
        <span className='sr-only'>Next</span>
      </a>
    </div>
  )
}

import React, { Fragment } from 'react'
import Portal from '../Portal'
import ToggleDomClass from '../ToggleDomClass'

class MainError extends Portal<{ error: string }, {}> {
  constructor(props) {
    super(props)
    this.id = 'main-error'
  }
  portal() {
    const { error } = this.props
    return <p className='text-white'>{error}</p>
  }
}

export default ({ error }) => (
  <Fragment>
    <ToggleDomClass id='splash-loader' className='visible' />
    <ToggleDomClass id='splash-loader' className='stopped' />
    <MainError error={error} />
  </Fragment>
)

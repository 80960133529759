import React, { Fragment } from 'react'
import {
  ScrollPage,
  ScrollDirection,
  ScrollState,
} from '../../components/Layout'
import EarningsCard, { CurrencySymbol } from '../../components/EarningsCard'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import UsefulTips from '../../components/Tips'
import Review from '../../components/Review'
import { Footer } from '../../components/Footer'
import DeviceCountCard from './DeviceCountCard'
import NodeCountCard from './NodeCountCard'
import BoundWitnessCard from './BoundWitnessCard'
import { useTranslation } from 'react-i18next'

const tips = [] // require('../../components/Tips/useful.sh').records
const CTAButton = () => {
  const { t } = useTranslation()
  return (
    <a
      target='_blank'
      href={process.env.REACT_APP_BUY_NOW}
      className='btn btn-info text-capitalize'
      style={{ borderRadius: '1em' }}
    >
      {t('buy now')}
    </a>
  )
}

const Dashboard = () => {
  const { t } = useTranslation()
  const nav = ({ direction }: ScrollState) => (
    <Navbar hidden={direction === ScrollDirection.down} />
  )
  const bar = ({ direction }: ScrollState) => (
    <BottomBar
      className='d-md-none'
      hidden={direction === ScrollDirection.up}
    />
  )
  return (
    <ScrollPage navigationBar={nav} bottomBar={bar}>
      <Fragment>
        <div className='position-relative jumbotron bg-primary text-center text-white rounded-0 mb-0 clear-top'>
          <div className='container'>
            <EarningsCard currencySymbol={CurrencySymbol.usd} />
          </div>
        </div>
        <div className='bg-white pt-3'>
          <div className='position-relative container'>
            <div className='row mt-4'>
              <div className='offset-md-2 col-md-8'>
                <DeviceCountCard />
                <BoundWitnessCard />
                <NodeCountCard />
              </div>
            </div>
          </div>

          <UsefulTips
            cta_content={t('grow_profits_cta')}
            cta_button={<CTAButton />}
            tips={tips.map(({ fields }) => fields)}
          />

          <div className='position-relative container pb-5'>
            <Review />
          </div>
          <div className='clear-bottom d-md-none' />
        </div>
        <Footer className='d-none d-md-block' />
      </Fragment>
    </ScrollPage>
  )
}

export default Dashboard

import React, { Fragment } from 'react'
import { withMyPublicKeys } from '../../gql/queries/myPublicKeys'
import get from 'lodash/get'

interface DeviceProps {
  name: string
  count: number
}

const DeviceCount = ({ name, count }: DeviceProps) => (
  <div className='row py-2 border-bottom'>
    <div className='col text-left'>{name}</div>
    <div className='col text-right'>
      <h4>{count}</h4>
    </div>
  </div>
)

export default withMyPublicKeys(({ data, loading }) => {
  return (
    <Fragment>
      <DeviceCount
        name='Sentinels'
        count={
          loading ? (
            <span>&nbsp;</span>
          ) : (
            get(data, 'mySentinels.meta.totalCount')
          )
        }
      />
      <DeviceCount
        name='Bridges'
        count={
          loading ? <span>&nbsp;</span> : get(data, 'myBridges.meta.totalCount')
        }
      />
    </Fragment>
  )
})

import MY_DEVICES from './queries/myDevices'
import MY_DEVICE_COUNTS from './queries/myDeviceCounts'
import MY_USER_INFO from './queries/myUserInfo'
import negate from 'lodash/negate'
import uniqBy from 'lodash/uniqBy'
import set from 'lodash/set'
import get from 'lodash/get'

const isSameId = (id: string) => (obj: any) => id === get(obj, 'id')

export const addedDevice = (responsePath, typePath) => (cache, { data }) => {
  const device = get(data, responsePath)
  const myDevices = cache.readQuery({ query: MY_DEVICES })
  const myDeviceCounts = cache.readQuery({ query: MY_DEVICE_COUNTS })
  const devices = (get(myDevices, `${typePath}.items`) || []).concat(device)
  set(myDevices, `${typePath}.items`, devices)
  set(myDevices, `${typePath}.meta.totalCount`, devices.length)
  set(myDeviceCounts, `${typePath}.meta.totalCount`, devices.length)

  cache.writeQuery({
    query: MY_DEVICES,
    data: myDevices,
  })
  cache.writeQuery({
    query: MY_DEVICE_COUNTS,
    data: myDeviceCounts,
  })
}

export const deletedDevice = (cache, { data: { deleteDevice } }) => {
  const myDevices = cache.readQuery({ query: MY_DEVICES })
  const myDeviceCounts = cache.readQuery({ query: MY_DEVICE_COUNTS })
  const mySentinels = (get(myDevices, 'mySentinels.items') || []).filter(
    negate(isSameId(deleteDevice.id))
  )
  const myBridges = (get(myDevices, 'myBridges.items') || []).filter(
    negate(isSameId(deleteDevice.id))
  )
  set(myDevices, 'mySentinels.meta.totalCount', mySentinels.length)
  set(myDevices, 'myBridges.meta.totalCount', myBridges.length)
  set(myDevices, 'mySentinels.items', mySentinels)
  set(myDevices, 'myBridges.items', myBridges)
  set(myDeviceCounts, 'mySentinels.meta.totalCount', mySentinels.length)
  set(myDeviceCounts, 'myBridges.meta.totalCount', myBridges.length)

  cache.writeQuery({
    query: MY_DEVICES,
    data: myDevices,
  })
  cache.writeQuery({
    query: MY_DEVICE_COUNTS,
    data: myDeviceCounts,
  })
}

export const attachedArchivist = (
  cache,
  { data: { attachToArchivistClient } }
) => {
  const data = cache.readQuery({ query: MY_USER_INFO })
  const archivists = get(data, 'myUserInfo.archivists') || []
  set(
    data,
    'myUserInfo.archivists',
    uniqBy([].concat(archivists).concat(attachToArchivistClient), 'id')
  )
  cache.writeQuery({
    query: MY_USER_INFO,
    data,
  })
}

export const detachedArchivist = (
  cache,
  { data: { detachFromArchivistClient } }
) => {
  const data = cache.readQuery({ query: MY_USER_INFO })
  const archivists = get(data, 'myUserInfo.archivists') || []
  set(
    data,
    'myUserInfo.archivists',
    archivists.filter(({ id }) => get(detachFromArchivistClient, 'id') !== id)
  )
  cache.writeQuery({
    query: MY_USER_INFO,
    data,
  })
}

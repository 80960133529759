// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' {...props}>
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='1'
      d='M45.07,16.62v-7a2.94,2.94,0,0,0-2.94-2.94h-1V5.53a2.68,2.68,0,0,0-2.68-2.67H11.34A2.68,2.68,0,0,0,8.67,5.53V6.68h-.8A2.94,2.94,0,0,0,4.93,9.62v7a3,3,0,0,0,1,2.22,2.91,2.91,0,0,0-1,2.21v7a3,3,0,0,0,1.88,2.73,2.93,2.93,0,0,0-1.88,2.73v7a2.94,2.94,0,0,0,2.94,2.94h.8v1a2.68,2.68,0,0,0,2.67,2.67H17.7a2.68,2.68,0,0,0,2.67-2.67v-1h9v1A2.68,2.68,0,0,0,32,47.14h6.35A2.68,2.68,0,0,0,41,44.47v-1h1.1a2.94,2.94,0,0,0,2.94-2.94v-7a2.93,2.93,0,0,0-1.88-2.73,3,3,0,0,0,1.88-2.73v-7a2.91,2.91,0,0,0-1-2.21A3,3,0,0,0,45.07,16.62ZM9.59,5.53a1.76,1.76,0,0,1,1.75-1.75H38.42a1.76,1.76,0,0,1,1.76,1.75V6.68H9.59Zm9.86,38.94a1.76,1.76,0,0,1-1.75,1.75H11.34a1.76,1.76,0,0,1-1.75-1.75v-1h9.86Zm20.65,0a1.75,1.75,0,0,1-1.75,1.75H32a1.76,1.76,0,0,1-1.76-1.75v-1H40.1Zm4-10.95v7a2,2,0,0,1-2,2H7.87a2,2,0,0,1-2-2v-7a2,2,0,0,1,2-2H42.13A2,2,0,0,1,44.14,33.52Zm0-12.47v7a2,2,0,0,1-2,2H7.87a2,2,0,0,1-2-2v-7a2,2,0,0,1,.94-1.7,2.91,2.91,0,0,0,1.07.21H42.13a2.91,2.91,0,0,0,1.07-.21A2,2,0,0,1,44.14,21.05Zm0-4.43a2,2,0,0,1-.94,1.7,2.91,2.91,0,0,0-1.07-.2H7.87a2.91,2.91,0,0,0-1.07.2,2,2,0,0,1-.94-1.7v-7a2,2,0,0,1,2-2H42.13a2,2,0,0,1,2,2Z'
    />
  </svg>
)

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeviceCounts from '../../components/DeviceCounts'

export default () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <div className='d-flex'>
        <h6 className='mb-0 align-self-center text-uppercase'>
          <strong>{t('my devices')}</strong>
        </h6>
        <div className='col' />
        <Link
          to='/devices'
          className='btn bg-transparent px-3 font-weight-light text-muted d-none'
        >
          <small>
            <i className='fa fa-plus-circle mr-1' />
            <span className='text-capitalize-first d-inline-block'>
              {t('add new device')}
            </span>
          </small>
        </Link>
      </div>
      <div className='col mb-5'>
        <DeviceCounts />
      </div>
    </Fragment>
  )
}

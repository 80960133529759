/* tslint:disable:whitespace  */
// tslint:disable: import-name
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
// import { Modal, ModalContent } from '@xyo-network/tool-storybook-react'
import getOr from 'lodash/fp/getOr'
import get from 'lodash/get'

import React, { FC, Component } from 'react'
import { compose } from 'recompose'
import {
  Container,
  PageHeader,
  TitleMd,
  TitleXs,
} from '../../components/Bootstrap'
import Modal from 'reactstrap/lib/Modal'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import Button from 'reactstrap/lib/Button'
import BottomBar from '../../components/BottomBar'
import { Footer } from '../../components/Footer'
import {
  ScrollDirection,
  ScrollPage,
  ScrollState,
} from '../../components/Layout'
import Navbar from '../../components/Navbar'
import ToggleDomClass from '../../components/ToggleDomClass'
import { MY_STUFF_QUERY } from './gql'
import './index.css'
import StakingForm from './StakingForm'
import { withQuery } from 'react-apollo'
import {
  IMyStuffQueryData,
  IErrorContainerProps,
  IAlreadyStakedProps,
} from './types'

const ErrorContainer: FC<IErrorContainerProps> = (props) => {
  const { gqlErrors, error, data } = props
  if (error || !data) {
    return (
      <div className='mb-4'>
        <div className='text-center pt-5'>
          <h5>Unable to begin staking: </h5>
          <SlideInUp timer={200}>
            {gqlErrors.map((e) => {
              if (e.message === 'Your KYC must be accepted first') {
                return (
                  <div>
                    <h4 className='mb-2 text-capitalize-first' key={e.message}>
                      {e.message}
                    </h4>
                    <a className='text-info' href='/kyc'>
                      Click here to update your KYC Settings
                    </a>
                  </div>
                )
              }
              return <p key={e.message}>{e.message}</p>
            })}
          </SlideInUp>
        </div>
      </div>
    )
  }

  return <div />
}

const AlreadyStaked: FC<IAlreadyStakedProps> = (props) => {
  const { amountXyo, data } = props
  const hasXyo = amountXyo && amountXyo > 0

  if (data && !hasXyo) {
    return (
      <div className='px-5'>
        <div>
          All available XYO has already been staked. Have you completed your
          KYC? -{' '}
          <a className='text-primary' href='/kyc'>
            Click Here
          </a>{' '}
          to check
        </div>
        <div className='my-3'>
          <a
            className='mr-1 text-info'
            href='https://coin.xyo.network/crypto-pack-3-0mgnyqts8'
          >
            Click here
          </a>
          to purchase a our Crypto Starter Pack or visit
          <a className='mx-1 text-info' href='https://matrix.xyo.network/stake'>
            https://matrix.xyo.network/stake
          </a>
          to stake using XYO tokens
        </div>
      </div>
    )
  }

  return <div />
}

interface IState {
  showModal: boolean
  modalContent: string
  modalClass: string
  modalTitle: string
}

class Staking extends Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: false,
      modalContent: '',
      modalClass: 'bg-default',
      modalTitle: 'Success',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  handleSubmit(error, message) {
    this.setState({
      showModal: true,
      modalContent: message,
      modalClass: !error ? 'bg-success' : 'bg-danger',
      modalTitle: !error ? 'Success!' : 'Error',
    })
  }

  closeModal() {
    location.reload()
  }

  render() {
    const { data, error, refetch } = this.props
    if (data.loading) {
      return <ToggleDomClass id='splash-loader' className='visible' />
    }

    const { showModal, modalContent, modalClass } = this.state
    const amountXyo = get(data, 'availableXyoToStake.amountXyo')
    const amountEth = get(data, 'availableXyoToStake.amountEth')
    const divinersMeta = get(data, 'myDiviners.meta')
    const archivistsMeta = get(data, 'myArchivists.meta')
    const bridgesMeta = get(data, 'myBridges.meta')
    const sentinelsMeta = get(data, 'mySentinels.meta')
    const gqlErrors = getOr([], 'graphQLErrors', error) as any[]
    const nav = ({ direction }: ScrollState) => (
      <Navbar hidden={direction === ScrollDirection.down} />
    )
    const bar = ({ direction }: ScrollState) => (
      <BottomBar
        className='d-md-none'
        hidden={direction === ScrollDirection.up}
      />
    )

    return (
      <ScrollPage navigationBar={nav} bottomBar={bar} handleRefresh={refetch}>
        <div className='position-relative row'>
          <div className='col-12'>
            <PageHeader>
              <Container>
                <TitleMd>staking</TitleMd>
              </Container>
              <TitleXs>
                <SlideInUp>staking</SlideInUp>
              </TitleXs>
            </PageHeader>
            <div className='flex-grow-1 bg-white pt-3'>
              <Container className='pb-4'>
                <ErrorContainer
                  error={error}
                  data={data}
                  gqlErrors={gqlErrors}
                />
                <AlreadyStaked data={data} amountXyo={amountXyo} />
                <StakingForm
                  amount={amountXyo || 0}
                  amountEth={amountEth || 0}
                  {...{
                    sentinelsMeta: sentinelsMeta || [],
                    bridgesMeta: bridgesMeta || [],
                    archivistsMeta: archivistsMeta || [],
                    divinersMeta: divinersMeta || [],
                  }}
                  onSuccess={this.handleSubmit}
                />
              </Container>
            </div>
          </div>
        </div>
        <div className='col'>
          <Footer className='d-none d-md-block' />
        </div>
        <Modal isOpen={showModal} className={modalClass}>
          <ModalHeader className={modalClass}>Success</ModalHeader>
          <ModalBody>{modalContent}</ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.closeModal}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </ScrollPage>
    )
  }
}

export default compose(withQuery<IMyStuffQueryData>(MY_STUFF_QUERY))(Staking)

import React from 'react'
import Node from '../../icons/Node'
import TOTAL_NODES from '../../gql/queries/totalNodes'
import { useTranslation } from 'react-i18next'
import { Query } from 'react-apollo'
import get from 'lodash/get'

export default () => {
  const { t } = useTranslation()
  return (
    <div className='pb-5'>
      <div className='d-flex position-relative bg-white shadow-sm rounded p-3 align-items-center'>
        <div
          className='rounded-circle shadow'
          style={{ width: 60, height: 60 }}
        >
          <Node />
        </div>
        <h6 className='mb-0 ml-2 text-uppercase'>
          <strong>{t('total xyo nodes')}</strong>
        </h6>
        <div className='col' />
        <h6 className='mb-0 text-success'>
          <Query query={TOTAL_NODES} variables={{ limit: 0 }}>
            {({ data, loading }) =>
              loading ? (
                <strong>&nbsp;</strong>
              ) : (
                <strong>{get(data, 'countNodes.count')}</strong>
              )}
          </Query>
        </h6>
      </div>
    </div>
  )
}

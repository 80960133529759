// tslint:disable: import-name
import gql from 'graphql-tag'

export const MY_AVAILABLE_XYO_QUERY = gql`
  query MyAvailableXyoQuery {
    availableXyoToStake {
      amountXyo
    }
  }
`

export const PAGE_FRAGMENT = gql`
  fragment PageFragment on Page {
    meta {
      totalCount
      totalPages
      cursor
      cursorBack
    }
  }
`

export const DEVICE_FRAGMENT = gql`
  fragment DeviceFragment on Address {
    publicKey
    name
  }
`

export const MY_DIVINERS_QUERY = gql`
  query MyDivinersQuery($pagination: Paginate) {
    myDiviners(paginate: $pagination) {
      ...PageFragment
      items {
        ...DeviceFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
  ${DEVICE_FRAGMENT}
`

export const MY_ARCHIVISTS_QUERY = gql`
  query MyArchivistsQuery($pagination: Paginate) {
    myArchivists(paginate: $pagination) {
      items {
        ...DeviceFragment
      }
      ...PageFragment
    }
  }
  ${PAGE_FRAGMENT}
  ${DEVICE_FRAGMENT}
`

export const MY_BRIDGES_QUERY = gql`
  query MyBridgesQuery($pagination: Paginate) {
    myBridges(paginate: $pagination) {
      items {
        ...DeviceFragment
      }
      ...PageFragment
    }
  }
  ${PAGE_FRAGMENT}
  ${DEVICE_FRAGMENT}
`

export const MY_SENTINELS_QUERY = gql`
  query MySentinelsQuery($pagination: Paginate) {
    mySentinels(paginate: $pagination) {
      items {
        ...DeviceFragment
      }
      ...PageFragment
    }
  }
  ${PAGE_FRAGMENT}
  ${DEVICE_FRAGMENT}
`

export const MY_STUFF_QUERY = gql`
  query MyStuffQuery {
    availableXyoToStake {
      amountXyo
      amountEth
    }
    myDiviners {
      ...PageFragment
    }
    myArchivists {
      ...PageFragment
    }
    myBridges {
      ...PageFragment
    }
    mySentinels {
      ...PageFragment
    }
  }
  ${PAGE_FRAGMENT}
`

export const ADD_NETWORK_STAKE_MUTATION = gql`
  mutation AddNetworkStake($beneficiaryAddress: String!, $stakees: [Stakee!]!) {
    addNetworkStake(
      beneficiaryAddress: $beneficiaryAddress
      stakees: $stakees
    ) {
      amountXyo
      beneficiaryAddress
      bondId
      transactionHash
      blockNumber
      createdAt
      updatedAt
    }
  }
`

export const idQueryMap = {
  diviners: MY_DIVINERS_QUERY,
  archivists: MY_ARCHIVISTS_QUERY,
  bridges: MY_BRIDGES_QUERY,
  sentinels: MY_SENTINELS_QUERY,
}

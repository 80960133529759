/* tslint:disable:whitespace  */
// tslint:disable: import-name
// tslint:disable: align
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import cx from 'classnames'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import pick from 'lodash/fp/pick'
import reduce from 'lodash/fp/reduce'
import size from 'lodash/fp/size'
import toPairs from 'lodash/fp/toPairs'
import React, { useEffect, useState } from 'react'
import { Mutation } from 'react-apollo'

import Nav from 'reactstrap/lib/Nav'
import NavItem from 'reactstrap/lib/NavItem'
import NavLink from 'reactstrap/lib/NavLink'
import TabContent from 'reactstrap/lib/TabContent'
import TabPane from 'reactstrap/lib/TabPane'

import Loader from '../../components/Loader'
import { useInitWeb3 } from '../../web3'
import DeviceList from './DeviceList'
import DeviceHeader from './DeviceHeader'
import deviceTypes from './deviceTypes'

import { ADD_NETWORK_STAKE_MUTATION } from './gql'
import {
  IAddNetworkStakeMutationData,
  IAddNetworkStakeMutationVars,
  IAllocatedStake,
  INode,
  IStakingForm,
} from './types'

const isComingSoon = false
const maxDevices = 10

const isAddress = function(address) {
  return !!/^(0x)?[0-9a-f]{40}$/i.test(address)
}

export const ourDiviner: INode = {
  publicKey: '0x0808ee2ad4F9B396ecED74dD7C411eb40b4A5FAA',
  name: 'betanode',
}

const clamp = (min, val, max) => Math.min(Math.max(val, min), max)

export const myDeviceTypes = pick(
  ['diviners', 'archivists', 'bridges', 'sentinels'],
  deviceTypes
)

const getStakees = flow(
  toPairs,
  filter(([, percent]) => percent !== 0),
  map(([address, percent]) => ({ address, percent }))
)

const getTotal = (stake) => {
  return reduce((acc, [, v]: [any, number]) => acc + v, 0, toPairs(stake))
}

const StakingForm = ({ amount, amountEth, ...props }: IStakingForm) => {
  if (!amount || amount <= 0) {
    return <div />
  }

  const [allocatedStake, setAllocatedStake] = useState<IAllocatedStake>({
    [ourDiviner.publicKey]: 100,
  })

  const [beneficiaryAddress, setBeneficiaryAddress] = useState('')
  const [metamaskError, setMetamaskError] = useState()
  const [activeTab, setActiveTab] = useState('xyoDiviner')

  const {
    initWeb3,
    web3: { initialized, value },
  } = useInitWeb3()

  useEffect(() => {
    if (initialized && value) {
      value.eth
        .getAccounts()
        .then((accounts) => {
          const account =
            accounts === undefined || accounts[0] === undefined
              ? null
              : accounts[0]
          if (!account) throw new Error('No accounts found in browser wallet.')
          setBeneficiaryAddress(account)
        })
        .catch((err) => {
          console.error(err)
          setMetamaskError(err.message)
        })
    }
  }, [initialized, value])

  const handleUnstakeAddressChange = (e: any) => {
    setBeneficiaryAddress(e.target.value)
  }

  const handleChange = (key, value) => {
    const newAllocatedStake = { ...allocatedStake, [key]: clamp(0, value, 100) }
    setAllocatedStake(newAllocatedStake)
  }

  const total = getTotal(allocatedStake)
  const stakees = getStakees(allocatedStake)

  const validAddress = isAddress(beneficiaryAddress)

  return (
    <div>
      <SlideInUp>
        <p>How would you like to allocate your {amount} XYO?</p>
        <p>
          {amountEth > 0 ? (
            <div>You will also receive {amountEth.toFixed(4)} ETH.</div>
          ) : (
            <div />
          )}
        </p>
        <p className='text-muted'>You can stake up to {maxDevices} devices.</p>
      </SlideInUp>
      <SlideInUp timer={200}>
        <Nav tabs={true} className='mt-3'>
          {toPairs(deviceTypes).map(([id, { label }]) => {
            const onClick = () => setActiveTab(id)
            const isActive = activeTab === id
            return (
              <NavItem key={id}>
                <NavLink
                  className={cx(
                    'staking-nav-tab',
                    isActive
                      ? 'text-primary staking-nav-tab-active'
                      : 'text-muted staking-nav-tab-inactive',
                    {
                      active: isActive,
                    }
                  )}
                  onClick={onClick}
                >
                  {id === 'xyoDiviner'
                    ? label
                    : `My ${label.toUpperCase()}s (${
                        props[`${id}Meta`].totalCount
                      })`}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {toPairs(deviceTypes).map(([id, { label }]) => {
            const meta = props[`${id}Meta`]
            return (
              <TabPane className='position-relative' key={id} tabId={id}>
                <DeviceHeader
                  name={id === 'xyoDiviner' ? label : `my ${label}s`}
                  count={id === 'xyoDiviner' ? 1 : meta.totalCount}
                />
                <DeviceList
                  label={label}
                  id={id}
                  handleChange={handleChange}
                  allocatedStake={allocatedStake}
                />
              </TabPane>
            )
          })}
        </TabContent>
        <hr />
        <div className='row mx-0 mb-2'>
          <div
            className={cx(
              'col-12 col-md-3 d-flex align-items-center pl-0',
              size(stakees) > maxDevices && 'border-bottom border-danger'
            )}
          >
            Total allocation for {size(stakees)} devices
          </div>
          <div
            className={cx(
              'col-4 col-md-3 pr-0 offset-8 offset-md-3',
              total !== 100 && 'border-bottom border-danger'
            )}
          >
            {total}%
          </div>
        </div>
        {total !== 100 && (
          <p className='mt-3 text-danger'>Total allocation must equal 100%</p>
        )}
        {size(stakees) > maxDevices && (
          <p className='mt-3 text-danger'>
            You may not stake more than {maxDevices} at once
          </p>
        )}
        <hr />
      </SlideInUp>
      <SlideInUp timer={400}>
        <div className='form-group'>
          <div>
            <p>Paste your public wallet address below</p>
          </div>
          <div className='col-12 col-md-6 px-0 mb-3'>
            <label className='sr-only' htmlFor='unstakeAddress'>
              Public Wallet Address
            </label>
            <input
              className='form-control'
              type='text'
              id='unstakeAddress'
              placeholder='0x32...'
              value={beneficiaryAddress}
              onChange={handleUnstakeAddressChange}
            />
          </div>
          <div>
            <button
              className='btn btn-outline-primary'
              onClick={initWeb3}
              disabled={initialized}
            >
              {initialized ? 'Initialized!' : 'Fill in from metamask'}
            </button>
            {metamaskError && <p>{metamaskError}</p>}
            <div>
              {validAddress ? (
                <span className='text-success'>Success: Valid Address!</span>
              ) : (
                <span className='text-danger'>Error: Address not valid</span>
              )}
            </div>
          </div>
        </div>
      </SlideInUp>
      <SlideInUp timer={600}>
        <div className='row mx-0 mt-5'>
          <div className='col-12 col-md-4 px-0'>
            <Mutation<
              IAddNetworkStakeMutationData,
              IAddNetworkStakeMutationVars
            >
              mutation={ADD_NETWORK_STAKE_MUTATION}
            >
              {(addNetworkStake, { loading, data, error }) => {
                const submitStakingForm = async() => {
                  if (validAddress) {
                    await addNetworkStake({
                      variables: {
                        beneficiaryAddress,
                        stakees,
                      },
                    })
                    let message = `Successfully staged staking event for ${amount} XYO tokens. `
                    if (amountEth) {
                      message += `${amountEth} ETH will be transferred to your account. `
                    }

                    message +=
                      'You will receive an email within 24 hours with the Block ID so that you can look up the transaction'

                    props.onSuccess(error, message)
                  }
                }
                return (
                  <div>
                    <button
                      className='btn btn-primary w-100'
                      onClick={submitStakingForm}
                      disabled={
                        total !== 100 ||
                        size(stakees) > maxDevices ||
                        !validAddress
                      }
                    >
                      {loading ? (
                        <Loader />
                      ) : isComingSoon ? (
                        'COMING SOON'
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                )
              }}
            </Mutation>
          </div>
        </div>
      </SlideInUp>
    </div>
  )
}

export default StakingForm

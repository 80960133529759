import gql from 'graphql-tag'

export default gql`
  mutation AddBridge(
    $id: String!
    $name: String
    $photoUrl: String
    $publicKey: String
    $uuid: String
    $major: Int
    $minor: Int
    $dns: String
    $port: Int
  ) {
    addBridge(
      address: {
        id: $id
        publicKey: $publicKey
        name: $name
        photoUrl: $photoUrl
        dns: $dns
        port: $port
      }
      bridge: { uuid: $uuid, major: $major, minor: $minor }
    ) {
      id
      name
      photoUrl
      publicKey
      uuid
      major
      minor
      dns
      port
    }
  }
`

import gql from 'graphql-tag'

export default gql`
  mutation AddFeedback($value: Int!, $text: String) {
    addFeedback(value: $value, text: $text) {
      id
      owner
      value
      text
      created
      updated
    }
  }
`

const deviceTypes = {
  xyoDiviner: {
    label: 'XYO Diviner',
  },
  diviners: {
    label: 'diviner',
  },
  archivists: {
    label: 'archivist',
  },
  bridges: {
    label: 'bridge',
  },
  sentinels: {
    label: 'sentinel',
  },
}

export default deviceTypes

import React, { Fragment } from 'react'
import ErrorBoundary from '../ErrorBoundary'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import { FadeIn } from '../Animate'
import Checkbox from '../Checkbox'
import cx from 'classnames'
import ProfileQuery from './profileQuery'
import ProfileMutation from './profileMutation'
import { useBlockyImage } from '@xyo-network/tool-storybook-react/dist/lib/Blockie'
import get from 'lodash/get'

const Blocky = ({ seed }) => {
  const src = useBlockyImage(seed)
  return <img src={src} className='w-100' />
}

const callWith = (fn, iteratee, path = '') => ev =>
  fn(iteratee(path ? get(ev, path) : ev))

const ProfileError = ({ error }) => {
  return (
    <div
      className={cx('alert', {
        'alert-danger': error,
        'mb-4': error,
      })}
    >
      <p className='mb-0'>{error}</p>
    </div>
  )
}

const ProfileAnonymousCheckbox = ({ isAnonymous, onIsAnonymousChange }) => {
  return (
    <Checkbox
      checked={isAnonymous}
      onChange={onIsAnonymousChange}
      label={
        isAnonymous
          ? "Anonymous account is set. You're like a ghost right now!"
          : 'Make my account anonymous. Ooooh Secrets!'
      }
    />
  )
}

const optimisticUpdate = (name: string, data) => ({
  __typename: 'Mutation',
  updateMyUserInfo: {
    __typename: name,
    ...data,
  },
})

const ProfileCard = () => {
  // const { user, error, onPhotoUrlChange } = profileHook()
  const E = error => <ProfileError error={error} />
  return (
    <ErrorBoundary fallback={E}>
      <ProfileQuery>
        {({ data, loading, error }) => (
          <ProfileMutation>
            {updateUserInfo =>
              loading ? (
                <div className='text-center'>
                  <Loader />
                </div>
              ) : error ? (
                <ProfileError error={error.message} />
              ) : (
                    <Fragment>
                      <FadeIn
                        active={!data.myUserInfo.isAnonymous}
                        className='border rounded shadow-sm p-3 my-4 overflow-hidden'
                      >
                        <div>
                          <div
                            className='position-relative bg-dark rounded m-auto mb-2 d-flex align-items-center justify-content-center overflow-hidden'
                            style={{ height: 60, width: 60 }}
                          >
                            <Blocky seed={data.myUserInfo.publicKey} />
                            {/* <input
                        type='file'
                        accept='image/*'
                        onChange={callWith(
                          console.log.bind(console),
                          ev => ev.target.value
                        )}
                        className='full-absolute opacity-0'
                      /> */}
                          </div>
                          <div className='form-group'>
                            <label htmlFor='email' className='text-muted'>
                              Full Name
                        </label>
                            <input
                              id='full-name'
                              type='text'
                              name='displayName'
                              className='form-control'
                              onChange={callWith(
                                updateUserInfo,
                                displayName => ({
                                  variables: { displayName },
                                  optimisticResponse: optimisticUpdate('UserInfo', {
                                    ...data.myUserInfo,
                                    displayName,
                                  }),
                                }),
                                'target.value'
                              )}
                              value={data.myUserInfo.displayName || ''}
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='public-key' className='text-muted'>
                              Public Key
                        </label>
                            <input
                              id='public-key'
                              type='text'
                              name='publicKey'
                              className='form-control'
                              onChange={callWith(
                                updateUserInfo,
                                publicKey => ({
                                  variables: { publicKey },
                                  optimisticResponse: optimisticUpdate('UserInfo', {
                                    ...data.myUserInfo,
                                    publicKey,
                                  }),
                                }),
                                'target.value'
                              )}
                              value={data.myUserInfo.publicKey || ''}
                            />
                          </div>

                          <ProfileAnonymousCheckbox
                            isAnonymous={!!data.myUserInfo.isAnonymous}
                            onIsAnonymousChange={callWith(
                              updateUserInfo,
                              checked => ({
                                variables: {
                                  isAnonymous: Boolean(checked),
                                },
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  updateMyUserInfo: {
                                    __typename: 'UserInfo',
                                    ...data.myUserInfo,
                                    isAnonymous: Boolean(checked),
                                  },
                                },
                              }),
                              'target.checked'
                            )}
                          />
                        </div>
                      </FadeIn>
                      <FadeIn
                        className='text-center'
                        active={!!data.myUserInfo.isAnonymous}
                      >
                        <img
                          className='mb-4'
                          style={{ height: 200 }}
                          src={`${process.env.PUBLIC_URL}/images/bag.png`}
                          alt='Anonymous Bag'
                        />
                        <ProfileAnonymousCheckbox
                          isAnonymous={!!data.myUserInfo.isAnonymous}
                          onIsAnonymousChange={callWith(
                            updateUserInfo,
                            checked => ({
                              variables: { isAnonymous: Boolean(checked) },
                              optimisticResponse: optimisticUpdate('UserInfo', {
                                ...data.myUserInfo,
                                isAnonymous: Boolean(checked),
                              }),
                            }),
                            'target.checked'
                          )}
                        />
                      </FadeIn>
                    </Fragment>
                  )
            }
          </ProfileMutation>
        )}
      </ProfileQuery>
    </ErrorBoundary>
  )
}

export default ProfileCard

import React from 'react'
import googlePlay from './GooglePlay.svg'
import appStore from './AppStore.svg'
import cx from 'classnames'

interface Props {
  className?: string
}

export const GoToGooglePlay = ({ className }: Props) => {
  return (
    <a
      className={cx('btn p-0', className)}
      target='_blank'
      href={process.env.REACT_APP_GOOGLE_PLAY}
    >
      <img
        src={googlePlay}
        alt='Get it on Google Play'
        style={{ height: 40 }}
      />
    </a>
  )
}
export const GoToAppStore = ({ className }: Props) => {
  return (
    <a
      className={cx('btn p-0', className)}
      target='_blank'
      href={process.env.REACT_APP_APP_STORE}
    >
      <img src={appStore} alt='Get it on Google Play' style={{ height: 40 }} />
    </a>
  )
}

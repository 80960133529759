import React, { Fragment, useState } from 'react'
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom'
import { SlideInRight, SlideInLeft, FadeIn } from '../Animate'
import Notifications from '../Notifications'
import Account from '../Account'
import cx from 'classnames'
import './index.css'
import Home from '../../icons/Home'
import BoundWitness from '../../icons/BoundWitness'
import Devices from '../../icons/Devices'
import Menu from '../../icons/Menu'
import Archivist from '../../icons/Archivist'
// import NotificationsIcon from '../../icons/Notifications'
import { useTranslation } from 'react-i18next'

interface NavbarProps {
  goBack?: boolean
  hidden?: boolean
  transparent?: boolean
}

const Navbar = ({
  goBack,
  history,
  hidden,
  transparent,
}: RouteComponentProps & NavbarProps) => {
  const { t } = useTranslation()
  const onGoBack = () => history.goBack()
  const [menu, setMenu] = useState(false)
  const [alert, setAlert] = useState(false)
  const setMenuOpen = () => setMenu(true)
  const setMenuClosed = () => setMenu(false)
  // const setAlertOpen = () => setAlert(true)
  const setAlertClosed = () => setAlert(false)
  const setAllClosed = () => {
    setAlertClosed()
    setMenuClosed()
  }

  return (
    <Fragment>
      <nav
        className={cx(
          'navbar navbar-dark navbar-expand-md bg-md-secondary border-0 position-absolute w-100 top-0 p-0',
          {
            'navbar-hidden': hidden,
            'bg-primary': !transparent,
          }
        )}
      >
        {goBack ? (
          <button
            className='bg-transparent text-white border-0 cursor-pointer m-2'
            type='button'
            data-toggle='collapse'
            aria-expanded='false'
            aria-label='Go back'
            onClick={onGoBack}
          >
            <i className='fa fa-arrow-left' />
          </button>
        ) : (
          <button
            className='bg-transparent text-white border-0 cursor-pointer m-2'
            type='button'
            data-toggle='collapse'
            aria-expanded={menu}
            aria-label='Open menu'
            onClick={setMenuOpen}
          >
            <Menu className='top-icon' />
          </button>
        )}
        <div className='collapse navbar-collapse align-self-stretch align-items-stretch'>
          <div className='navbar-nav mr-auto'>
            <NavLink
              className='nav-item d-flex align-items-center'
              activeClassName='bg-opaque active'
              to='/'
              exact={true}
            >
              <span className='d-flex nav-link align-items-center px-4 py-0'>
                <Home className='top-nav-icon mr-2' />
                <span className='text-capitalize'>{t('dashboard')}</span>
              </span>
            </NavLink>
            <NavLink
              className='nav-item d-flex align-items-center'
              activeClassName='bg-opaque active'
              to='/bound-witness'
              exact={true}
            >
              <span className='d-flex nav-link align-items-center px-4 py-0'>
                <BoundWitness className='top-nav-icon mr-2' />
                <span className='text-capitalize'>{t('bound witness')}</span>
              </span>
            </NavLink>
            {/* <NavLink
              className='nav-item d-flex align-items-center'
              activeClassName='bg-opaque active'
              to='/nearby'
              exact={true}
            >
              <span className='d-flex nav-link align-items-center px-4 py-0'>
                <Nearby className='top-nav-icon mr-2' />
                <span className='text-capitalize'>{t('nearby')}</span>
              </span>
            </NavLink> */}
            <NavLink
              className='nav-item d-flex align-items-center'
              activeClassName='bg-opaque active'
              to='/devices'
              exact={true}
            >
              <span className='d-flex nav-link align-items-center px-4 py-0'>
                <Devices className='top-icon mr-2' />
                <span className='text-capitalize'>{t('devices')}</span>
              </span>
            </NavLink>
            <NavLink
              className='nav-item d-flex align-items-center'
              activeClassName='bg-opaque active'
              to='/archivists'
              exact={true}
            >
              <span className='d-flex nav-link align-items-center px-4 py-0'>
                <Archivist className='top-icon mr-2' />
                <span className='text-capitalize'>{t('archivists')}</span>
              </span>
            </NavLink>
          </div>
        </div>
        {/* <button
          className='bg-transparent text-white border-0 cursor-pointer m-2'
          onClick={setAlertOpen}
        >
          <NotificationsIcon className='top-icon' />
        </button> */}
      </nav>
      <FadeIn
        className='sidebar-shadow'
        onClick={setAllClosed}
        active={menu || alert}
      />
      <SlideInRight className='sidebar sidebar-left bg-white' active={menu}>
        <Account />
      </SlideInRight>
      <SlideInLeft className='sidebar sidebar-right bg-primary' active={alert}>
        <Notifications />
      </SlideInLeft>
    </Fragment>
  )
}

export default withRouter(Navbar)

// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' {...props}>
    <path
      fillOpacity='0'
      stroke='currentColor'
      strokeWidth='1'
      strokeOpacity='1'
      fillRule='evenodd'
      d='M13,2H5L1,9l4,7h8l4-7L13,2z'
    />
    <path
      fillOpacity='0'
      stroke='currentColor'
      strokeWidth='1'
      strokeOpacity='1'
      fillRule='evenodd'
      d='M11.5,4.6h-5L4,9l2.5,4.4h5L14,9L11.5,4.6z'
    />
  </svg>
)

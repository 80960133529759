// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
    <path
      fill='currentColor'
      strokeOpacity='0'
      fillOpacity='1'
      d='M21,4v24h6V4H21z M23,6h2v20h-2V6z M13,11v17h6V11H13z M15,13h2v13h-2V13z M5,18v10h6V18H5z M7,20h2v6H7V20z'
    />
  </svg>
)

import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import createHttpLink from './httpLink'

const authLink = (uri: string) =>
  setContext((o: any, { headers }) => {
    try {
      return {
        headers: {
          ...headers,
          'X-Redirect': uri,
        },
      }
    } catch (e) {
      return { headers }
    }
  })

export default (uri: string) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink(uri).concat(
      createHttpLink(process.env.REACT_APP_PROXY_SERVER || '', true)
    ),
  })

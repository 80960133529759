import React, { Component, ReactNode } from 'react'
import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'
import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'

interface Props {
  fallback: ({ error: string }) => ReactNode
  children: ReactNode
}

export default class ErrorBoundary extends Component<Props> {
  state = {
    error: '',
  }
  componentDidCatch(err, info) {
    console.log(err)
    this.setState({ error: err.message })
  }
  render() {
    const { fallback } = this.props
    const { error } = this.state
    if (error && fallback) return fallback({ error })
    if (error) {
      return <Alert type={BootstrapType.danger}>{error}</Alert>
    }
    return this.props.children
  }
}

import React from 'react'
import get from 'lodash/fp/get'
import {
  SlideInUp,
  SlideInDown,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

const KYCMaxAttempts = ({ location }) => {
  const error = get(['state', 'data', 'attempts', 'error'], location)

  return (
    <>
      <SlideInUp timer={200}>
        <h4 className='mt-0 text-uppercase'>
          We're sorry, an error has occurred
        </h4>
      </SlideInUp>
      <SlideInDown timer={200}>
        <p>{error}</p>
      </SlideInDown>
    </>
  )
}

export default KYCMaxAttempts

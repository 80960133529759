// tslint:disable: object-shorthand-properties-first
import { useReducer } from 'react'
import { bindActionCreators } from 'redux'

export interface IAsyncState<D> {
  loading: boolean
  data: D | null
  error: string
}

export const asyncState = (loading = false, data = null, error = '') => ({
  loading,
  data,
  error,
})

interface IAsyncActions<D> {
  setLoading(): IType
  setData(d: D): IType & IPayload<D>
  setError(e: string): IType & IPayload<string>
}

interface IType {
  type: string
}

interface IPayload<D> {
  payload: D
}

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_DATA = 'SET_DATA'

export const setLoading = () => ({ type: SET_LOADING })
export const setError = (payload: string): IType & IPayload<string> => ({
  type: SET_ERROR,
  payload,
})
export const setData = <D>(payload: D): IType & IPayload<D> => ({
  type: SET_DATA,
  payload,
})
const actions = { setLoading, setError, setData }

export const asyncReducer = (state = asyncState(), action: any) => {
  switch (action.type) {
    case SET_LOADING:
      return asyncState(true)
    case SET_DATA:
      return asyncState(false, action.payload)
    case SET_ERROR:
      return asyncState(false, null, action.payload)
    default:
      return state
  }
}

export const useAsyncReducer = <D>(): [IAsyncState<D>, IAsyncActions<D>] => {
  const [state, dispatch] = useReducer(asyncReducer, asyncState())
  const boundActions = bindActionCreators(actions, dispatch as any)
  return [state, boundActions]
}

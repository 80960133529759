import gql from 'graphql-tag'

export default gql`
  mutation UpdateFeedback($id: String!, $value: Int, $text: String) {
    updateFeedback(id: $id, value: $value, text: $text) {
      id
      owner
      value
      text
      created
      updated
    }
  }
`

import React from 'react'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import Loader from '../../../components/Loader'

const KYCPending = ({ location }) => {
  const { data } = location.state
  const {
    attempts: {
      dailyAttempts,
      totalAttempts,
      maxDailyAttempts,
      maxTotalAttempts,
    },
  } = data
  return (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>Your request is being processed</h4>
      </SlideInDown>
      <div className='d-flex align-items-center justify-content-center my-3'>
        <Loader />
      </div>
      <SlideInUp timer={200}>
        <p>
          Please be patient while your request is being processed. The process
          may take a few minutes. Upon completion, this page will automatically
          update.
        </p>
      </SlideInUp>
      <SlideInUp timer={400}>
        <small>
          <p>
            <strong>Daily Attempt Disclaimer:</strong> You are allowed{' '}
            {maxDailyAttempts} KYC submission attempts per day. You are
            currently on attempt {dailyAttempts} out of {maxDailyAttempts} daily
            submissions allowed.
          </p>
          <p>
            <strong>Total Attmept Disclaimer:</strong> You are allowed{' '}
            {maxTotalAttempts} overall KYC submission attempts. You are
            currently on attempt {totalAttempts} out of {maxTotalAttempts} total
            submissions allowed.
          </p>
        </small>
      </SlideInUp>
    </>
  )
}

export default KYCPending

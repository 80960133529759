import React from 'react'
import { Link } from 'react-router-dom'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

const KYCApproved = () => {
  return (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>Approved</h4>
      </SlideInDown>
      <SlideInUp timer={200}>
        <p>Congratulations! Your KYC application has been approved!</p>
      </SlideInUp>
      <SlideInUp timer={400}>
        <Link className='btn btn-primary' to='/staking'>
          Begin staking
        </Link>
      </SlideInUp>
    </>
  )
}

export default KYCApproved

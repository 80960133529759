import React from 'react'

interface Props {
  checked: boolean
  label: string
  onChange: (ev: React.FormEvent<HTMLInputElement>) => void
}

export default ({ checked, onChange, label }: Props) => (
  <label className='custom-check-container'>
    <input
      type='checkbox'
      className='custom-check-input'
      id='anonymous'
      checked={!!checked}
      onChange={onChange}
    />

    <span className='custom-check' />
    {label}
  </label>
)

// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15.4375 14.40625'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M7.314350128173828,0.38983154296875 L7.71875,0 L8.123149871826172,0.38983154296875 L15.4375,7.76129150390625 L14.628700256347656,8.576416015625 L13.907814025878906,7.84991455078125 L13.907814025878906,14.40625 L8.844036102294922,14.40625 L8.844036102294922,8.73590087890625 L6.593463897705078,8.73590087890625 L6.593463897705078,14.40625 L1.5296821594238281,14.40625 L1.5296821594238281,7.84991455078125 L0.8087997436523438,8.576416015625 L0,7.76129150390625 L7.314350128173828,0.38983154296875 L7.314350128173828,0.38983154296875ZM12.78253173828125,6.7158203125 L7.71875,1.61248779296875 L2.65496826171875,6.7158203125 L2.65496826171875,13.27215576171875 L5.468181610107422,13.27215576171875 L5.468181610107422,7.601806640625 L9.969318389892578,7.601806640625 L9.969318389892578,13.27215576171875 L12.78253173828125,13.27215576171875 L12.78253173828125,6.7158203125 L12.78253173828125,6.7158203125Z'
    />
  </svg>
)

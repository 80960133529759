// tslint:disable: import-name
// tslint:disable: interface-name
import React, { Component } from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { Page, Main } from '../../components/Layout'
import { SignUp } from '@xyo-network/tool-storybook-react/dist/lib/auth'
import { SlideInDown } from '@xyo-network/tool-storybook-react/dist/lib/Animate'

interface SignUpProps {}

class SignUpContainer extends Component<RouteComponentProps & SignUpProps> {
  onSuccess = () => {
    this.props.history.push('/')
  }
  goToLogin = () => {
    this.props.history.push('/login')
  }
  render() {
    return (
      <Page>
        <Main>
          <div className='col' />
          <SlideInDown>
            <Link to='/' className='btn text-white text-left'>
              <h4>
                <i className='fa fa-times' />
              </h4>
            </Link>
          </SlideInDown>
          <SignUp
            onSuccess={this.onSuccess}
            goToLogin={this.goToLogin}
            className='text-white'
            inputClassName='text-white'
            buttonClassName='btn-info'
            loaderClassName='text-white'
          />
          <div className='col mb-4' />
        </Main>
      </Page>
    )
  }
}

export default withRouter(SignUpContainer)

import gql from 'graphql-tag'

export interface ITimeAggregate {
  count: number
  year: number
  month: number
  day: number
  hour: number
}

export default gql`
  fragment TimeAggregate on TimeAggregate {
    count
    year
    month
    day
    hour
  }
`

import gql from 'graphql-tag'

export default gql`
  mutation AttachToArchivistClient(
    $multiaddr: String
    $dns: String
    $ip: String
    $port: Int
  ) {
    attachToArchivistClient(
      archivist: { multiaddr: $multiaddr, port: $port, dns: $dns, ip: $ip }
    ) {
      id
      name
      publicKey
      multiaddr
    }
  }
`

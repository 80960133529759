import gql from 'graphql-tag'

export default gql`
  query MyUserInfo {
    myUserInfo {
      id
      displayName
      email
      photoURL
      publicKey
      isAnonymous
      defaultArchivistId
      archivists {
        id
        name
        publicKey
        dns
        port
      }
    }
  }
`

// tslint:disable: jsx-no-lambda
// tslint:disable: import-name
// tslint:disable: align

import React, { Fragment, useState, useEffect } from 'react'
import { ScrollPage, ScrollDirection } from '../../components/Layout'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import requestDevice from '../../utils/bluetooth'
import mockNearbyDevice from './mocks'
import { NearbyDevice } from './types'
import Device from './Device'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { Footer } from '../../components/Footer'
import set from 'lodash/set'

export default () => {
  const [nearbyDevices, setNearbyDevices] = useState([] as NearbyDevice[])
  const [loading, setLoading] = useState(true)

  const onRequestDevice = () => {
    setLoading(true)
    requestDevice()
      .then((device: any) => {
        console.log(device)
        set(window, 'xyDevice', device)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const id = setTimeout(() => {
      setLoading(false)
      setNearbyDevices(mockNearbyDevice)
    }, 500)
    return () => clearTimeout(id)
  }, [])

  return (
    <ScrollPage
      navigationBar={({ direction }) => (
        <Navbar hidden={direction === ScrollDirection.down} />
      )}
      bottomBar={({ direction }) => (
        <BottomBar
          hidden={direction === ScrollDirection.up}
          className='d-md-none'
        />
      )}
    >
      <Fragment>
        <div className='position-relative bg-primary bg-md-transparent mb-2 clear-top pb-3'>
          <div className='container'>
            <h4 className='d-none d-md-block'>NEARBY DEVICES</h4>
          </div>
          <h4 className='d-md-none text-center text-white'>NEARBY DEVICES</h4>
        </div>
        <div className='container'>
          <button className='btn m-2' onClick={onRequestDevice}>
            Find Device
          </button>
        </div>
        <div className='container flex-grow-1'>
          {loading && (
            <div className='text-center text-primary'>
              <Loader />
            </div>
          )}
          {nearbyDevices.map((device: NearbyDevice, i) => (
            <SlideInUp key={i} timer={i * 100}>
              <Device {...device} />
            </SlideInUp>
          ))}
        </div>
        <div className='pt-5 pb-3' />

        <Footer className='d-none d-md-block' />
      </Fragment>
    </ScrollPage>
  )
}

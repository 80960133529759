// tslint:disable: ter-arrow-parens
import { useEffect } from 'react'
import {
  useAsyncReducer,
  setError,
  IAsyncState,
} from '../../utils/asyncReducer'
import chunk from 'lodash/chunk'
import times from 'lodash/times'
import isNumber from 'lodash/isNumber'

interface IScannerState {
  percent: string
  progress: number
  results?: number[]
}

export const useScanFactory = (ping, count, size = 20) => (
  ...args
): [IAsyncState<IScannerState>, (unsub?: boolean) => void] => {
  const [state, { setData }] = useAsyncReducer<IScannerState>()
  const scan = (unsubscribed: boolean) => {
    if (args.some((v) => v)) {
      chunk(
        times(count, (i) => i),
        size
      )
        .reduce(async(acc, curr, i) => {
          const results = await acc
          const data = await Promise.all(
            curr.map((i: number) =>
              ping(...args, i)
                .then(() => i)
                .catch(() => null)
            )
          )
          const newResults = results.concat(data.filter(isNumber))
          const progress = Math.min((i + 1) * size, count) / count
          const percent = `${Math.ceil(progress * 100)}%`
          if (!unsubscribed) {
            setData({ progress, percent, results: newResults })
          }
          return newResults
        },      Promise.resolve([] as number[]))
        .catch((e) => setError(e.message))
    }
  }
  useEffect(() => {
    let unsubscribed = false
    scan(unsubscribed)
    return () => {
      unsubscribed = true
    }
  },        args)

  return [state, scan]
}

import React, { createContext, useContext, ReactNode } from 'react'
import client from './appClient'

export const Context = createContext(client)
export const XYOClientProvider = ({ children }: { children: ReactNode }) => {
  return <Context.Provider value={client}>{children}</Context.Provider>
}
export const useXyoClient = () => {
  return useContext(Context)
}

import { Component } from 'react'

interface Props {
  timer?: number
}

export enum Status {
  ENTERING = 'ENTERING',
  ENTERED = 'ENTERED',
  EXITING = 'EXITING',
  EXITED = 'EXITED',
}

export default class WillEnter<T> extends Component<Props & T> {
  timer: number
  timerId: any
  state = {
    status: Status.EXITED,
  }
  constructor(props) {
    super(props)
    this.timer = typeof this.props.timer === 'number' ? this.props.timer : 350
  }
  onEntering = () => {
    this.setState({
      status: Status.ENTERING,
    })
  }
  onEntered = () => {
    this.setState({ status: Status.ENTERED })
  }
  onExiting = () => {
    this.setState({ status: Status.EXITING })
  }
  onExited = () => {
    this.setState({ status: Status.EXITED })
  }
  componentWillUnmount() {
    clearTimeout(this.timerId)
  }
  componentWillReceiveProps(props) {
    if (this.props['show'] === props.show) return
    if (props.show) {
      this.onEntering()
    } else {
      this.onExiting()
    }
    clearTimeout(this.timerId)
    this.timerId = setTimeout(() => {
      if (props.show) {
        this.onEntered()
      } else {
        this.onExited()
      }
    }, 350)
  }
}

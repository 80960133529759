// tslint:disable: jsx-no-lambda
// tslint:disable: import-name
// tslint:disable: jsx-wrap-multiline
import React, { useState, Children, Fragment } from 'react'
import { ScrollPage, ScrollDirection } from '../../components/Layout'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import Modal, {
  ModalContent,
} from '@xyo-network/tool-storybook-react/dist/lib/Modal'
import { MySentinel, MyBridge } from './types'
import MY_DEVICES_QUERY from '../../gql/queries/myDevices'
import Device from './Device'
import get from 'lodash/get'
import BottomDrawer from '../../components/BottomDrawer'
import LoadingDevice from './LoadingDevice'
import AddBridge from './AddBridge'
import AddSentinel from './AddSentinel'
import DeviceSettings from './Settings'
import { GoToGooglePlay, GoToAppStore } from '../../components/AppButtons'
import {
  Container,
  PageHeader,
  TitleMd,
  TitleXs,
} from '../../components/Bootstrap'
import { Query } from 'react-apollo'
import { Footer } from '../../components/Footer'
import {
  hasBluetooth,
  findBridge,
  findSentinel,
  IBluetoothDevice,
  USER_CANCELED_ERROR,
} from '../../utils/bluetooth'
import { useTranslation } from 'react-i18next'
import {
  SlideInLeft,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import Devices from '../../icons/Devices'
import Nearby from '../../icons/Nearby'
import size from 'lodash/size'
import cx from 'classnames'
import Banner from '../../components/Banner'

enum ModalType {
  none = 'NONE',
  sentinel = 'SENTINEL',
  bridge = 'BRIDGE',
  app = 'APP',
  web = 'WEB',
}

const isSameId = (id: string) => (obj: any) => get(obj, 'id') === id

const DeviceHeader = ({ name, loading, data }) => {
  const { t } = useTranslation()
  return (
    <div className='d-flex border-bottom pb-2 mb-4'>
      <h4 className='mb-0 px-2 text-uppercase'>{name}</h4>
      <div className='col' />
      {!loading && (
        <p className='text-muted mb-0 px-2 text-capitalize'>
          {size(data)} {t('connected devices')}
        </p>
      )}
    </div>
  )
}

const DeviceRow = ({ children }) => (
  <div className='row pb-4'>
    {Children.map(children, (child, i) => (
      <div className='col-6 col-sm-4 col-md-3 col-lg-2 mb-4' key={i}>
        {Children.only(child)}
      </div>
    ))}
  </div>
)

const NoneFound = ({ text, icon }) => (
  <div className='mb-4'>
    <div className='text-center pt-5'>
      <SlideInUp>{icon}</SlideInUp>
      <SlideInUp timer={200}>
        <h4 className='mb-0 text-capitalize-first'>{text}</h4>
      </SlideInUp>
    </div>
  </div>
)

const AddDeviceButton = ({ children, onClick }) => {
  return (
    <div className='text-center mb-5'>
      <button className='btn bg-transparent text-uppercase' onClick={onClick}>
        <i className='fa fa-plus-circle' /> {children}
      </button>
    </div>
  )
}

const LoadingDevices = (n = 8) =>
  [...Array(n)].map((a, i) => <LoadingDevice key={i} />)

export default () => {
  const { t } = useTranslation()
  const [selectedDeviceId, setSelectedDevice] = useState('')
  const [bannerError, setBannerError] = useState('')
  const [activeModal, setActiveModal] = useState(ModalType.none)
  const [device, setDevice] = useState<IBluetoothDevice | null>(null)
  const closeModal = () => setActiveModal(ModalType.none)
  const closeBottomDrawer = () => setSelectedDevice('')
  const nav = ({ direction }) => (
    <Navbar hidden={direction === ScrollDirection.down} />
  )
  const bar = ({ direction }) => (
    <BottomBar
      hidden={direction === ScrollDirection.up}
      className='d-md-none'
    />
  )
  const handleBleError = (e: any) => {
    console.log(e.message, e.code)
    if (e.message === USER_CANCELED_ERROR) return
    setBannerError(e.message)
  }
  const openAddBridgeModal = async() => {
    setBannerError('')
    if (!hasBluetooth()) {
    }
    // return setActiveModal(ModalType.web)
    return setActiveModal(ModalType.app)
    try {
      const _device = await findBridge()
      setDevice(_device)
      _device && setActiveModal(ModalType.bridge)
    } catch (e) {
      handleBleError(e)
    }
  }
  const openAddSentinelModal = async() => {
    setBannerError('')
    if (!hasBluetooth()) {
    }
    return setActiveModal(ModalType.app)
    try {
      const _device = await findSentinel()
      setDevice(_device)
      _device && setActiveModal(ModalType.sentinel)
    } catch (e) {
      handleBleError(e)
    }
  }

  return (
    <Query query={MY_DEVICES_QUERY} fetchPolicy='cache-and-network'>
      {({ data, loading, error, refetch }: any) => {
        const selectedDevice = (get(data, 'mySentinels.items') || [])
          .concat(get(data, 'myBridges.items') || [])
          .find(isSameId(selectedDeviceId))
        return (
          <ScrollPage
            handleRefresh={refetch}
            navigationBar={nav}
            bottomBar={bar}
          >
            <Fragment>
              <Modal open={activeModal !== ModalType.none} onClose={closeModal}>
                {activeModal === ModalType.bridge ? (
                  <ModalContent
                    className='overflow-hidden'
                    headerClassName='bg-info text-white rounded-0'
                    title={
                      <span className='text-capitalize'>{t('add bridge')}</span>
                    }
                  >
                    <AddBridge device={device} onSuccess={closeModal} />
                  </ModalContent>
                ) : activeModal === ModalType.sentinel ? (
                  <ModalContent
                    className='overflow-hidden'
                    headerClassName='bg-info text-white rounded-0'
                    title={
                      <span className='text-capitalize'>
                        {t('add sentinel')}
                      </span>
                    }
                  >
                    <AddSentinel device={device} onSuccess={closeModal} />
                  </ModalContent>
                ) : activeModal === ModalType.web ? (
                  <ModalContent
                    className='overflow-hidden'
                    headerClassName='bg-info text-white rounded-0'
                    title={
                      <span className='text-capitalize'>
                        {t('visit in chrome')}
                      </span>
                    }
                  >
                    <p className='text-capitalize-first'>
                      {t('incompatible_chrome')}
                    </p>
                    <button className='btn btn-primary' onClick={closeModal}>
                      Close
                    </button>
                  </ModalContent>
                ) : activeModal === ModalType.app ? (
                  <ModalContent
                    className='overflow-hidden'
                    headerClassName='bg-info text-white rounded-0'
                    title={
                      <span className='text-capitalize'>
                        {t('download the app')}
                      </span>
                    }
                  >
                    <p className='text-capitalize-first'>
                      {t('incompatible_app')}
                    </p>
                    <GoToAppStore className='mr-1' />
                    <GoToGooglePlay />
                  </ModalContent>
                ) : (
                  <div />
                )}
              </Modal>
              <BottomDrawer
                className='d-md-none'
                backdropClassName='d-md-none'
                show={!!selectedDevice}
                onClose={closeBottomDrawer}
              >
                {selectedDevice ? (
                  <div className='bg-white rounded-top shadow-sm overflow-hidden px-2 pt-2 pb-5'>
                    <DeviceSettings
                      device={selectedDevice}
                      onClose={closeBottomDrawer}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </BottomDrawer>
              {bannerError ? (
                <Banner className='bg-secondary bg-md-primary text-white p-2'>
                  {bannerError}
                </Banner>
              ) : (
                ''
              )}
              <div className='position-relative row'>
                <div
                  className={cx({
                    ['col-md-8 pr-0']: !!selectedDevice,
                    ['col-12']: !selectedDevice,
                  })}
                >
                  <PageHeader>
                    <Container>
                      <TitleMd>{t('my devices')}</TitleMd>
                    </Container>
                    <TitleXs>
                      <SlideInUp>{t('my devices')}</SlideInUp>
                    </TitleXs>
                  </PageHeader>
                  <div className='flex-grow-1 bg-white pt-3'>
                    <Container>
                      <DeviceHeader
                        loading={loading}
                        name={t('bridges')}
                        data={get(data, 'myBridges.items')}
                      />
                      {loading ? (
                        <DeviceRow>{LoadingDevices(1)}</DeviceRow>
                      ) : size(get(data, 'myBridges.items')) ? (
                        <DeviceRow>
                          {get(data, 'myBridges.items').map(
                            (device: MyBridge, i) => (
                              <SlideInLeft key={i} timer={i * 200}>
                                <Device
                                  active={selectedDeviceId === device.id}
                                  onClick={() => setSelectedDevice(device.id)}
                                  {...device}
                                />
                              </SlideInLeft>
                            )
                          )}
                        </DeviceRow>
                      ) : (
                        <NoneFound
                          text={t('no bridges found')}
                          icon={<Nearby className='large-icon' />}
                        />
                      )}
                      <AddDeviceButton onClick={openAddBridgeModal}>
                        {t('connect more bridges')}
                      </AddDeviceButton>

                      <DeviceHeader
                        loading={loading}
                        name={t('sentinels')}
                        data={get(data, 'mySentinels.items')}
                      />
                      {loading ? (
                        <DeviceRow>{LoadingDevices(1)}</DeviceRow>
                      ) : size(get(data, 'mySentinels.items')) ? (
                        <DeviceRow>
                          {get(data, 'mySentinels.items').map(
                            (device: MySentinel, i) => (
                              <SlideInLeft key={i} timer={i * 200}>
                                <Device
                                  active={selectedDeviceId === device.id}
                                  onClick={() => setSelectedDevice(device.id)}
                                  {...device}
                                />
                              </SlideInLeft>
                            )
                          )}
                        </DeviceRow>
                      ) : (
                        <NoneFound
                          text={t('no sentinels found')}
                          icon={<Devices className='large-icon' />}
                        />
                      )}
                      <AddDeviceButton onClick={openAddSentinelModal}>
                        {t('connect more sentinels')}
                      </AddDeviceButton>
                    </Container>
                    <div className='pt-5 pb-3' />
                  </div>
                </div>
                {selectedDevice ? (
                  <div className='d-none d-md-block col-md-4 pl-0 border-left bg-white'>
                    <div className='px-2 py-5'>
                      <div className='text-right'>
                        <button className='btn' onClick={closeBottomDrawer}>
                          X
                        </button>
                      </div>
                      <DeviceSettings
                        device={selectedDevice}
                        onClose={closeBottomDrawer}
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className='col'>
                  <Footer className='d-none d-md-block' />
                </div>
              </div>
            </Fragment>
          </ScrollPage>
        )
      }}
    </Query>
  )
}

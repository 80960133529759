// tslint:disable: import-name
// tslint:disable: object-shorthand-properties-first
import cx from 'classnames'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import capitalize from 'lodash/fp/capitalize'
import React, { FC, useState } from 'react'
import { Query } from 'react-apollo'
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

import Loader from '../../components/Loader'
import { idQueryMap } from './gql'
import { ourDiviner } from './StakingForm'
import {
  IDeviceList,
  IDeviceListQuery,
  IMyDevicesData,
  IMyDevicesVariables,
} from './types'
import { useBlockyImage } from '@xyo-network/tool-storybook-react/dist/lib/Blockie'

const limit = 10

const DeviceList: FC<IDeviceList> = ({
  devices,
  meta,
  handleChange,
  allocatedStake,
  label,
  id,
  pageForward,
  pageBack,
  loading,
}) => {
  if (get('totalCount', meta) === 0) {
    return (
      <div className='row mx-0 my-2'>
        <div className='col px-0'>No devices of type {label} found.</div>
      </div>
    )
  }
  const cursorBack = get('cursorBack', meta)
  const canGoBack = cursorBack !== ''
  const prev = () => {
    if (pageBack && cursorBack) {
      pageBack(cursorBack)
    }
  }
  const cursor = get('cursor', meta)
  const canGoForward = cursor !== ''
  const next = () => {
    if (pageForward && cursor) {
      pageForward(cursor)
    }
  }

  return (
    <>
      {loading && (
        <div className='staking-devices-loader'>
          <Loader />
        </div>
      )}
      {devices.map(({ publicKey, name }) => {
        const blocky = useBlockyImage(publicKey)
        const handleSentinelChange = (e) => {
          handleChange(publicKey, e.target.value)
        }
        return (
          <div key={publicKey} className='row mx-0 mb-2'>
            <div className='col-12 col-md-3 p-0'>
              <UncontrolledTooltip
                target={`tooltip-${publicKey}`}
                autohide={false}
              >
                Public Key: {publicKey}
              </UncontrolledTooltip>
              <div
                id={`tooltip-${publicKey}`}
                className={cx(
                  'd-flex w-100 cursor-pointer align-items-center justify-content-start'
                )}
              >
                {blocky && (
                  <img
                    className='staking-device-img mr-3'
                    src={blocky}
                    alt={name}
                  />
                )}
                <div className='text-center p-1 rounded single-line'>
                  {name || publicKey}
                </div>
              </div>
            </div>
            <div className='col-8 col-md-3 pl-0 d-flex align-items-center'>
              <div className='form-group mb-0 w-100'>
                <label className='sr-only' htmlFor={`range-${publicKey}`}>
                  {name || publicKey}
                </label>
                <input
                  type='range'
                  className='form-control-range'
                  id={`range-${publicKey}`}
                  min={0}
                  max={100}
                  step={1}
                  value={allocatedStake[publicKey] || 0}
                  onChange={handleSentinelChange}
                />
              </div>
            </div>
            <div className='col-4 col-md-3 pr-0'>
              <label className='sr-only' htmlFor={`allocate-${publicKey}`}>
                {name || publicKey}
              </label>
              <div className='input-group'>
                <input
                  className='form-control'
                  type='number'
                  id={`allocate-${publicKey}`}
                  value={allocatedStake[publicKey] || 0}
                  min={0}
                  max={100}
                  onChange={handleSentinelChange}
                />
                <div className='input-group-append'>
                  <div className='input-group-text rounded-0 border-top-0 border-right-0 bg-white'>
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {id !== 'xyoDiviner' && (
        <div className='row mx-0 mb-2'>
          <div className='col-12 col-md-2 d-flex align-items-center justify-content-between px-0'>
            <button
              disabled={!canGoBack || loading}
              className={cx(
                'btn btn-outline-primary',
                (!canGoBack || loading) && 'disabled'
              )}
              onClick={prev}
            >
              Previous
            </button>
            <button
              disabled={!canGoForward || loading}
              className={cx(
                'btn btn-outline-primary',
                (!canGoForward || loading) && 'disabled'
              )}
              onClick={next}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const DeviceListQuery: FC<IDeviceListQuery> = ({ ...props }) => {
  const { id } = props
  const [pagination, setPaginationState] = useState({
    limit,
    cursor: '',
    cursorBack: '',
  })
  if (id === 'xyoDiviner') {
    return (
      <DeviceList devices={[ourDiviner]} meta={{ totalCount: 1 }} {...props} />
    )
  }

  return (
    <Query<IMyDevicesData, IMyDevicesVariables>
      query={idQueryMap[id]}
      variables={{ pagination }}
    >
      {({ data, loading, error, refetch }) => {
        if (error || !data) {
          console.log({ error })
          const onClick = () => refetch()
          return (
            <div>
              Error.{' '}
              <button className='btn btn-outline-primary' onClick={onClick}>
                Try again?
              </button>
            </div>
          )
        }

        const { items: devices, meta } = getOr(
          { items: [], meta: {} },
          `my${capitalize(id)}`,
          data
        )

        const pageBack = (cursorBack) => {
          const newPaginationState = { limit, cursor: '', cursorBack }
          setPaginationState(newPaginationState)
          refetch({ pagination: newPaginationState })
        }

        const pageForward = (cursor) => {
          const newPaginationState = { limit, cursor, cursorBack: '' }
          setPaginationState(newPaginationState)
          refetch({ pagination: newPaginationState })
        }

        return (
          <DeviceList
            devices={devices}
            meta={meta}
            {...props}
            {...{
              pageForward,
              pageBack,
              loading,
            }}
          />
        )
      }}
    </Query>
  )
}

export default DeviceListQuery

// tslint:disable: import-name
// tslint:disable: jsx-wrap-multiline
import React from 'react'
import './index.css'

export default () => (
  <div className='card p-3 shadow-sm cursor-pointer'>
    <div className='device-img mb-3 animated-background' />
    {
      <div className='bg-success text-center text-white p-1 rounded animated-background'>
        &nbsp;
      </div>
    }
  </div>
)

// tslint:disable: interface-name
// tslint:disable: ter-arrow-parens
declare global {
  export const BluetoothUUID: any
}

export const USER_CANCELED_ERROR = 'User cancelled the requestDevice() chooser.'
export const NETWORK_SERVICE_UUID = '00010000-89bd-43c8-9231-40f6e305f96d'
export const CONNECT_UUID = '00010001-89bd-43c8-9231-40f6e305f96d'
export const SSID_UUID = '00010010-89bd-43c8-9231-40f6e305f96d'
export const IP_UUID = '00010020-89bd-43c8-9231-40f6e305f96d'
export const STATUS_UUID = '00010030-89bd-43c8-9231-40f6e305f96d'
export const SCAN_UUID = '00010040-89bd-43c8-9231-40f6e305f96d'
export const SENTINEL_SERVICE_UUID = 'a44eacf4-0104-0001-0000-5f784c9977b5'
export const SENTINEL_COLOR_UUID = 'a44eacf4-0104-0001-0015-5f784c9977b5'

const createCharacteristicUuidMap = () => {
  try {
    const manufacturer_name_string = BluetoothUUID.getCharacteristic(
      'manufacturer_name_string'
    )
    const model_number_string = BluetoothUUID.getCharacteristic(
      'model_number_string'
    )
    const hardware_revision_string = BluetoothUUID.getCharacteristic(
      'hardware_revision_string'
    )
    const firmware_revision_string = BluetoothUUID.getCharacteristic(
      'firmware_revision_string'
    )
    const software_revision_string = BluetoothUUID.getCharacteristic(
      'software_revision_string'
    )
    const system_id = BluetoothUUID.getCharacteristic('system_id')
    const regulatory_certification_data_list = BluetoothUUID.getCharacteristic(
      'ieee_11073-20601_regulatory_certification_data_list'
    )
    const pnp_id = BluetoothUUID.getCharacteristic('pnp_id')

    return {
      [manufacturer_name_string]: 'manufacturer_name_string',
      [model_number_string]: 'model_number_string',
      [hardware_revision_string]: 'hardware_revision_string',
      [firmware_revision_string]: 'firmware_revision_string',
      [software_revision_string]: 'software_revision_string',
      [system_id]: 'system_id',
      [regulatory_certification_data_list]:
        'regulatory_certification_data_list',
      [pnp_id]: 'pnp_id',
    }
  } catch (e) {
    return {}
  }
}

const CharacteristicUuidMap = createCharacteristicUuidMap()

const CharacteristicDecodeMap = {
  system_id: (value: DataView) => {
    const manufacturer_id = [
      padHex(value.getUint8(4)) + padHex(value.getUint8(3)),
      padHex(value.getUint8(2)) + padHex(value.getUint8(1)),
      padHex(value.getUint8(0)),
    ].join(' ')

    const organization_uuid = [
      padHex(value.getUint8(7)) + padHex(value.getUint8(6)),
      padHex(value.getUint8(5)),
    ].join(' ')

    return {
      manufacturer_id,
      organization_uuid,
    }
  },
  pnp_id: (value: DataView) => {
    const vendor_id_source = value.getUint8(0) === 1 ? 'Bluetooth' : 'USB'
    const vendor_id = value.getUint8(1) | (value.getUint8(2) << 8)
    const product_id = value.getUint8(3) | (value.getUint8(4) << 8)
    const product_version = value.getUint8(5) | (value.getUint8(6) << 8)
    return { vendor_id_source, vendor_id, product_id, product_version }
  },
}

interface Characteristic {
  uuid: string
  readValue: () => Promise<ArrayBuffer>
}

export const hasBluetooth = () => {
  return !!('bluetooth' in navigator)
}

const isBluetoothEnabled = () => {
  if ('bluetooth' in navigator) {
    const bluetooth = (navigator as any).bluetooth as any
    return Promise.resolve(bluetooth)
  }

  return Promise.reject(new Error('Bluetooth is required'))
}

const readCharacteristicValues = (characteristics: Characteristic[]) => {
  const decoder = new TextDecoder('utf-8')
  return Promise.all(
    characteristics.map((characteristic: Characteristic) => {
      const key =
        CharacteristicUuidMap[characteristic.uuid] || characteristic.uuid
      const format =
        CharacteristicDecodeMap[key] || ((v: ArrayBuffer) => decoder.decode(v))
      return characteristic
        .readValue()
        .then((value) => ({ [key]: format(value) }))
    })
  ).then((results) => results.reduce((acc, c) => ({ ...acc, ...c }), {}))
}

// const readCharacteristic = async (characteristic, service) => {
//   const data = await service.getCharacteristic(characteristic)
//   const value = await data.readValue()
//   return value
// }
interface IBluetoothGatt {
  connect: () => Promise<any>
}
export interface IBluetoothDevice {
  id: string
  name: string
  gatt: IBluetoothGatt
  color?: string
}

export interface IBluetoothDeviceInfo {
  level: number
}

export const findBridge = async(
  name?: string
): Promise<IBluetoothDevice | null> => {
  const filters: any[] = [
    {
      services: [NETWORK_SERVICE_UUID],
    },
  ]
  if (name) {
    filters.push({ name })
  }
  const ble = await isBluetoothEnabled()
  const device = await ble.requestDevice({
    filters,
  })
  return device
}

export const findSentinel = async(): // name?: string
Promise<IBluetoothDevice | null> => {
  // const filters: any[] = [
  //   {
  //     services: [NETWORK_SERVICE_UUID],
  //   },
  // ]
  // if (name) {
  //   filters.push({ name })
  // }
  const ble = await isBluetoothEnabled()
  const device = await ble.requestDevice({
    acceptAllDevices: true,
    optionalServices: [SENTINEL_SERVICE_UUID],
  })
  return device
}

export default async function connectSentinel(): Promise<
  (IBluetoothDevice & IBluetoothDeviceInfo) | null
> {
  try {
    const device = await findSentinel()
    if (!device) throw new Error('User did not select a device')
    console.log('Found device', device)
    const server = await device.gatt.connect()
    console.log('Connected device')
    const information = await server.getPrimaryService('device_information')
    console.log('Connected device information')
    const battery = await server.getPrimaryService('battery_service')
    const attributes = await server.getPrimaryService(SENTINEL_SERVICE_UUID)
    console.log('Connected battery information')
    // const txPower = await server.getPrimaryService('tx_power')
    // console.log('Connected txPower information')
    const informationChars = await information.getCharacteristics()
    const batteryChars = await battery.getCharacteristics()
    const colorChar = await attributes.getCharacteristic(SENTINEL_COLOR_UUID)
    // const txPowerChars = await txPower.getCharacteristics()
    let color = ''
    try {
      const colorVal = await colorChar.readValue()
      console.log(colorChar, colorVal)
      color = colorVal.getUint8(0)
    } catch (e) {
      console.log(e)
    }
    const info = await readCharacteristicValues(informationChars)
    const level = (await batteryChars[0].readValue()).getUint8(0)
    // const power = (await txPowerChars[0].readValue()).getUint8(0)
    console.log({ device, info, level })
    return {
      id: device.id,
      name: device.name,
      gatt: device.gatt,
      ...info,
      color,
      level,
    }
  } catch (e) {
    console.log(e.message)
    return null
  }
}

function padHex(value: any) {
  return `00${value.toString(16).toUpperCase()}`.slice(-2)
}

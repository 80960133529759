// tslint:disable: import-name
// tslint:disable: interface-name
import React, { ReactNode, Fragment } from 'react'
import { NearbyDevice } from './types'
import cx from 'classnames'

enum BWType {
  unlinked = 'bg-danger',
  linked = 'bg-success',
  default = 'bg-dark',
}

interface Props {
  type: BWType
  children: ReactNode
  onClick?: () => void
}

const BWButton = ({ type, children, onClick }: Props) => (
  <button
    onClick={onClick}
    className={cx(
      'btn rounded d-flex align-items-center justify-content-center m-1 text-white',
      type
    )}
    style={{ height: 40, width: 40 }}
  >
    {children}
  </button>
)

export default ({ name, latest_connection }: NearbyDevice) => (
  <div className='border-bottom p-4'>
    <div className='row'>
      <div className='col'>
        <p className='mb-0'>{name}</p>
        <p className='mb-0'>Mac Address</p>
        <p className='mb-0'>UUID#</p>
      </div>
      <div className='col'>
        <p className='mb-0 text-center text-muted'>
          {latest_connection
            ? `Last Connected: ${latest_connection}`
            : 'Unknown last connection'}
        </p>
      </div>
      <div className='col'>
        <div className='row p-2 justify-content-end'>
          {latest_connection ? (
            <Fragment>
              <BWButton type={BWType.default}>
                <p className='mb-0'>BW</p>
              </BWButton>
              <BWButton type={BWType.linked}>
                <i className='fa fa-link' />
              </BWButton>
            </Fragment>
          ) : (
            <BWButton type={BWType.unlinked}>
              <i className='fa fa-unlink' />
            </BWButton>
          )}
        </div>
      </div>
    </div>
  </div>
)

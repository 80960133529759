// tslint:disable: import-name
import React, { Suspense } from 'react'
import Router from './routes'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import ToggleDomClass from './components/ToggleDomClass'
import XYOWeb3Provider, { InitWeb3Provider } from './web3'
import client from './gql/appClient'
import { BannerMount } from './components/Banner'
import OnlineStatus from './components/OnlineStatus'

export default () => {
  return (
    <Suspense
      fallback={<ToggleDomClass id='splash-loader' className='visible' />}
    >
      <ApolloProvider client={client}>
        <XYOWeb3Provider>
          <InitWeb3Provider>
            <div className='d-flex fill-available flex-column'>
              <OnlineStatus />
              <BannerMount />
              <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                <Router />
              </BrowserRouter>
            </div>
          </InitWeb3Provider>
        </XYOWeb3Provider>
      </ApolloProvider>
    </Suspense>
  )
}

/* tslint:disable:whitespace  */
/* tslint:disable:import-name  */
/* tslint:disable:jsx-wrap-multiline  */
/* tslint:disable:ter-arrow-parens  */
import React, { Fragment, useState, FormEvent, useMemo } from 'react'
import {
  ScrollPage,
  ScrollDirection,
  ScrollState,
} from '../../components/Layout'
import Card, { CardBody } from '@xyo-network/tool-storybook-react/dist/lib/Card'
import CardHead from '@xyo-network/tool-storybook-react/dist/lib/Card/Head'
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { useBlockyImage } from '@xyo-network/tool-storybook-react/dist/lib/Blockie'
import Modal, {
  ModalContent,
} from '@xyo-network/tool-storybook-react/dist/lib/Modal'
import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'
import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'
import TextInput from '@xyo-network/tool-storybook-react/dist/lib/Form/Text'
import NumberInput from '@xyo-network/tool-storybook-react/dist/lib/Form/Number'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import { Footer } from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import MY_USER_INFO from '../../gql/queries/myUserInfo'
import UPDATE_MY_USER_INFO from '../../gql/mutations/updateMyUserInfo'
import ARCHIVIST_INFO from '../../gql/queries/archivistInfo'
import ATTACH_ARCHIVIST from '../../gql/mutations/attachArchivist'
import DETACH_ARCHIVIST from '../../gql/mutations/detachArchivist'
import { Query, Mutation, QueryResult } from 'react-apollo'
import createArchivistClient from '../../gql/archivistClient'
import { Collapsible } from '@xyo-network/tool-storybook-react/dist/lib/Collapse'
import { attachedArchivist, detachedArchivist } from '../../gql/cache'
import Banner from '../../components/Banner'
import ArchivistIcon from '../../icons/Archivist'
import over from 'lodash/over'
import get from 'lodash/get'
import size from 'lodash/size'
// import identity from 'lodash/identity'

const archivistGraphqlUri = ({ ip, dns, multiaddr, port, ssl }) => {
  const protocol = ssl ? 'https' : 'http'
  if (dns && port) return `${protocol}://${dns}:${port}`
  if (ip && port) return `${protocol}://${ip}:${port}`
  if (multiaddr) {
    const parts = multiaddr.split('/')
    return `${protocol}://${parts[2]}:${parts[4]}`
  }
  return ''
}

const DisplayName = ({ name, value }) =>
  value ? (
    <p>
      <strong>{name}</strong> {value}
    </p>
  ) : (
    <div />
  )

const NoneFound = ({ text, icon }) => (
  <div className='mb-4'>
    <div className='text-center pt-5'>
      <SlideInUp>{icon}</SlideInUp>
      <SlideInUp timer={200}>
        <h4 className='mb-0 text-capitalize-first'>{text}</h4>
      </SlideInUp>
    </div>
  </div>
)

const Archivist = ({ archivist, onDetach, onDefault, isDefault }) => {
  const [open, setOpen] = useState(false)
  // const showDetails = () => setOpen(true)
  // const hideDetails = () => setOpen(false)
  const toggleDetails = () => setOpen(!open)
  const blocky = useBlockyImage(archivist.publicKey)
  const archivistUri = archivistGraphqlUri(archivist || {})
  const client = useMemo(() => createArchivistClient(archivistUri), [
    archivistUri,
  ])
  const renderIcon = () => (
    <img className='bg-primary rounded shadow' src={blocky} />
  )
  const renderTitle = () => (
    <Fragment>
      <p className='ml-2 mb-0 hash-overflow'>
        {archivist.name || archivist.publicKey}{' '}
      </p>
      {isDefault ? <span className='ml-2 text-muted'>(Default)</span> : ''}
    </Fragment>
  )
  const renderAction = ({ data, loading, error }: QueryResult) => () => {
    if (loading) return <Loader />
    if (error) return <i className='fa fa-times text-danger' />
    return <i className='fa fa-check text-success' />
  }
  return (
    <Query client={client} query={ARCHIVIST_INFO}>
      {(req) => (
        <Card className='shadow mb-3 cursor-pointer'>
          <CardHead
            className='align-items-center'
            renderIcon={renderIcon}
            renderTitle={renderTitle}
            renderAction={renderAction(req)}
            onClick={toggleDetails}
          />
          <Collapsible show={open}>
            <CardBody>
              <Alert type={BootstrapType.danger}>
                {get(req, 'error.message')}
              </Alert>
              <DisplayName name='Name' value={get(req, 'data.about.name')} />
              <DisplayName
                name='Address'
                value={get(req, 'data.about.address')}
              />
              <DisplayName
                name='Version'
                value={get(req, 'data.about.version')}
              />
              {isDefault ? (
                ''
              ) : (
                <button className='btn btn-success mr-2' onClick={onDefault}>
                  Set As Default
                </button>
              )}
              <button className='btn btn-danger' onClick={onDetach}>
                Detach
              </button>
            </CardBody>
          </Collapsible>
        </Card>
      )}
    </Query>
  )
}

const preventDefault = (fn) => (ev: FormEvent) => {
  if (ev) ev.preventDefault()
  fn(ev)
}

const AddArchivist = ({ onSuccess }) => {
  return (
    <Mutation
      mutation={ATTACH_ARCHIVIST}
      update={over([attachedArchivist, onSuccess])}
    >
      {(attachArchivist, { data, loading, error }: any) => (
        <form
          onSubmit={preventDefault((ev) =>
            attachArchivist({
              variables: {
                dns: ev.target.dns.value,
                port: Number(ev.target.port.value),
              },
            })
          )}
        >
          <TextInput label='IP address or DNS name' name='dns' />
          <NumberInput label='Port' name='port' />
          <Alert type={BootstrapType.danger}>{get(error, 'message')}</Alert>
          {loading ? (
            <Loader />
          ) : (
            <button className='btn btn-primary' type='submit'>
              Save
            </button>
          )}
        </form>
      )}
    </Mutation>
  )
}

const DetachArchivist = ({ id, onSuccess }) => {
  const detach = (fn) => () => fn()
  return (
    <Mutation
      mutation={DETACH_ARCHIVIST}
      update={over([detachedArchivist, onSuccess])}
      variables={{ id }}
    >
      {(detachArchivist, { data, loading, error }: any) => (
        <button
          className='btn btn-danger'
          type='submit'
          onClick={detach(detachArchivist)}
          disabled={loading}
        >
          Detach
        </button>
      )}
    </Mutation>
  )
}

const SetDefaultArchivist = ({ id, onSuccess }) => {
  const onClick = (fn) => () => fn()
  return (
    <Mutation
      mutation={UPDATE_MY_USER_INFO}
      update={onSuccess}
      variables={{ defaultArchivistId: id }}
    >
      {(setDefaultArchivistId, { loading }) => (
        <button
          className='btn btn-success mr-2'
          type='submit'
          onClick={onClick(setDefaultArchivistId)}
          disabled={loading}
        >
          Confirm
        </button>
      )}
    </Mutation>
  )
}

export default () => {
  const { t } = useTranslation()
  const [addArchivist, setAddArchivist] = useState(false)
  const [deleteArchivist, setDeleteArchivist] = useState(null)
  const [primaryArchivist, setPrimaryArchivist] = useState(null)
  const openAddArchivistModal = () => setAddArchivist(true)
  const closeAddArchivistModal = () => setAddArchivist(false)
  const openDeleteArchivistModal = (id) => () => setDeleteArchivist(id)
  const closeDeleteArchivistModal = () => setDeleteArchivist(null)
  const openPrimaryArchivistModal = (id) => () => setPrimaryArchivist(id)
  const closePrimaryArchivistModal = () => setPrimaryArchivist(null)
  const nav = ({ direction }: ScrollState) => (
    <Navbar hidden={direction === ScrollDirection.down} />
  )
  const bar = ({ direction }: ScrollState) => (
    <BottomBar
      className='d-md-none'
      hidden={direction === ScrollDirection.up}
    />
  )
  return (
    <Query query={MY_USER_INFO}>
      {({ data, loading }) => {
        const archivists = get(data, 'myUserInfo.archivists') || []
        return (
          <ScrollPage navigationBar={nav} bottomBar={bar}>
            <Fragment>
              <div className='position-relative bg-primary bg-md-white mb-0 clear-top pb-3'>
                <div className='container'>
                  <h4 className='d-none d-md-block text-uppercase'>
                    {t('archivists')}
                  </h4>
                </div>
                <h4 className='d-md-none text-center text-white text-uppercase'>
                  <SlideInUp>{t('archivists')}</SlideInUp>
                </h4>
              </div>
              {loading ? (
                ''
              ) : (
                <Banner className='bg-secondary bg-md-primary text-white p-2 clear-top'>
                  <p className='mb-0'>
                    {archivists.length
                      ? 'Using default Archivist'
                      : 'Using the Matrix'}
                  </p>
                </Banner>
              )}
              <div className='flex-grow-1 bg-white pt-3'>
                <div className='container'>
                  {loading ? (
                    <div className='text-center'>
                      <Loader />
                    </div>
                  ) : (
                    <Fragment>
                      {size(archivists) ? (
                        archivists.map((archivist, i) => (
                          <SlideInUp key={archivist.id} timer={i * 200}>
                            <Archivist
                              archivist={archivist}
                              isDefault={
                                archivist.id ===
                                get(data, 'myUserInfo.defaultArchivistId')
                              }
                              onDetach={openDeleteArchivistModal(archivist.id)}
                              onDefault={openPrimaryArchivistModal(
                                archivist.id
                              )}
                            />
                          </SlideInUp>
                        ))
                      ) : (
                        <NoneFound
                          icon={<ArchivistIcon className='large-icon' />}
                          text={t('no archivists found')}
                        />
                      )}
                      <SlideInUp timer={archivists.length * 200 || 400}>
                        <div className='text-center mb-5'>
                          <button
                            className='btn bg-transparent text-uppercase'
                            onClick={openAddArchivistModal}
                          >
                            <i className='fa fa-plus-circle' />{' '}
                            {t('connect more archivists')}
                          </button>
                        </div>
                      </SlideInUp>
                    </Fragment>
                  )}
                </div>
                <div className='clear-bottom d-md-none' />
              </div>
              <Footer className='d-none d-md-block' />
              <Modal open={addArchivist} onClose={closeAddArchivistModal}>
                <ModalContent
                  className='overflow-hidden'
                  headerClassName='bg-info text-white rounded-0'
                  title={
                    // tslint:disable-next-line: jsx-wrap-multiline
                    <span className='text-capitalize'>
                      {t('add archivist')}
                    </span>
                  }
                >
                  <AddArchivist onSuccess={closeAddArchivistModal} />
                </ModalContent>
              </Modal>
              <Modal
                open={!!deleteArchivist}
                onClose={closeDeleteArchivistModal}
              >
                <ModalContent
                  className='overflow-hidden'
                  headerClassName='bg-info text-white rounded-0'
                  title={
                    // tslint:disable-next-line: jsx-wrap-multiline
                    <span className='text-capitalize'>
                      {t('detach archivist')}
                    </span>
                  }
                >
                  <button
                    className='btn btn-success mr-2'
                    onClick={closeDeleteArchivistModal}
                  >
                    Cancel
                  </button>
                  <DetachArchivist
                    id={deleteArchivist}
                    onSuccess={closeDeleteArchivistModal}
                  />
                </ModalContent>
              </Modal>
              <Modal
                open={!!primaryArchivist}
                onClose={closePrimaryArchivistModal}
              >
                <ModalContent
                  className='overflow-hidden'
                  headerClassName='bg-info text-white rounded-0'
                  title={
                    <span className='text-capitalize'>
                      {t('set as default archivist')}
                    </span>
                  }
                >
                  <SetDefaultArchivist
                    id={primaryArchivist}
                    onSuccess={closePrimaryArchivistModal}
                  />
                  <button
                    className='btn btn-danger mr-2'
                    onClick={closePrimaryArchivistModal}
                  >
                    Cancel
                  </button>
                </ModalContent>
              </Modal>
            </Fragment>
          </ScrollPage>
        )
      }}
    </Query>
  )
}

// tslint:disable: import-name
import React, { useState, Fragment } from 'react'
import Card from '@xyo-network/tool-storybook-react/dist/lib/Card'
import { useTranslation } from 'react-i18next'
// import { CardBody } from '@xyo-network/tool-storybook-react/dist/lib/Card'
import CardHead from '@xyo-network/tool-storybook-react/dist/lib/Card/Head'
import { useBlockyImage } from '@xyo-network/tool-storybook-react/dist/lib/Blockie'
// import { Collapsible } from '@xyo-network/tool-storybook-react/dist/lib/Collapse'
// import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'
// import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'

export default ({ ip }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [error] = useState('')
  const toggleDetails = () => setOpen(!open)
  const blocky = useBlockyImage(ip)
  const renderIcon = () => (
    <img className='bg-primary rounded shadow' src={blocky} />
  )
  const renderTitle = () => (
    <Fragment>
      <p className='ml-2 mb-0 hash-overflow'>{ip} </p>
    </Fragment>
  )
  const renderAction = () =>
    error ? (
      <i className='fa fa-times text-danger' />
    ) : (
      <a
        className='text-capitalize text-success'
        target='_blank'
        href={`http://${ip}`}
      >
        {t('manage settings')} {'\u2192'}
      </a>
    )
  return (
    <Card className='shadow mb-3 cursor-pointer'>
      <CardHead
        className='align-items-center'
        renderIcon={renderIcon}
        renderTitle={renderTitle}
        renderAction={renderAction}
        onClick={toggleDetails}
      />
      {/* <Collapsible show={open}>
        <CardBody>
          <Alert type={BootstrapType.danger}>{error}</Alert>
          <iframe
            src={`http://${ip}`}
            width='100%'
            height={400}
            frameBorder={0}
          />
        </CardBody>
      </Collapsible> */}
    </Card>
  )
}

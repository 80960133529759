// tslint:disable-next-line: import-name
import i18n from 'i18next'
// tslint:disable-next-line: import-name
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const version = 'v2'

export default () => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}_${version}.json`,
      },
    })
}

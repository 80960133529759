import gql from 'graphql-tag'

export default gql`
  query ArchivistInfo {
    about {
      name
      version
      ip
      graphqlPort
      boundWitnessServerPort
      address
      peers
    }
  }
`

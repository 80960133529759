// tslint:disable: import-name
// tslint:disable: interface-name
// tslint:disable: jsx-wrap-multiline
import React from 'react'
import { MySentinel, MyBridge } from './types'
import cx from 'classnames'
import './index.css'
import { useBlockyImage } from '@xyo-network/tool-storybook-react/dist/lib/Blockie'

interface Selectable {
  onClick?: any
  active?: boolean
}

export default ({
  name,
  publicKey,
  onClick,
  active,
}: (MySentinel | MyBridge) & Selectable) => {
  const blocky = useBlockyImage(publicKey)
  return (
    <div
      className={cx('card p-3 shadow-sm cursor-pointer', {
        'border-primary': active,
      })}
      onClick={onClick}
    >
      {blocky && <img className='device-img mb-3' src={blocky} alt={name} />}
      {
        <div className='text-center p-1 rounded single-line'>
          {name || 'Active'}
        </div>
      }
    </div>
  )
}

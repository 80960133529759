// tslint:disable: import-name
// tslint:disable: align
import React, { Fragment } from 'react'
import {
  ScrollPage,
  ScrollDirection,
  ScrollState,
} from '../../components/Layout'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'
import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import Banner from '../../components/Banner'
import { Footer } from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import { getLocalIp } from './localIp'
import { useScanFactory } from './scan'
import imageExists from './imageExists'
import Bridge from './Bridge'
import get from 'lodash/get'
import size from 'lodash/size'
import { useAsyncReducer } from '../../utils/asyncReducer'

const firstThree = (ip: string | null) =>
  (ip || '')
    .split('.')
    .slice(0, -1)
    .join('.')

export default () => {
  const { t } = useTranslation()
  const [ip, ipActions] = useAsyncReducer<string>()
  const trie = firstThree(ip.data)
  const [{ data: scanner }, scan] = useScanFactory((trie, i) => {
    return imageExists(`http://${trie}.${i}/static/media/logo.e200a040.svg`)
  }, 256)(trie)
  const startScan = async() => {
    try {
      ipActions.setLoading()
      const ip = await getLocalIp()
      ipActions.setData(ip || '')
    } catch (e) {
      ipActions.setError(e.message)
    }
  }
  const retry = async() => {
    await startScan()
    scan(false)
  }
  const percent = get(scanner, 'percent')
  const nav = ({ direction, position }: ScrollState) => (
    <Navbar
      hidden={direction === ScrollDirection.down}
      transparent={!position}
    />
  )
  const bar = ({ direction }: ScrollState) => (
    <BottomBar
      className='d-md-none'
      hidden={direction === ScrollDirection.up}
    />
  )
  return (
    <ScrollPage navigationBar={nav} bottomBar={bar}>
      <Fragment>
        {ip.error ? (
          <SlideInDown>
            <Banner className='bg-danger text-white p-2 clear-top'>
              {ip.error}
            </Banner>
          </SlideInDown>
        ) : (
          ''
        )}
        <div className='position-relative bg-primary mb-0 clear-top pb-3 text-center text-white'>
          <h4 className='text-uppercase'>
            <SlideInDown>{t('scan for bridges')}</SlideInDown>
          </h4>
          <SlideInDown>
            <p className='text-uppercase'>{ip.data || ''}</p>
          </SlideInDown>
        </div>
        <div className='flex-grow-1 bg-white pt-3'>
          <div className='container'>
            <div className='d-flex align-items-stretch mb-4'>
              <div className='progress w-100 h-auto'>
                <div
                  className='progress-bar bg-secondary'
                  style={{ width: percent }}
                />
              </div>
              {get(scanner, 'progress') === 1 ? (
                <button
                  className='btn btn-light text-capitalize ml-1'
                  onClick={retry}
                >
                  {t('retry')}
                </button>
              ) : get(scanner, 'progress') ? (
                <button className='btn btn-transparent px-0 w-0'>&nbsp;</button>
              ) : (
                <button
                  onClick={startScan}
                  className='btn btn-info text-capitalize ml-1 nowrap'
                >
                  {t('start scan')}
                </button>
              )}
            </div>

            {get(scanner, 'progress') === 1 &&
            !size(get(scanner, 'results')) ? (
              <Alert type={BootstrapType.danger}>{t('no_bridges')}</Alert>
            ) : (
              ''
            )}

            {(get(scanner, 'results') || []).map((ip, i) => (
              <SlideInUp key={i} timer={i * 200}>
                <Bridge ip={`${trie}.${ip}`} />
              </SlideInUp>
            ))}
          </div>
        </div>
        <Footer />
      </Fragment>
    </ScrollPage>
  )
}

export default [
  {
    name: 'BLE_001',
  },
  {
    name: 'BLE_002',
    latest_connection: '1 day',
  },
  {
    name: 'BLE_003',
    latest_connection: '4 days',
  },
]

import React, { useState, useEffect } from 'react'

export default () => {
  const [isOnline, setIsOnline] = useState(!!window.navigator.onLine)
  useEffect(() => {
    const setOnline = () => setIsOnline(true)
    const setOffline = () => setIsOnline(false)
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])
  return isOnline ? (
    <div />
  ) : (
    <p className='bg-danger text-white mb-0 p-2'>Offline</p>
  )
}

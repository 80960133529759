import React, { ReactNode, cloneElement } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  children?: ReactNode
}

const BootstrapElement = (bootClassName, element = <div />) => ({
  className,
  children,
  ...props
}: Props & any) =>
  cloneElement(element, {
    ...element.props,
    className: cx(bootClassName, element.props.className, className),
    children,
  })

export const Container = BootstrapElement('container')
export const Row = BootstrapElement('row')
export const Offset = BootstrapElement('col col-md-8 offset-md-2')
export const PageHeader = BootstrapElement(
  'position-relative bg-primary bg-md-white mb-0 clear-top pb-3'
)
export const TitleMd = BootstrapElement(
  'd-none d-md-block text-uppercase',
  <h4 />
)
export const TitleXs = BootstrapElement(
  'd-md-none text-center text-white text-uppercase',
  <h4 />
)
export default BootstrapElement

import React, { ReactNode, Component } from 'react'
import cx from 'classnames'

interface Props {
  children: ReactNode
}

export const Main = ({ children }: Props) => (
  <div className='container'>
    <div className='row'>
      <div className='offset-md-4 col-md-4'>
        <div className='d-flex flex-column vh-100'>{children}</div>
      </div>
    </div>
  </div>
)

export const Page = ({ children }: Props) => (
  <div className='d-flex flex-column bg-primary vh-100'>{children}</div>
)

export enum ScrollDirection {
  top = 'TOP',
  bottom = 'BOTTOM',
  down = 'DOWN',
  up = 'UP',
}

export interface ScrollState {
  direction: ScrollDirection
  position: number
}

interface ScrollPageProps {
  tracker?: boolean
  handleRefresh?: () => void
  navigationBar?: (s: ScrollState) => ReactNode
  bottomBar?: (s: ScrollState) => ReactNode
  children: ReactNode
}

export class ScrollPage extends Component<ScrollPageProps, ScrollState> {
  scrollEl: HTMLDivElement
  unsubTouchEnd: (() => void) | null = null
  state = {
    direction: ScrollDirection.top,
    position: 0,
  }
  on = (eventName: string, fn: () => void) => {
    if (this.scrollEl) {
      this.scrollEl.addEventListener(eventName, fn)
      return () => {
        this.scrollEl.removeEventListener(eventName, fn)
      }
    }
    return () => {}
  }

  handleScroll = () => window.requestAnimationFrame(this.updateScrollPosition)
  setScrollEl = (el: HTMLDivElement) => {
    if (!this.scrollEl) {
      this.scrollEl = el
      this.scrollEl.addEventListener('scroll', this.handleScroll)
    }
  }
  componentWillUnmount = () => {
    this.scrollEl.removeEventListener('scroll', this.handleScroll)
  }
  updateScrollPosition = () => {
    const { position } = this.state
    const newPosition = this.scrollEl.scrollTop
    if (newPosition > position) {
      this.setState({ direction: ScrollDirection.down })
    }
    if (newPosition < position) this.setState({ direction: ScrollDirection.up })
    if (
      this.props.handleRefresh &&
      !this.unsubTouchEnd &&
      newPosition <= -100
    ) {
      this.unsubTouchEnd = this.on('touchend', () => {
        setTimeout(() => {
          this.props.handleRefresh && this.props.handleRefresh()
        }, 100)
      })
    } else if (this.unsubTouchEnd) {
      this.unsubTouchEnd()
      this.unsubTouchEnd = null
    }
    if (newPosition <= 0) {
      this.setState({ direction: ScrollDirection.top })
    } else {
      const { height } = this.scrollEl.getBoundingClientRect()
      if (newPosition >= this.scrollEl.scrollHeight - height) {
        this.setState({ direction: ScrollDirection.bottom })
      }
    }
    this.setState({ position: newPosition })
  }
  render() {
    const {
      navigationBar,
      bottomBar,
      handleRefresh,
      children,
      tracker = true,
    } = this.props
    const { direction, position } = this.state
    return (
      <div className='d-flex flex-column overflow-hidden position-relative vh-100'>
        {navigationBar && navigationBar({ direction, position })}
        <div
          ref={tracker ? this.setScrollEl : null}
          className='position-relative scroll-y flex-grow-1 d-flex flex-column'
        >
          {handleRefresh ? (
            <div
              className={cx(
                'continue-bg-above continue-bg-above-with-logo bg-primary',
                {
                  'continue-bg-above-refresh': position < -100,
                }
              )}
            />
          ) : (
            <div className='continue-bg-above bg-primary' />
          )}
          {typeof children === 'function'
            ? children({ direction, position })
            : children}
        </div>
        {bottomBar && bottomBar({ direction, position })}
      </div>
    )
  }
}

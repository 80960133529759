import React from 'react'
import { NavLink } from 'react-router-dom'
import Portal from '../Portal'
import Logo from '../Logo'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

interface FooterProps {
  className?: string
}

export const Footer = ({ className }: FooterProps) => {
  const { t } = useTranslation()
  return (
    <div className={cx('footer bg-light', className)}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4 d-flex flex-column justify-content-center py-4'>
            <div className='p-2 text-uppercase '>
              <p className='mb-0'>{t('account')}</p>
            </div>
            <NavLink
              className='p-2 border-bottom d-block text-capitalize'
              to='/profile'
            >
              {t('my profile')}
            </NavLink>
            <a
              className='p-2 border-bottom d-block text-capitalize'
              href={process.env.REACT_APP_GET_HELP_URI || ''}
              target='_blank'
            >
              {t('get help')}
            </a>
          </div>
          <div className='col-md-4 d-flex flex-column justify-content-center py-4'>
            <div className='p-2 text-uppercase'>
              <p className='mb-0'>{t('privacy')}</p>
            </div>
            {/* <NavLink
              className='p-2 border-bottom d-block text-capitalize'
              to='/sharing'
            >
              {t('sharing')}
            </NavLink> */}
            <NavLink
              className='p-2 border-bottom d-block text-capitalize'
              to='/privacy-policy'
            >
              {t('privacy policy')}
            </NavLink>
            <NavLink
              className='p-2 border-bottom d-block text-capitalize'
              to='/terms-of-service'
            >
              {t('terms of Service')}
            </NavLink>
          </div>
          <div className='col-md-4 d-flex flex-column justify-content-center text-center py-4'>
            <Logo />
            <h4 className='text-uppercase'>{t('powered by xyo')}</h4>
          </div>
        </div>
        <p className='mb-0 pb-2 text-center'>
          &#169; 2019 XY The Persistent Company
        </p>
        <div className='clear-bottom d-md-none' />
      </div>
    </div>
  )
}

export default class FooterPortal extends Portal<FooterProps, {}> {
  id = 'footer'
  portal() {
    const { className } = this.props
    return <Footer className={className} />
  }
}

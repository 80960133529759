import gql from 'graphql-tag'

export default gql`
  query GetNode($publicKey: String) {
    node(publicKey: $publicKey) {
      publicKey
      displayName
      lastGps {
        latitude
        longitude
      }
    }
  }
`

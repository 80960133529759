import React, { Component } from 'react'
import { createPortal } from 'react-dom'

export default class Portal<P, S> extends Component<P, S> {
  id = ''

  portal() {
    throw new Error("method 'portal' must be extended")
    return <div />
  }

  render() {
    const node = document.getElementById(this.id)
    if (!node) {
      throw new Error(`No element found with id: ${this.id}`)
    } else {
      return createPortal(this.portal(), node)
    }
  }
}

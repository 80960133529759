import React, { ReactNode } from 'react'
import Portal from '../Portal'
import cx from 'classnames'

interface Props {
  className?: string
  children: ReactNode
}
const id = 'banner'

export const BannerMount = () => <div id={id} />

export default class Banner extends Portal<Props, {}> {
  id = id
  portal() {
    const { children, className } = this.props
    return <div className={cx('', className)}>{children}</div>
  }
}

import gql from 'graphql-tag'

export default gql`
  query MyDeviceCounts {
    mySentinels {
      meta {
        totalCount
      }
    }
    myBridges {
      meta {
        totalCount
      }
    }
  }
`

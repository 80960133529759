// tslint:disable-next-line: import-name
import gql from 'graphql-tag'

export default gql`
  query MyDevices {
    mySentinels {
      meta {
        totalCount
      }
      items {
        id
        name
        photoUrl
        publicKey
        uuid
        major
        minor
      }
    }
    myBridges {
      meta {
        totalCount
      }
      items {
        id
        name
        photoUrl
        publicKey
        uuid
        major
        minor
        dns
        port
      }
    }
  }
`

// tslint:disable: import-name
import React, { Component } from 'react'
import { Page, Main } from '../../components/Layout'
import {
  SlideInUp,
  SlideInDown,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import Logo from '../../components/Logo'
import { Login } from '@xyo-network/tool-storybook-react/dist/lib/auth'

// tslint:disable-next-line: interface-name
interface SignInProps {}

const SignInContainer = class extends Component<
  RouteComponentProps & SignInProps
> {
  onSuccess = () => {
    const { from }: any = this.props.location.state || { from: '/' }
    this.props.history.push(from)
  }

  goToSignUp = () => {
    this.props.history.push('/signup')
  }

  render() {
    return (
      <Page>
        <Main>
          <div className='col mb-5' />
          <SlideInDown timer={300}>
            <Link to='/' className='btn text-white text-left'>
              <h4>
                <i className='fa fa-times' />
              </h4>
            </Link>
            <div className='text-center mb-2'>
              <Logo />
            </div>
            <h4 className='text-white text-center'>WELCOME TO XYO</h4>
          </SlideInDown>
          <Login
            className='text-white'
            labelClassName='text-white'
            buttonClassName='btn-info'
            onSuccess={this.onSuccess}
            goToSignUp={this.goToSignUp}
          />
          <SlideInUp timer={300}>
            <Link to='reset-password' className='text-white'>
              Forgot Password
            </Link>
          </SlideInUp>
          <div className='col mb-4' />
        </Main>
      </Page>
    )
  }
}

export default withRouter(SignInContainer)

import gql from 'graphql-tag'

export default gql`
  query Blocks($perPage: Int = 10, $page: Int = 0, $publicKeys: [String]!) {
    blocks(perPage: $perPage, page: $page, publicKeys: $publicKeys) {
      pagination {
        count
        next
        numPages
      }
      items {
        hash
        createdAt
        parties {
          heuristics
          publicKey
        }
      }
    }
  }
`

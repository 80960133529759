import React from 'react'
import { Link } from 'react-router-dom'
import { Page, Main } from '../../components/Layout'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { useTranslation } from 'react-i18next'
import Logo from '../../components/Logo'

const Bienvenidos = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <Main>
        <div className='flex-grow-3' />
        <SlideInDown>
          <div className='text-center mb-2'>
            <Logo />
          </div>
          <h4 className='text-white text-center text-uppercase'>
            {t('welcome to xyo')}
          </h4>
        </SlideInDown>
        <div className='flex-grow-1' />
        <SlideInUp>
          <div className='p-2'>
            <Link
              className='d-block btn btn-lg btn-info w-100 mb-4 text-white text-uppercase'
              to='/signup'
            >
              {t('sign up')}
            </Link>
            <Link
              className='d-block btn btn-lg w-100 mb-4 text-white text-uppercase'
              to='/login'
            >
              {t('log in')}
            </Link>
          </div>
        </SlideInUp>
        <div className='flex-grow-3 mb-4' />
      </Main>
    </Page>
  )
}

export default Bienvenidos

// tslint:disable-next-line: import-name
import React from 'react'
import { QueryResult, compose, graphql } from 'react-apollo'
// import { renderComponent } from 'recompose'
// tslint:disable-next-line: import-name
import MY_DEVICES_QUERY from '../../gql/queries/myDevices'
import get from 'lodash/get'
// import bs58 from 'bs58'
import flatMap from 'lodash/flatMap'

export const withMyPublicKeys = compose(
  graphql(MY_DEVICES_QUERY),
  // tslint:disable-next-line: ter-arrow-parens
  (Component) => (result) => (
    <Component {...result.data} {...result} publicKeys={myPublicKeys(result)} />
  )
)

// const prefix = (pre, str) => `${pre}${str}`
// const encode = (str: string) => {
//   try {
//     const hex = Buffer.from(str, 'hex')
//     return bs58.encode(hex)
//   } catch (e) {
//     return bs58.encode(Buffer.from(str))
//   }
// }
// const zeroX = str => prefix('0x', str)

export const myPublicKeys = (devices: QueryResult): string[] => {
  return flatMap(
    []
      .concat(get(devices, 'data.mySentinels.items') || [])
      .concat(get(devices, 'data.myBridges.items') || [])
      .filter((v) => v)
      .map(({ publicKey }) => publicKey)
      .filter((v) => v)
      .map((v: string) => {
        return v
        // if (v.indexOf('0x')) return zeroX(encode(v))
        // return [
        //   zeroX(encode(prefix('000e41', v.replace('0x', '')))),
        //   zeroX(encode(prefix('000c41', v.replace('0x', '')))),
        // ]
      })
  )
}

// tslint:disable: import-name
// tslint:disable: ter-arrow-parens
import React, { Fragment, useState } from 'react'
import UpdateSentinel from './UpdateSentinel'
import { Query } from 'react-apollo'
import { TextInput } from '@xyo-network/tool-storybook-react/dist/lib/Form'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import CopyButton from '@xyo-network/tool-storybook-react/dist/lib/CopyButton'
import UpdateBridge from './UpdateBridge'
// import DeleteDeviceMutation from './DeleteDeviceMutation'
import { useTranslation } from 'react-i18next'
import GET_NODE from '../../gql/queries/getNode'
import get from 'lodash/get'
import Modal, {
  ModalContent,
} from '@xyo-network/tool-storybook-react/dist/lib/Modal'

const CopyPublicKey = (copiedText, uncopiedText) => (copied) => (
  <span className='text-capitalize'>{copied ? copiedText : uncopiedText}</span>
)

const optimisticResponse = (
  mutationName: string,
  __typename: string,
  args: any
) => ({
  optimisticResponse: {
    __typename: 'Mutation',
    [mutationName]: {
      __typename,
      ...args,
    },
  },
})

const optimisticResponseBridge = (args) =>
  optimisticResponse('updateBridge', 'Bridge', args)
const optimisticResponseSentinel = (args) =>
  optimisticResponse('updateSentinel', 'Sentinel', args)
const optimisticResponseDeleteBridge = (args) =>
  optimisticResponse('deleteDevice', 'Bridge', args)
const optimisticResponseDeleteSentinel = (args) =>
  optimisticResponse('deleteDevice', 'Sentinel', args)

const Settings = ({
  device,
  update,
  updateStatus,
  optimisticUpdate,
  optimisticDelete,
  onClose,
  manage,
}) => {
  const { t } = useTranslation()
  const publicKey = get(device, 'publicKey')
  const [open, setOpen] = useState(false)
  const openDeleteModal = () => setOpen(true)
  const closeDeleteModal = () => setOpen(false)
  // const onDeleteDevice = fn => () => {
  //   const id = get(device, 'id')
  //   fn({
  //     variables: { id },
  //     ...optimisticDelete({ ...device }),
  //   })
  //   closeDeleteModal()
  // }
  const updateDevice = (key: string) => (value: any) =>
    update({
      variables: {
        id: device.id,
        [key]: value,
      },
      ...optimisticUpdate({ ...device, [key]: value }),
    })
  return (
    <Fragment>
      <div className='mb-3'>
        <div className='d-flex'>
          <strong>Mode</strong>
          <div className='col' />
          <span>{device.__typename}</span>
        </div>
        <Query query={GET_NODE} skip={!publicKey} variables={{ publicKey }}>
          {({ loading, data }) =>
            loading ? (
              <Loader />
            ) : (
              <>
                <div className='d-flex'>
                  <strong>Latitude</strong>
                  <div className='col' />
                  <span>{get(data, 'node.lastGps.latitude') || 'Unknown'}</span>
                </div>
                <div className='d-flex'>
                  <strong>Longitude</strong>
                  <div className='col' />
                  <span>
                    {get(data, 'node.lastGps.longitude') || 'Unknown'}
                  </span>
                </div>
              </>
            )
          }
        </Query>
      </div>
      <TextInput
        subText={<span className='text-capitalize'>{t('name')}</span>}
        name='name'
        value={device.name || ''}
        onChange={updateDevice('name')}
      />
      <TextInput
        subText={<span className='text-capitalize'>{t('payment key')}</span>}
        name='paymentKey'
        value={device.paymentKey || ''}
        onChange={updateDevice('paymentKey')}
      />
      <TextInput
        disabled={true}
        subText={<span className='text-capitalize'>{t('public key')}</span>}
        name='publicKey'
        value={device.publicKey || ''}
        onChange={updateDevice('publicKey')}
      />
      <CopyButton
        className='btn-outline-primary mr-1'
        value={device.publicKey || ''}
        render={CopyPublicKey(t('copied public key'), t('copy public key'))}
      />
      {manage && (
        <a
          href={manage}
          target='_blank'
          className='btn btn-info text-capitalize mr-1 d-none'
        >
          {t('manage')}
        </a>
      )}
      <button
        className='btn btn-danger text-capitalize d-none'
        onClick={openDeleteModal}
      >
        {t('delete')}
      </button>
      <Modal open={open} onClose={closeDeleteModal}>
        <ModalContent
          className='overflow-hidden'
          headerClassName='bg-info text-white rounded-0'
          title={<span className='text-capitalize'>{t('delete device')}</span>}
        >
          <button
            className='btn btn-success text-capitalize'
            onClick={closeDeleteModal}
          >
            {t('cancel')}
          </button>
          {/* <DeleteDeviceMutation update={onClose}>
            {deleteDevice => (
              <button
                className='btn btn-danger my-4 ml-2 text-capitalize'
                onClick={onDeleteDevice(deleteDevice)}
              >
                {t('delete')}
              </button>
            )}
          </DeleteDeviceMutation> */}
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

export default ({ device, onClose }) => {
  if (device.__typename === 'Bridge') {
    return (
      <UpdateBridge>
        {(update, updateStatus) => (
          <Settings
            manage={`http://${device.dns}:80`}
            optimisticUpdate={optimisticResponseBridge}
            optimisticDelete={optimisticResponseDeleteBridge}
            onClose={onClose}
            device={device}
            update={update}
            updateStatus={updateStatus}
          />
        )}
      </UpdateBridge>
    )
  }
  return (
    <UpdateSentinel>
      {(update, updateStatus) => (
        <Settings
          manage={''}
          optimisticUpdate={optimisticResponseSentinel}
          optimisticDelete={optimisticResponseDeleteSentinel}
          onClose={onClose}
          device={device}
          update={update}
          updateStatus={updateStatus}
        />
      )}
    </UpdateSentinel>
  )
}

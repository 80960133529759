import flatMap from 'lodash/flatMap'
import times from 'lodash/times'

// tslint:disable-next-line: array-type
export default (routes: [string, number][]) => {
  const pool = flatMap(routes, ([route, odds]) => {
    return times(odds * 100).map(() => route)
  })
  return () => {
    const index = Math.floor(Math.random() * pool.length)
    return pool[index]
  }
}

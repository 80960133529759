import React, { useEffect, ReactNode, Fragment } from 'react'

interface Props {
  id: string
  className: string
  children?: ReactNode
}

export default ({ id, className, children }: Props) => {
  useEffect(() => {
    const element = document.getElementById(id)
    if (!element) throw new Error(`Element with id "${id}" does not exist`)
    element.classList.add(className)
    return () => {
      element.classList.remove(className)
    }
  }, [])
  return <Fragment>{children}</Fragment>
}

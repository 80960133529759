import React, { CSSProperties } from 'react'
import './index.css'

interface Props {
  style?: CSSProperties
}

export default ({ style }: Props) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 180 180'
    style={{ height: 80, ...style }}
  >
    <g>
      <path
        className='logo-color-0'
        d='M89.8,166.02c-13.33,0-26.66-0.1-39.99,0.07c-3.09,0.04-4.87-1.03-6.35-3.63
		c-13.06-22.84-26.18-45.65-39.37-68.41c-1.57-2.7-1.89-4.84-0.19-7.74c13.37-22.85,26.62-45.77,39.75-68.76
		c1.53-2.67,3.17-3.66,6.24-3.65c26.66,0.12,53.32,0.11,79.98,0.01c2.86-0.01,4.55,0.88,5.97,3.36
		c13.48,23.56,27.04,47.07,40.69,70.53c1.14,1.95,0.83,3.26-0.17,4.98c-13.44,23.19-26.88,46.39-40.2,69.66
		c-1.53,2.67-3.31,3.67-6.36,3.64C116.46,165.94,103.13,166.02,89.8,166.02z M58.07,30.98c-0.2,1.93-1.88,2.28-3.21,3.01
		c-1.83-1.9-2.14-4.86-4.67-6.73c-9.3,16.13-18.54,32.15-27.86,48.31c2.79,0.7,3.73-1.5,5.14-2.68
		c7.87-6.59,15.03-14.01,23.32-20.12c1.73,0.18,2.94,0.97,3.28,2.78C42.55,65.86,31.16,76.31,19.43,86.37
		c-3.35,2.87-3.91,4.35-0.33,7.43c11.33,9.75,22.39,19.82,33.54,29.76c-0.92,1.27-1.42,2.9-3.07,3.53
		c-4.07-3.68-8.12-7.39-12.23-11.03c-3.11-2.76-6.27-5.45-9.45-8.14c-0.9-0.76-2.07-1.59-3.15-0.79c-1.55,1.14,0.14,1.95,0.64,2.82
		c1.8,3.19,3.63,6.36,5.46,9.52c6.41,11.06,12.83,22.11,19.24,33.16c2.04-0.91,2.26-2.77,3.28-4.02c1.3,0.75,2.61,1.49,3.91,2.24
		c-2.41,5.04-2.41,5.05,3.15,5.05c7.48,0.01,14.96,0,22.43,0c9.69,0,19.39,0,29.08,0c0.05-0.3,0.09-0.6,0.14-0.9
		c-11.49-4.04-22.98-8.08-34.47-12.13c0.42-1.34,0.84-2.69,1.26-4.03c14.34,4.73,28.73,9.34,43,14.26c3.65,1.26,4.74,0.59,5.51-3.23
		c2.94-14.46,6.29-28.83,9.48-43.23c1.49,0.16,2.98,0.31,4.47,0.47c-2.14,9.91-4.24,19.83-6.47,29.72
		c-0.36,1.58-1.79,3.73-0.25,4.51c2.03,1.04,2.41-1.68,3.16-2.96c8-13.64,15.85-27.38,23.91-40.98c2.21-3.74,1.24-5.02-2.78-4.91
		c0-1.56,0-3.12,0-4.68c1.62-0.77,6.82,2.02,3.6-3.56c-8.15-14.11-16.33-28.19-24.53-42.27c-0.71-1.22-1.18-2.84-3.28-2.8
		c2.26,11.23,4.51,22.4,6.76,33.56c-1.51,0.3-3.01,0.61-4.52,0.91c-2.92-14.1-6.1-28.16-8.63-42.34c-0.8-4.5-2.51-4.44-5.93-3.34
		c-14.5,4.68-29.05,9.23-43.58,13.83c-0.61-1.48-1.22-2.95-1.83-4.43c12.15-3.86,24.3-7.73,36.45-11.59
		c-17.19-1.12-34.11-1.12-51.03-1.12C55.33,24.71,55.33,24.71,58.07,30.98z'
      />
      <path
        className='logo-color-1'
        d='M128.25,79.14c2.7-2.12,5.39-4.26,8.67-5.45c1.51-0.3,3.01-0.61,4.52-0.91c9.23,1.07,15.08,6.06,17.46,15.07
		c0,1.56,0,3.12,0,4.68c-2.59,8.8-7.99,14.22-17.57,14.61c-1.49-0.16-2.98-0.31-4.47-0.47c-3.22-1.37-5.91-3.54-8.59-5.71
		c-0.49-1.64-1.36-3.03-2.74-4.07c-0.63-4.65-1.02-9.29,0.48-13.87C127.17,81.97,127.78,80.6,128.25,79.14z M141.57,102.13
		c6.54,0.1,12.31-5.43,12.36-11.85c0.05-6.54-5.5-12.22-11.97-12.27c-6.78-0.05-12.07,5.13-12.15,11.88
		C129.74,96.74,134.82,102.03,141.57,102.13z'
      />
      <path
        className='logo-color-2'
        d='M78.19,130.02c0.23,2.95,0.45,5.9,0.68,8.85c-0.42,1.34-0.84,2.69-1.26,4.03c-4.87,7.52-11.75,9.94-20.34,7.97
		c-1.3-0.75-2.61-1.49-3.91-2.24c-6.37-6.28-6.91-13.58-3.79-21.53c1.65-0.63,2.15-2.27,3.08-3.53c2.78-1.17,5.55-2.34,8.32-3.51
		c1.6,0.02,3.2,0.05,4.8,0.07c3.82,1.19,7.16,3.2,10.04,5.98C76.6,127.41,77.4,128.71,78.19,130.02z M73.96,135.95
		c-0.01-5.97-4.68-10.76-10.65-10.89c-5.66-0.13-10.96,4.94-11.12,10.63c-0.16,5.86,5.22,11.27,11.09,11.16
		C69.2,146.73,73.97,141.86,73.96,135.95z'
      />
      <path
        className='logo-color-3'
        d='M58.07,30.98c7.22-0.57,14.19-0.41,18.88,6.44c0.61,1.48,1.22,2.95,1.83,4.43c0.06,3,0.12,6,0.17,9.01
		c-2.22,0.23-2.48,2.61-3.98,3.63c-2.38,2.65-5.25,4.34-8.87,4.59c-1.73-0.03-3.45-0.07-5.18-0.1c-2.29-1.14-4.57-2.28-6.86-3.42
		c-0.35-1.81-1.55-2.61-3.28-2.78c0.53-1.28,0.49-2.58,0.07-3.86c-1.98-5.88,0.56-10.52,4.01-14.92
		C56.19,33.26,57.88,32.91,58.07,30.98z M64.39,54.12c5.39,0.04,9.62-4.09,9.56-9.34c-0.06-5-4.21-9.09-9.28-9.12
		c-5.37-0.03-9.67,4.15-9.61,9.37C55.13,50.04,59.27,54.08,64.39,54.12z'
      />
      <path
        className='logo-color-4'
        d='M60.93,58.97c1.73,0.03,3.45,0.07,5.18,0.1c-0.07,2.15-0.19,4.3-0.2,6.44c-0.06,18.2-0.1,36.41-0.14,54.61
		c-1.6-0.02-3.2-0.05-4.8-0.07C60.96,99.7,60.94,79.33,60.93,58.97z'
      />
      <path
        className='logo-color-5'
        d='M78.19,130.02c-0.79-1.31-1.59-2.61-2.38-3.92c1.26-0.79,2.51-1.61,3.79-2.36
		c15.31-8.96,30.62-17.9,45.93-26.85c1.38,1.04,2.24,2.43,2.74,4.07C111.89,111.18,95.06,120.64,78.19,130.02z'
      />
      <path
        className='logo-color-5'
        d='M74.98,54.48c1.5-1.02,1.76-3.39,3.98-3.63c12.79,6.41,24.89,14.06,37.33,21.09c4.05,2.29,7.97,4.79,11.95,7.2
		c-0.47,1.46-1.07,2.84-2.24,3.89c-7.19-4.08-14.34-8.22-21.58-12.21C94.59,65.41,85.26,59.08,74.98,54.48z'
      />
    </g>
  </svg>
)

import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/fp/get'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

const KYCRejected = ({ location }) => {
  const message = get(['state', 'data', 'rejectionReason', 'message'], location)

  return (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>
          Your previous attempt has been rejected for the following reason:
        </h4>
      </SlideInDown>
      <SlideInUp timer={200}>
        <p>{message || 'Score below threshold'}</p>
      </SlideInUp>
      <SlideInUp timer={400}>
        <Link className='btn btn-primary' to='/kyc/start'>
          Try Again
        </Link>
      </SlideInUp>
    </>
  )
}

export default KYCRejected

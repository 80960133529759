// tslint:disable: import-name
// tslint:disable: interface-name
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import reducers from './reducers'
import web3Saga from './reducers/web3'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (a: any) => (a: any) => any
  }
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 })
    : compose

const sagaMiddleware = createSagaMiddleware()
const middleware: any[] = [sagaMiddleware]
if (process.env.NODE_ENV === 'development') middleware.push(logger)

export default () =>
  createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))

export function initSaga() {
  sagaMiddleware.run(web3Saga)
}

import { createHttpLink } from 'apollo-link-http'

export default (uri: string, useGETForQueries: boolean = false) =>
  createHttpLink({
    fetchOptions: {
      timeout: 2000,
    },
    uri,
    useGETForQueries,
  })

import { SlideInLeft } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import {
  useXyAccountContext,
  isXYAccount,
} from '@xyo-network/tool-storybook-react/dist/lib/auth/context'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import 'react-html5-camera-photo/build/css/index.css'
import { Route, Switch } from 'react-router-dom'
import BottomBar from '../../components/BottomBar'
import { Footer } from '../../components/Footer'
import {
  ScrollDirection,
  ScrollPage,
  ScrollState,
} from '../../components/Layout'
import Navbar from '../../components/Navbar'
import ToggleDomClass from '../../components/ToggleDomClass'
import './index.css'
import KYCApproved from './KYCApproved'
import KYCEmailVerification from './KYCEmailVerification'
import KYCForm from './KYCForm'
import KYCMaxAttempts from './KYCMaxAttempts'
import KYCPending from './KYCPending'
import KYCRejected from './KYCRejected'
import { useXyoClient } from '../../gql/context'

const paths = ['', 'rejected', 'in-progress'].reduce(
  (acc: string[], v) =>
    acc.concat([`/kyc/${v}`, v === '' ? '/kyc' : `/kyc/${v}/`].filter(Boolean)),
  []
)

const KYC = ({ history, location }) => {
  const xya = useXyAccountContext()
  const client = useXyoClient()
  const onLogOut = () => {
    if (isXYAccount(xya)) xya.signOut()
    history.push('/welcome')
    client.resetStore()
  }
  const user = xya.currentUser()!
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const interval = useRef<NodeJS.Timeout>()
  const fetchApi = useCallback(async () => {
    try {
      if (!user.emailVerified) {
        history.replace('/kyc/email-verification')
      } else {
        const result = await fetch(
          `${process.env.REACT_APP_CHECKOUT_API}/customers/kyc?email=${
          user.email
          }`
        )
        const data = await result.json()
        const { status, attempts, message } = data
        if (message) {
          throw new Error(message)
        }
        const isRootKycRoute =
          location.pathname === '/kyc' || location.pathname === '/kyc/'
        const getPathname = () => {
          // for debugging only
          // return 'start'
          // return 'in-progress'
          // return 'accepted'
          // return 'rejected'
          // return 'max-attempts'
          if (attempts.error) {
            // user has reached max attempts
            return 'max-attempts'
          }
          if (status === 'rejected') {
            // last attempt was rejected, needs to retry, only if at root KYC route
            return isRootKycRoute ? 'rejected' : null
          }
          if (status === 'unknown') {
            // user has never submitted a request
            return 'start'
          }
          // status is either approved or pending
          return status
        }
        const pathname = getPathname()
        console.log({ pathname })
        if (pathname !== null) {
          history.replace(`/kyc/${pathname}`, { data })
        }
      }
      setLoading(false)
    } catch (err) {
      console.error(err)
      setError(err.message)
      setLoading(false)
    }
  }, [user, location])

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
      interval.current = undefined
    }

    if (!interval.current && paths.includes(location.pathname)) {
      interval.current = setInterval(fetchApi, 10000)
    }

    fetchApi()
    return () => {
      if (interval && interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [location.pathname])

  const nav = ({ direction }: ScrollState) => (
    <Navbar hidden={direction === ScrollDirection.down} />
  )

  const bar = ({ direction }: ScrollState) => (
    <BottomBar
      className='d-md-none'
      hidden={direction === ScrollDirection.up}
    />
  )

  if (loading) {
    return <ToggleDomClass id='splash-loader' className='visible' />
  }

  return (
    <ScrollPage navigationBar={nav} bottomBar={bar}>
      <div className='row my-5 py-5 justify-content-center'>
        <SlideInLeft>
          <div className='card p-5 kyc-card'>
            {error ? (
              <>
                <div>Sorry, an error has occurred: {error}</div>
                {error === 'Customer not found' && (
                  <div>
                    <div>
                      Please ensure you logged in with the same email you made
                      your purchase with.
                    </div>
                    <button
                      className='btn btn-secondary text-uppercase w-100'
                      onClick={onLogOut}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </>
            ) : (
                <Switch>
                  <Route path='/kyc/start' component={KYCForm} exact={true} />
                  <Route
                    path='/kyc/email-verification'
                    component={KYCEmailVerification}
                    exact={true}
                  />
                  <Route
                    path='/kyc/max-attempts'
                    component={KYCMaxAttempts}
                    exact={true}
                  />
                  <Route
                    path='/kyc/rejected'
                    component={KYCRejected}
                    exact={true}
                  />
                  <Route
                    path='/kyc/in-progress'
                    component={KYCPending}
                    exact={true}
                  />
                  <Route
                    path='/kyc/accepted'
                    component={KYCApproved}
                    exact={true}
                  />
                </Switch>
              )}
          </div>
        </SlideInLeft>
      </div>
      <Footer />
    </ScrollPage>
  )
}

export default KYC

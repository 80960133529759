import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import Home from '../../icons/Home'
import Archivist from '../../icons/Archivist'
import Devices from '../../icons/Devices'
import BoundWitness from '../../icons/BoundWitness'
import { useTranslation } from 'react-i18next'
import './index.css'

interface Props {
  hidden?: boolean
  className?: string
}

export default ({ hidden, className }: Props) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <div>
        <nav
          className={cx(
            'bottom-bar position-absolute p-2 border-top bottom-0 bg-white w-100',
            {
              'bottom-bar-hidden': hidden,
            },
            className
          )}
        >
          <div className='row flex-nowrap justify-content-center text-center'>
            <NavLink
              to='/'
              className='navbar-toggler border-0 text-muted'
              activeClassName='active'
              exact={true}
            >
              <Home className='bottom-icon' />
              <p className='mb-0'>
                <small className='text-capitalize'>{t('dashboard')}</small>
              </p>
            </NavLink>
            <NavLink
              to='/bound-witness'
              className='navbar-toggler border-0 text-muted'
              activeClassName='active'
              exact={true}
            >
              <BoundWitness className='bottom-icon' />
              <p className='mb-0'>
                <small className='text-capitalize'>{t('bound witness')}</small>
              </p>
            </NavLink>
            <NavLink
              to='/devices'
              className='navbar-toggler border-0 text-muted'
              activeClassName='active'
              exact={true}
            >
              <Devices className='bottom-icon' />
              <p className='mb-0'>
                <small className='text-capitalize'>{t('devices')}</small>
              </p>
            </NavLink>
            <NavLink
              to='/archivists'
              className='navbar-toggler border-0 text-muted'
              activeClassName='active'
              exact={true}
            >
              <Archivist className='bottom-icon' />
              <p className='mb-0'>
                <small className='text-capitalize'>{t('archivists')}</small>
              </p>
            </NavLink>
          </div>
        </nav>
      </div>
    </Fragment>
  )
}

// tslint:disable: import-name
// tslint:disable: interface-name
import React, { Component } from 'react'
import { Page, Main } from '../../components/Layout'
import { SlideInDown } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { ResetPassword } from '@xyo-network/tool-storybook-react/dist/lib/auth'

interface ResetPasswordProps {}

const ResetPasswordContainer = class extends Component<
  RouteComponentProps & ResetPasswordProps
> {
  onSuccess = () => {
    this.props.history.push('/login')
  }

  render() {
    return (
      <Page>
        <Main>
          <div className='col mb-5' />
          <SlideInDown>
            <Link to='/' className='btn text-white text-left'>
              <h4>
                <i className='fa fa-times' />
              </h4>
            </Link>
          </SlideInDown>
          <ResetPassword
            className='text-white'
            inputClassName='text-white'
            buttonClassName='btn-info'
            onSuccess={this.onSuccess}
          />
          <div className='col mb-4' />
        </Main>
      </Page>
    )
  }
}

export default withRouter(ResetPasswordContainer)

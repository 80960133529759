import React, { ReactNode } from 'react'
import { Query, QueryResult } from 'react-apollo'
import { myPublicKeys } from '../../gql/queries/myPublicKeys'
import MY_DEVICES_QUERY from '../../gql/queries/myDevices'
import BLOCKS_QUERY from '../../gql/queries/blocks'
import set from 'lodash/set'

interface Props {
  children: (result: QueryResult) => ReactNode
  perPage?: number
}

export default ({ children, perPage }: Props) => {
  return (
    <Query query={MY_DEVICES_QUERY}>
      {(devices: QueryResult) => {
        if (devices.loading) return children(devices)
        if (devices.error) return children(devices)
        const publicKeys = myPublicKeys(devices)
        const variables = {}
        // if (publicKeys.length) set(variables, 'publicKeys', publicKeys)
        set(variables, 'publicKeys', publicKeys)
        if (perPage) set(variables, 'perPage', perPage)
        return (
          <Query query={BLOCKS_QUERY} variables={variables}>
            {children}
          </Query>
        )
      }}
    </Query>
  )
}

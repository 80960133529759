// tslint:disable-next-line: import-name
import gql from 'graphql-tag'
// tslint:disable-next-line: import-name
import BlockCountFragment from '../fragments/blockCount'
// tslint:disable-next-line: import-name
import TimeAggregateFragment from '../fragments/timeAggregate'

export const TOTAL_BLOCKS = gql`
  query TotalBlocks {
    countWitnesses {
      count
    }
  }
`
export const MY_TOTAL_BLOCKS = gql`
  query MyTotalBlocks($publicKeys: [String]!) {
    blocks(publicKeys: $publicKeys) {
      ...BlockCount
    }
  }

  ${BlockCountFragment}
`

export default gql`
  query EarningsCard($range: String, $groupBy: String, $publicKeys: [String]!) {
    totalBlocks: blocks {
      ...BlockCount
    }
    myBlocks: blocks(publicKeys: $publicKeys) {
      ...BlockCount
    }
    # witnessesOverTime(
    #   range: $range
    #   groupBy: $groupBy
    #   publicKeys: $publicKeys
    # ) {
    #   ...TimeAggregate
    # }
  }

  ${BlockCountFragment}
  # ${TimeAggregateFragment}
`

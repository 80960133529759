import React from 'react'
import cx from 'classnames'
import './index.css'

export default () => (
  <div className={cx('lds-ellipsis')}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

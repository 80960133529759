import React, { Component } from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { Page, Main } from '../../components/Layout'
// import {
//   Facebook,
//   Google,
// } from '@xyo-network/tool-storybook-react/dist/lib/auth'
import Logo from '../../components/Logo'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

interface LoginProps {}

class LoginContainer extends Component<RouteComponentProps & LoginProps> {
  onSuccess = () => {
    this.props.history.push('/')
  }
  render() {
    return (
      <Page>
        <Main>
          <div className='col' />
          <SlideInDown>
            <Link to='/bienvenidos' className='btn text-white text-left'>
              <h4>
                <i className='fa fa-times' />
              </h4>
            </Link>
            <div className='text-center mb-2'>
              <Logo />
            </div>
            <h4 className='text-white text-center mb-4'>WELCOME TO XYO</h4>
          </SlideInDown>
          <SlideInUp>
            <Link
              className='d-block btn btn-lg btn-info w-100 mt-4 mb-4'
              to='/login'
            >
              LOGIN WITH EMAIL
            </Link>
            {/* <div className='mb-4'>
              <Google onSuccess={this.onSuccess}>LOGIN WITH GOOGLE</Google>
            </div>
            <Facebook onSuccess={this.onSuccess}>LOGIN WITH FACEBOOK</Facebook> */}
          </SlideInUp>
          <div className='col mb-4' />
        </Main>
      </Page>
    )
  }
}

export default withRouter(LoginContainer)

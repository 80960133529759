import React, { Fragment } from 'react'
import Navbar from '../../components/Navbar'
import Account from '../../components/Account'

export default () => (
  <Fragment>
    <Navbar goBack={true} />
    <Account />
  </Fragment>
)

// tslint:disable-next-line: import-name
import React from 'react'
// tslint:disable-next-line: import-name
import gql from 'graphql-tag'
// tslint:disable-next-line: import-name
import AddressFragment from '../fragments/address'
import { Query } from 'react-apollo'
import get from 'lodash/get'

export const ADDRESS_BY_DNS = gql`
  query AddressByDNS($dns: String) {
    addressByDNS(dns: $dns) {
      ...Address
    }
  }

  ${AddressFragment}
`

export const ADDRESS_BY_PUBLIC_KEY = gql`
  query AddressByPublicKey($publicKey: String) {
    addressByPublicKey(publicKey: $publicKey) {
      ...Address
    }
  }

  ${AddressFragment}
`

const addressQueryFactory = (query, path) => ({
  variables,
  children,
  skip,
}) => (
  <Query query={ADDRESS_BY_DNS} variables={variables} skip={skip}>
    {({ data, loading, error }: any) =>
      children({ loading, error, data: get(data, path) })
    }
  </Query>
)

export const AddressByDNS = addressQueryFactory(ADDRESS_BY_DNS, 'addressByDNS')
export const AddressByPublicKey = addressQueryFactory(
  ADDRESS_BY_PUBLIC_KEY,
  'addressByPublicKey'
)

// tslint:disable: import-name
// tslint:disable: interface-name
// tslint:disable: object-shorthand-properties-first
// tslint:disable: align
import React, { useEffect, useState } from 'react'
import { Mutation } from 'react-apollo'
import { useAsyncEffect } from '@xyo-network/tool-storybook-react/dist/lib/hooks'
import { useXyAccountContext } from '@xyo-network/tool-storybook-react/dist/lib/auth'
import Text from '@xyo-network/tool-storybook-react/dist/lib/Form/Text'
import Select from '@xyo-network/tool-storybook-react/dist/lib/Form/Select'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import ADD_BRIDGE from '../../gql/mutations/addBridge'
import over from 'lodash/over'
import { addedDevice } from '../../gql/cache'
import get from 'lodash/get'
import identity from 'lodash/identity'
// import cx from 'classnames'
import {
  IBluetoothDevice,
  NETWORK_SERVICE_UUID,
  CONNECT_UUID,
  STATUS_UUID,
  SSID_UUID,
  IP_UUID,
  SCAN_UUID,
} from '../../utils/bluetooth'
import WifiStatus from '@xyo-network/tool-storybook-react/dist/lib/Icons/Wifi'
import parseError from '../../utils/parseError'
import { useTranslation } from 'react-i18next'
import { useAsyncReducer } from '../../utils/asyncReducer'
import size from 'lodash/size'
import { AddressByDNS } from '../../gql/queries/address'
import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'
import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'

type ErrorCallback = (err: Error | null, data?: any) => void

interface AddBridgeProps {
  device: IBluetoothDevice | null
  onSuccess: () => void
}

async function readValue(char: any) {
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(await char.readValue())
}

async function writeValue(char: any, data: any) {
  const encoder = new TextEncoder()
  return char.writeValue(encoder.encode(data))
}

async function writeJson(char: any, data: any) {
  return writeValue(char, JSON.stringify(data))
}

async function subscribeTo(characteristic) {
  const eventName = 'characteristicvaluechanged'
  await characteristic.startNotifications()
  return (fn: ErrorCallback) => {
    const handleChange = (ev: any) => {
      const val = ev.target.value
      const str = new TextDecoder('utf-8').decode(val)
      fn(null, str)
    }

    characteristic.addEventListener(eventName, handleChange)

    return () => {
      characteristic
        .stopNotifications()
        .then(() => {
          characteristic.removeEventListener(eventName, handleChange)
        })
        .catch((err: any) => fn(err))
    }
  }
}

async function networkService(device) {
  if (!device) return
  const server = await device.gatt.connect()
  const service = await server.getPrimaryService(NETWORK_SERVICE_UUID)
  const [
    connectChar,
    ssidChar,
    ipChar,
    statusChar,
    scanChar,
  ] = await Promise.all([
    service.getCharacteristic(CONNECT_UUID),
    service.getCharacteristic(SSID_UUID),
    service.getCharacteristic(IP_UUID),
    service.getCharacteristic(STATUS_UUID),
    service.getCharacteristic(SCAN_UUID),
  ])
  const connect = async({ ssid, password, pin }) => {
    return writeJson(connectChar, { ssid, password, pin })
  }
  const scan = async() => {
    const str = await readValue(scanChar)
    return (str || '').split(',')
  }
  const subscribeToStatus = await subscribeTo(statusChar)
  const subscribeToSSID = await subscribeTo(ssidChar)
  const subscribeToIP = await subscribeTo(ipChar)
  const onDisconnect = (fn: () => void) => {
    device.addEventListener('gattserverdisconnected', fn)
    return () => {
      device.removeEventListener('gattserverdisconnected', fn)
    }
  }
  return {
    id: device.id,
    connect,
    scan,
    subscribeToStatus,
    subscribeToSSID,
    subscribeToIP,
    onDisconnect,
  }
}

const Status = ({ status }) => {
  if (status === '0') return <p className='mb-0 text-danger'>Offline</p>
  if (status === '1') return <Loader />
  if (status === '2') return <p className='mb-0 text-success'>Connected</p>
  return <div />
}

const toWifiStatus = (status: string) => {
  switch (status) {
    case '0':
      return 'offline'
    case '2':
      return 'connected'
    default:
      return 'connecting'
  }
}

const DisplayBridgeStatus = ({ loading, error, data }) => {
  return (
    <div className='d-flex mb-2 align-items-center'>
      <WifiStatus status={toWifiStatus(data)} className='line-height-1 mr-1' />{' '}
      Status <span className='col' />{' '}
      <DisplayAsync {...{ loading, error, data }}>
        {(data) => {
          return <Status status={data} />
        }}
      </DisplayAsync>
    </div>
  )
}

const DisplayBridgeSSID = ({ loading, error, data }) => {
  return (
    <div className='d-flex mb-2 align-items-center'>
      Network <span className='col' />{' '}
      <DisplayAsync {...{ loading, error, data }}>
        {(data) => {
          return data
        }}
      </DisplayAsync>
    </div>
  )
}

const DisplayBridgeIP = ({ loading, error, data }) => {
  return (
    <div className='d-flex mb-2 align-items-center'>
      IP <span className='col' />{' '}
      <DisplayAsync {...{ loading, error, data }}>
        {(data) => {
          return (
            <a href={`http://${data}`} target='_blank'>
              {data}
            </a>
          )
        }}
      </DisplayAsync>
    </div>
  )
}

const ConnectBridge = ({ connect, scan, onCancel }) => {
  if (!connect) return <div />
  const scanner = useAsyncEffect<string[]>(() => scan(), scan)
  const onSubmit = async(ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    const ssid = get(ev, 'target.ssid.value')
    const password = get(ev, 'target.password.value')
    const pin = get(ev, 'target.pin.value')
    try {
      onCancel()
      await connect({
        ssid,
        password,
        pin,
      })
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      <form onSubmit={onSubmit} id='connect-wifi'>
        {scanner.loading ? (
          <div className='form-group'>
            <label className='d-block'>Wifi Network Name</label>
            <Loader />
          </div>
        ) : scanner.error || !size(scanner.data) ? (
          <Text id='ssid' name='ssid' label='Wifi Network Name' type='text' />
        ) : (
          <Select
            id='ssid'
            name='ssid'
            label='Wifi Network Name'
            options={scanner.data || []}
          />
        )}
        <Text
          id='password'
          name='password'
          label='Wifi Password'
          type='password'
        />
        <Text id='pin' name='pin' label='Pin' type='password' />
      </form>
      <button className='btn btn-danger mr-1' onClick={onCancel}>
        Cancel
      </button>
      <button className='btn btn-primary' type='submit' form='connect-wifi'>
        Connect
      </button>
    </>
  )
}

const DisplayAsync = ({ loading, error, data, children }) => {
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }
  if (error) return <p className='text-danger mb-0'>{error}</p>
  return children(data)
}

export default ({ device, onSuccess }: AddBridgeProps) => {
  const handleSubmit = (submit) => {
    return (ev: any) => {
      ev.preventDefault()
      const variables = {
        id: get(networkBleService, 'data.id'),
        name: 'XYO Bridge',
        dns: get(ip, 'data'),
        port: 80,
      }
      submit({ variables })
    }
  }
  const { t } = useTranslation()
  const xya = useXyAccountContext()
  const user = xya.currentUser()
  const [disconnected, setDisconnected] = useState(false)
  const networkBleService = useAsyncEffect(() => networkService(device), device)
  const [status, statusActions] = useAsyncReducer()
  const [ssid, ssidActions] = useAsyncReducer()
  const [ip, ipActions] = useAsyncReducer()
  const [showConnect, setShowConnect] = useState(false)
  const setShowConnectActive = () => setShowConnect(true)
  const setShowConnectInactive = () => setShowConnect(false)
  useEffect(() => {
    if (!get(networkBleService, 'data')) return
    const errorStateCb = (actions) => {
      return (err, data) => {
        err ? actions.setError(err.message) : actions.setData(data)
      }
    }
    const subscribeToStatus =
      get(networkBleService, 'data.subscribeToStatus') || identity
    const subscribeToSSID =
      get(networkBleService, 'data.subscribeToSSID') || identity
    const subscribeToIP =
      get(networkBleService, 'data.subscribeToIP') || identity
    const subscribeToDisconnect =
      get(networkBleService, 'data.onDisconnect') || identity
    statusActions.setLoading()
    ssidActions.setLoading()
    ipActions.setLoading()
    const unsubscribeFromStatus = subscribeToStatus(errorStateCb(statusActions))
    const unsubscribeFromSSID = subscribeToSSID(errorStateCb(ssidActions))
    const unsubscribeFromIP = subscribeToIP(errorStateCb(ipActions))
    const unsubscribeFromDisconnect = subscribeToDisconnect(() =>
      setDisconnected(true)
    )
    return () => {
      over(
        unsubscribeFromStatus,
        unsubscribeFromSSID,
        unsubscribeFromIP,
        unsubscribeFromDisconnect
      )()
    }
  }, [networkBleService.data])
  return (
    <Mutation
      mutation={ADD_BRIDGE}
      update={over([addedDevice('addBridge', 'myBridges'), onSuccess])}
    >
      {(addBridge, { loading, error }: any) => (
        <>
          <DisplayBridgeStatus
            {...status}
            loading={status.loading || networkBleService.loading}
          />
          <DisplayBridgeSSID
            {...ssid}
            loading={ssid.loading || networkBleService.loading}
          />
          <DisplayBridgeIP
            {...ip}
            loading={ip.loading || networkBleService.loading}
          />
          {showConnect ? (
            <ConnectBridge
              scan={get(networkBleService, 'data.scan')}
              connect={get(networkBleService, 'data.connect')}
              onCancel={setShowConnectInactive}
            />
          ) : (
            ''
          )}
          {disconnected && (
            <p className='text-danger text-capitalize'>
              {t('bridge disconnected')}
            </p>
          )}
          {error && <p className='text-danger'>{parseError(error.message)}</p>}
          {networkBleService.error ? (
            <p className='text-danger'>{networkBleService.error}</p>
          ) : loading ? (
            <div className='text-center'>
              <Loader />
            </div>
          ) : showConnect ? (
            ''
          ) : (
            <AddressByDNS variables={{ dns: ip.data }} skip={!ip.data}>
              {({ data, loading, error }) =>
                loading ? (
                  <Loader />
                ) : get(data, 'owner') === get(user, 'uid') ? (
                  <>
                    <Alert type={BootstrapType.primary}>
                      Bridge named "{get(data, 'name')}" is already claimed by
                      this account.
                    </Alert>
                    <button
                      onClick={setShowConnectActive}
                      className='btn btn-primary text-capitalize mr-1'
                    >
                      {t('change wifi')}
                    </button>
                  </>
                ) : data ? (
                  <Alert type={BootstrapType.danger}>
                    You are not the owner of this bridge
                  </Alert>
                ) : (
                  <>
                    <button
                      onClick={setShowConnectActive}
                      className='btn btn-primary text-capitalize mr-1'
                    >
                      {t('change wifi')}
                    </button>
                    {ip.data ? (
                      <button
                        className='btn btn-success text-white'
                        onClick={handleSubmit(addBridge)}
                      >
                        Save
                      </button>
                    ) : (
                      ''
                    )}
                  </>
                )
              }
            </AddressByDNS>
          )}
        </>
      )}
    </Mutation>
  )
}

// tslint:disable: import-name
import Web3 from 'web3'
import { SagaIterator } from 'redux-saga'
import { put, call, all } from 'redux-saga/effects'
import { createAction, createReducer } from 'redux-delta'
import { connect } from 'react-redux'
import { IRootStore } from '../../types'
import { IWeb3Store } from './types'
// import { PortisProvider } from 'portis'
// import xyoTokenAbi from './xyoTokenAbi'
// import { AbiItem } from 'web3-utils/types'
import get from 'lodash/get'

// const portisApiKey = process.env.REACT_APP_PORTIS_API_KEY || ''
export const setAccounts = createAction('SET_ACCOUNTS')
export const fetchAccountBalance = createAction('GET_ACCOUNT_BALANCE')
export const setAccountBalance = createAction('SET_ACCOUNT_BALANCE')
export const setNetwork = createAction('SET_NETWORK')
export const setWeb3Error = createAction('SET_WEB3_ERROR')

export const web3 = createReducer(
  {
    balances: {},
  },
  [
    setAccounts.case((_, accounts) => ({ accounts })),
    setNetwork.case((_, network) => ({ network })),
    setAccountBalance.case(({ balances }, balance) => ({
      balances: { ...balances, balance },
    })),
    setWeb3Error.case((_, error) => ({ error })),
  ]
)

export const withWeb3Controls = connect((store: IRootStore) => store.web3)

export const getAccountBalance = (
  web3: IWeb3Store,
  accountIndex: number
): number => {
  const address = get(web3, `accounts[${accountIndex}]`)
  return get(web3, `balances.${address}`) || 0
}

// function* fetchAccountBalanceSaga(
//   web3: Web3,
//   xyoTokenAddress: string | undefined,
//   { payload }: { payload: string }
// ) {
//   try {
//     const contract = new web3.eth.Contract(
//       (xyoTokenAbi as unknown) as AbiItem,
//       xyoTokenAddress
//     )
//     const balance = yield call(contract.methods.balanceOf(payload).call)
//     const decimals = yield call(contract.methods.decimals().call)
//     const value = Number(balance) / 10 ** Number(decimals)
//     yield put(setAccountBalance({ [payload]: value }))
//   } catch (err) {
//     yield put(setWeb3Error(err.message))
//   }
// }

export default function*(): SagaIterator {
  try {
    if (!Web3.givenProvider) throw new Error('No provider specified')
    const web3 = new Web3(Web3.givenProvider)

    // yield takeEvery(
    //   fetchAccountBalance.type,
    //   fetchAccountBalanceSaga,
    //   web3,
    //   process.env.REACT_APP_XYO_TOKEN_ADDRESS
    // )

    if (!(web3.currentProvider as any).isPortis) {
      const [accounts, networkId] = yield all([
        call(web3.eth.getAccounts),
        call(web3.eth.net.getId),
      ])
      yield put(setAccounts(accounts))
      yield put(setNetwork(networkId))
      yield all(
        accounts.map((account: string) => put(fetchAccountBalance(account)))
      )
    }
  } catch (err) {
    yield put(setWeb3Error(err.message))
  }
}

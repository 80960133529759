import React, {
  Fragment,
  ReactElement,
  createContext,
  cloneElement,
  useContext,
} from 'react'
import { createPortal } from 'react-dom'
import WillEnter, { Status } from '../WillEnter'
import cx from 'classnames'
import './index.css'

interface BottomDrawerPortalProps {
  show: boolean
  className?: string
  backdropClassName?: string
  onClose?: () => void
  children: ReactElement<any>
}

export const context = createContext('bottom-drawer-container')
export const Consumer = context.Consumer
export const Provider = context.Provider
export const useBottomDrawerContext = () => {
  return useContext(context)
}

export default class BottomDrawerPortal extends WillEnter<
  BottomDrawerPortalProps
> {
  renderBottomDrawer = () => {
    const { status } = this.state
    const { show, children, onClose, className, backdropClassName } = this.props
    return (
      <Fragment>
        <div
          className={cx(
            'bottom-drawer',
            {
              show: status === Status.ENTERED,
              'd-none': status === Status.EXITED,
            },
            className
          )}
        >
          {cloneElement(children, { onClose })}
        </div>
        <div
          className={cx(
            'modal-backdrop',
            { show, hidden: !open },
            backdropClassName
          )}
          onClick={onClose}
        />
      </Fragment>
    )
  }
  renderPortal = (id: string) => {
    const node = document.getElementById(id)
    if (!node) {
      throw new Error(`No element found with id: ${id}`)
    } else {
      return createPortal(this.renderBottomDrawer(), node)
    }
  }
  render() {
    return <Consumer>{this.renderPortal}</Consumer>
  }
}

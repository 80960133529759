// tslint:disable: import-name
// tslint:disable: interface-name
import React, { Component } from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { Page, Main } from '../../components/Layout'
import { SignIn } from '@xyo-network/tool-storybook-react/dist/lib/auth'

interface SignUpProps {}

class SignUpContainer extends Component<RouteComponentProps & SignUpProps> {
  onSuccess = () => {
    this.props.history.push('/')
  }
  render() {
    return (
      <Page>
        <Main>
          <div className='col-md' />
          <Link to='/' className='btn text-white text-left'>
            <h4>
              <i className='fa fa-times' />
            </h4>
          </Link>
          <SignIn
            onSuccess={this.onSuccess}
            className='col'
            inputClassName='text-white'
            buttonClassName='btn-info'
            loaderClassName='text-white'
          />
          <div className='col-md mb-4' />
        </Main>
      </Page>
    )
  }
}

export default withRouter(SignUpContainer)

// tslint:disable: jsx-no-lambda

import React, { Fragment } from 'react'
import { ScrollPage, ScrollDirection } from '../../components/Layout'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import {
  FadeIn,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { Footer } from '../../components/Footer'
import { BoundWitnessAccordion } from '@xyo-network/tool-storybook-react/dist/lib/BoundWitness'
import { useTranslation } from 'react-i18next'
import MyBoundWitnesses from '../../components/BoundWitness/MyBoundWitnesses'
import NoneFound from '../../components/BoundWitness/NoneFound'
import size from 'lodash/size'
import get from 'lodash/get'
import './index.css'

export default () => {
  const { t } = useTranslation()
  return (
    <ScrollPage
      navigationBar={({ direction, position }) => (
        <Navbar
          hidden={direction === ScrollDirection.down}
          transparent={!position}
        />
      )}
      bottomBar={({ direction }) => (
        <BottomBar
          hidden={direction === ScrollDirection.up}
          className='d-md-none'
        />
      )}
    >
      <Fragment>
        <div className='position-relative bg-primary bg-md-white mb-0 clear-top pb-3'>
          <div className='container'>
            <h4 className='d-none d-md-block text-uppercase'>
              {t('bound witnesses')}
            </h4>
          </div>
          <h4 className='d-md-none text-center text-white text-uppercase'>
            <SlideInUp>{t('bound witnesses')}</SlideInUp>
          </h4>
        </div>
        <div className='bg-white flex-grow-1 pt-3'>
          <div className='container'>
            <MyBoundWitnesses>
              {({ data, loading }) =>
                loading ? (
                  <div className='text-center text-primary mt-2'>
                    <Loader />
                  </div>
                ) : size(get(data, 'blocks.items')) ? (
                  <FadeIn>
                    <BoundWitnessAccordion
                      boundWitnesses={get(data, 'blocks.items') || []}
                    />
                  </FadeIn>
                ) : (
                  <NoneFound />
                )}
            </MyBoundWitnesses>
          </div>
          <div className='pt-5 pb-5' />
        </div>
        <Footer className='d-none d-md-block' />
      </Fragment>
    </ScrollPage>
  )
}

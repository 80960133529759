// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
    <path
      fill='currentColor'
      d='M15.7,4.2c-0.8,0-1.5,0-2.3,0c0-0.2,0-0.3,0-0.5c-4.2,0-8.5,0-12.7,0c0-0.4,0-0.8,0-1.2c4.2,0,8.5,0,12.7,0
		c0-0.2,0-0.4,0-0.6c0.8,0,1.5,0,2.3,0C15.7,2.6,15.7,3.4,15.7,4.2z'
    />
    <path
      fill='currentColor'
      d='M15.7,7.1c0,0.4,0,0.8,0,1.2c-2.3,0-4.6,0-6.9,0c0,0.2,0,0.4,0,0.6c-0.8,0-1.5,0-2.3,0c0-0.2,0-0.3,0-0.6
		c-1.9,0-3.8,0-5.8,0c0-0.4,0-0.8,0-1.1c1.9,0,3.8,0,5.7,0c0-0.2,0-0.4,0-0.6c0.8,0,1.5,0,2.3,0c0,0.2,0,0.3,0,0.5
		C11.1,7.1,13.4,7.1,15.7,7.1z'
    />
    <path
      fill='currentColor'
      d='M4.1,12.9c0,0.2,0,0.4,0,0.6c-0.8,0-1.5,0-2.3,0c0-0.2,0-0.3,0-0.5c-0.4,0-0.7,0-1.1,0c0-0.4,0-0.8,0-1.2
		c0.4,0,0.7,0,1.1,0c0-0.2,0-0.4,0-0.6c0.8,0,1.5,0,2.3,0c0,0.2,0,0.3,0,0.5c3.8,0,7.7,0,11.5,0c0,0.4,0,0.8,0,1.2
		C11.9,12.9,8,12.9,4.1,12.9z'
    />
  </svg>
)

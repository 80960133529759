import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose'

interface AnimationProps {
  id?: string
  timer?: number
  active?: boolean
  className?: string
  onClick?: (...args: any[]) => void
}

const Fade = (options: any) => {
  const Pose = posed.div(options)
  return class extends Component<AnimationProps> {
    state = { active: false }
    timerId: any

    componentDidMount() {
      this.timerId = setTimeout(() => {
        this.setState({ active: true })
      }, this.props.timer || 100)
    }

    componentWillUnmount() {
      clearTimeout(this.timerId)
    }

    render() {
      const active =
        typeof this.props.active === 'boolean'
          ? this.props.active
          : this.state.active
      return (
        <PoseGroup>
          {active && (
            <Pose key={this.props.id || 'child'} {...this.props}>
              {this.props.children}
            </Pose>
          )}
        </PoseGroup>
      )
    }
  }
}

export const FadeIn = Fade({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

export const SlideInRight = Fade({
  enter: { transform: 'translateX(0%)', opacity: 1 },
  exit: { transform: 'translateX(-100%)', opacity: 0 },
})

export const SlideInLeft = Fade({
  enter: { transform: 'translateX(0%)', opacity: 1 },
  exit: { transform: 'translateX(100%)', opacity: 0 },
})

export const SlideInUp = Fade({
  enter: { transform: 'translateY(0%)', opacity: 1 },
  exit: { transform: 'translateY(100%)', opacity: 0 },
})

export const SlideInDown = Fade({
  enter: { transform: 'translateY(0%)', opacity: 1 },
  exit: { transform: 'translateY(-100%)', opacity: 0 },
})

import gql from 'graphql-tag'

export default gql`
  mutation UpdateBridge(
    $id: String!
    $name: String
    $photoUrl: String
    $publicKey: String
    $uuid: String
    $major: Int
    $minor: Int
  ) {
    updateBridge(
      address: {
        id: $id
        publicKey: $publicKey
        name: $name
        photoUrl: $photoUrl
      }
      bridge: { uuid: $uuid, major: $major, minor: $minor }
    ) {
      id
      name
      photoUrl
      publicKey
      uuid
      major
      minor
    }
  }
`

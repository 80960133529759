import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  return (
    <div className='bg-primary p-3'>
      <h4 className='text-white py-4 border-bottom'>
        <i className='fa fa-bell' />{' '}
        <span className='text-uppercase'>{t('notifications')}</span>
      </h4>
      <div className='row'>
        <div className='col text-muted'>
          <p className='mb-0 hash-overflow'>BLE_001</p>
          <p className='mb-0'>
            <small>Connected with 0x03i0je0jc9ee0</small>
          </p>
        </div>
        <div className='p-2 text-right'>
          <Link className='text-success' to='/bound-witness'>
            {t('view origin')}
          </Link>
        </div>
      </div>
    </div>
  )
}

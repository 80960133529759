import React, { Fragment } from 'react'
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom'
import {
  useXyAccountContext,
  isXYAccount,
} from '@xyo-network/tool-storybook-react/dist/lib/auth/context'
import { useTranslation } from 'react-i18next'
import { useXyoClient } from '../../gql/context'

const Account = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation()
  const client = useXyoClient()
  const xya = useXyAccountContext()
  const onLogOut = () => {
    if (isXYAccount(xya)) xya.signOut()
    history.push('/welcome')
    client.resetStore()
  }
  return (
    <Fragment>
      <div className='bg-primary p-3 pt-5'>
        <h4 className='text-center text-white text-uppercase'>
          {t('account')}
        </h4>
      </div>
      <div className='bg-light p-2 text-uppercase'>
        <p className='mb-0'>{t('account')}</p>
      </div>
      <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/profile'
      >
        {t('my profile')}
      </NavLink>
      <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/archivists'
      >
        {t('archivists')}
      </NavLink>
      <NavLink className='p-2 border-bottom d-block text-capitalize' to='/scan'>
        {t('local bridges')}
      </NavLink>
      <NavLink className='p-2 border-bottom d-block text-capitalize' to='/kyc'>
        {t('kyc')}
      </NavLink>
      <NavLink className='p-2 border-bottom d-block text-capitalize' to='/staking'>
        {t('staking')}
      </NavLink>
      {/* <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/passwords'
      >
        {t('passwords')}
      </NavLink> */}
      {/* <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/notifications'
      >
        {t('notifications')}
      </NavLink> */}
      <div className='bg-light p-2 text-uppercase'>
        <p className='mb-0'>{t('support')}</p>
      </div>
      <a
        className='p-2 border-bottom d-block text-capitalize'
        href={process.env.REACT_APP_TUTORIAL_URI || ''}
        target='_blank'
      >
        {t('tutorials')}
      </a>
      <a
        className='p-2 border-bottom d-block text-capitalize'
        href={process.env.REACT_APP_GET_HELP_URI || ''}
        target='_blank'
      >
        {t('get help')}
      </a>
      <div className='bg-light p-2 text-uppercase'>
        <p className='mb-0'>{t('privacy')}</p>
      </div>
      {/* <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/sharing'
      >
        {t('sharing')}
      </NavLink> */}
      <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/privacy-policy'
      >
        {t('privacy policy')}
      </NavLink>
      <NavLink
        className='p-2 border-bottom d-block text-capitalize'
        to='/terms-of-service'
      >
        {t('terms of Service')}
      </NavLink>
      <div className='p-2'>
        <button
          className='btn btn-secondary text-uppercase w-100'
          onClick={onLogOut}
        >
          {t('log out')}
        </button>
      </div>
    </Fragment>
  )
}

export default withRouter(Account)

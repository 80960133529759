// tslint:disable: import-name
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  Private,
  useXyAccountContext,
} from '@xyo-network/tool-storybook-react/dist/lib/auth'
import Bienvenidos from './Bienvenidos'
import Welcome from './Welcome'
import SignIn from './SignIn'
import SignUp from './SignUp'
import Login from './Login'
import LoginOption from './LoginOption'
import SignUpOption from './SignUpOption'
import ResetPassword from './ResetPassword'
import {
  Dashboard,
  Account,
  BoundWitness,
  Nearby,
  MyDevices,
  Archivists,
  Profile,
  ScanBridge,
  NotFound,
  Terms,
  Privacy,
  KYC,
  Staking,
} from './routes'
import splitTest from './splitTest'

const pickLoginRoute = splitTest([
  ['/welcome', 1],
  ['/bienvenidos', 0],
])
const selectedRoute = pickLoginRoute()

const PrivateRoute = ({ component: Component, ...rest }) => {
  const xya = useXyAccountContext()
  const render = (props) => {
    return xya.isSignedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location.pathname },
        }}
      />
    )
  }
  return <Route {...rest} render={render} />
}

export default () => (
  <Switch>
    <Route path='/welcome' component={Welcome} exact={true} />
    <Route path='/bienvenidos' component={Bienvenidos} exact={true} />
    <Route path='/signup-option' component={SignUpOption} exact={true} />
    <Route path='/login-option' component={LoginOption} exact={true} />
    <Route path='/signin' component={SignIn} exact={true} />
    <Route path='/signup' component={SignUp} exact={true} />
    <Route path='/login' component={Login} exact={true} />
    <Route path='/reset-password' component={ResetPassword} exact={true} />
    <Route path='/terms-of-service' component={Terms} exact={true} />
    <Route path='/privacy-policy' component={Privacy} exact={true} />
    <Private path='/' to={selectedRoute} component={Dashboard} exact={true} />
    <Private
      path='/account'
      to={selectedRoute}
      component={Account}
      exact={true}
    />
    <Private
      path='/bound-witness'
      to={selectedRoute}
      component={BoundWitness}
      exact={true}
    />
    <Private
      path='/nearby'
      to={selectedRoute}
      component={Nearby}
      exact={true}
    />
    <Private
      path='/devices'
      to={selectedRoute}
      component={MyDevices}
      exact={true}
    />
    <Private
      path='/archivists'
      to={selectedRoute}
      component={Archivists}
      exact={true}
    />
    <Private
      path='/profile'
      to={selectedRoute}
      component={Profile}
      exact={true}
    />
    <Private
      path='/scan'
      to={selectedRoute}
      component={ScanBridge}
      exact={true}
    />
    <PrivateRoute path='/kyc' to={selectedRoute} component={KYC} />
    <PrivateRoute path='/staking' to={selectedRoute} component={Staking} />
    <Redirect from='/index.html' to='/' />
    <Route component={NotFound} />
  </Switch>
)

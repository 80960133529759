// tslint:disable: import-name
// tslint:disable: jsx-wrap-multiline
import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap'

import App from './App'
import createStore from './store'
import registerServiceWorker from './registerServiceWorker'
import initTranslations from './translate'
import { Provider } from 'react-redux'
import ErrorBoundary from './components/ErrorBoundary'
import MainError from './components/MainError'
import './styles/index.scss'

console.log('Welcome to XYO')
const store = createStore()
initTranslations()

ReactDOM.render(
  <ErrorBoundary fallback={MainError}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement
)

const preloadLink = (src) => {
  const link = document.createElement('link')
  link.href = src
  link.rel = 'stylesheet'
  document.head.appendChild(link)
}

const preloadedLinks = [
  'https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700|Iceland|Orbitron:400,700|Share+Tech',
  'https://use.fontawesome.com/releases/v5.6.3/css/all.css',
]

preloadedLinks.forEach(preloadLink)

registerServiceWorker()

// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' {...props}>
    <path
      fill='currentColor'
      d='M2.65,4.16L1.2,5.65L0.34,4.83l2.91-2.8L6.09,4.8L5.25,5.59L3.81,4.2C3.8,4.26,3.79,4.32,3.79,4.39
      c0,2.66,0,5.33,0,7.99c-0.04,1.06,0.63,2.03,1.64,2.36c1.23,0.39,2.54-0.28,2.93-1.51c0.07-0.23,0.11-0.47,0.11-0.71
      c0-2.26,0-4.53,0-6.79c0-0.25,0.01-0.49,0.05-0.73c0.16-1.25,1-2.3,2.18-2.74c1.17-0.5,2.52-0.27,3.47,0.57
      c0.84,0.68,1.32,1.71,1.28,2.79c0,2.63,0,5.26,0,7.9v0.33l1.39-1.39l0.82,0.83c-0.94,0.91-1.89,1.84-2.81,2.72l-2.83-2.76l0.84-0.8
      l1.43,1.44c0-0.15,0.02-0.22,0.02-0.29V5.55c0.01-1.11-0.76-2.07-1.84-2.31c-1.1-0.26-2.21,0.33-2.63,1.37
      c-0.13,0.33-0.2,0.69-0.2,1.04c0,2.23,0,4.46,0,6.69C9.69,13.6,9.07,14.79,8,15.46c-1.34,0.86-3.1,0.68-4.24-0.44
      c-0.72-0.69-1.12-1.65-1.1-2.65c0-2.62,0-5.25,0-7.87L2.65,4.16z'
    />
  </svg>
)

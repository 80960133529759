// tslint:disable: max-line-length
import React from 'react'

export default props => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
    <path
      fill='currentColor'
      d='M16,13c-1.3,0-2.4,0.8-2.8,2H3v2h10.2c0.4,1.2,1.5,2,2.8,2s2.4-0.8,2.8-2H29v-2H18.8C18.4,13.8,17.3,13,16,13z
      M16,15c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S15.4,15,16,15z'
    />
  </svg>
)

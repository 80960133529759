// tslint:disable: jsx-no-lambda
// tslint:disable: import-name
// tslint:disable: jsx-wrap-multiline

import React, { Fragment, useState } from 'react'
import { ScrollPage, ScrollDirection } from '../../components/Layout'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import ProfileCard from '../../components/ProfileCard'
import Modal, {
  ModalContent,
} from '@xyo-network/tool-storybook-react/dist/lib/Modal'
import { Footer } from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import {
  Container,
  PageHeader,
  TitleMd,
  TitleXs,
} from '../../components/Bootstrap'

const Profile = () => {
  const { t } = useTranslation()
  const [deleteModalActive, setDeleteModalActive] = useState(false)
  const setDeleteModalClosed = () => setDeleteModalActive(false)
  // const setDeleteModalOpen = () => setDeleteModalActive(true)

  return (
    <ScrollPage
      navigationBar={({ direction, position }) => (
        <Navbar
          hidden={direction === ScrollDirection.down}
          transparent={!position}
        />
      )}
      bottomBar={({ direction }) => (
        <BottomBar
          hidden={direction === ScrollDirection.up}
          className='d-md-none'
        />
      )}
    >
      <Fragment>
        <Modal open={deleteModalActive} onClose={setDeleteModalClosed}>
          <ModalContent
            className='overflow-hidden'
            headerClassName='bg-info text-white rounded-0'
            title='Are you sure you would like to delete?'
            footer={
              <Fragment>
                <button
                  className='btn btn-danger text-capitalize'
                  onClick={setDeleteModalClosed}
                >
                  {t('confirm')}
                </button>
                <button
                  className='btn btn-success text-capitalize'
                  onClick={setDeleteModalClosed}
                >
                  {t('cancel')}
                </button>
              </Fragment>
            }
          />
        </Modal>
        <PageHeader>
          <Container>
            <TitleMd>{t('my profile')}</TitleMd>
          </Container>
          <TitleXs>
            <SlideInUp>{t('my profile')}</SlideInUp>
          </TitleXs>
        </PageHeader>
        <div className='flex-grow-1 bg-white pt-3'>
          <div className='container'>
            <div className='row'>
              <div className='offset-md-3 col-md-6'>
                <ProfileCard />
                {/* <button
                  className='btn btn-danger mb-5'
                  onClick={setDeleteModalOpen}
                >
                  Delete Profile
                </button> */}
                <div className='pt-5 pb-3' />
              </div>
            </div>
          </div>
        </div>

        <Footer className='d-none d-md-block' />
      </Fragment>
    </ScrollPage>
  )
}

export default Profile

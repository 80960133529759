// tslint:disable: import-name
// tslint:disable: interface-name
// tslint:disable: object-shorthand-properties-first
// tslint:disable: align
import React, { createContext, useState, useEffect, useContext } from 'react'
import Web3 from 'web3'

declare global {
  interface Window {
    ethereum: any
    web3: any
  }
}

const web3ContextValue = (value: Web3 | undefined, error: string = '') => ({
  initialized: !!value,
  value,
  error,
})

export const enableEthereum = async(value: Web3) => {
  if (window.ethereum) {
    const value = await window.ethereum.enable()
    return value
  }
  return null
}

const web3Context = createContext(web3ContextValue(undefined))
const initWeb3Context = createContext({
  web3: web3ContextValue(undefined),
  initWeb3: () => {},
})

export default ({ children }) => {
  const [web3, setWeb3] = useState(web3ContextValue(undefined))
  useEffect(() => {
    if (window.ethereum) {
      const _web3 = new Web3(window.ethereum)
      setWeb3(web3ContextValue(_web3))
    } else if (window.web3) {
      const _web3 = new Web3(window.web3.currentProvider)
      setWeb3(web3ContextValue(_web3))
    } else {
      setWeb3(
        web3ContextValue(
          undefined,
          'Non-Ethereum browser detected. You should consider trying MetaMask!'
        )
      )
    }
  }, [])
  return <web3Context.Provider value={web3}>{children}</web3Context.Provider>
}

export const useWeb3IsInitialized = () => {
  return useContext(web3Context).initialized
}

export const useWeb3 = () => {
  return useContext(web3Context).value
}

export const InitWeb3Provider = ({ children }) => {
  const [web3, setWeb3] = useState(web3ContextValue(undefined))
  const initWeb3 = () => {
    if (window.ethereum) {
      window.ethereum.enable().then(() => {
        const _web3 = new Web3(window.ethereum)
        setWeb3(web3ContextValue(_web3))
      })
    } else if (window.web3) {
      const _web3 = new Web3(window.web3.currentProvider)
      setWeb3(web3ContextValue(_web3))
    } else {
      setWeb3(
        web3ContextValue(
          undefined,
          'Non-Ethereum browser detected. You should consider trying MetaMask!'
        )
      )
    }
  }

  return (
    <initWeb3Context.Provider value={{ web3, initWeb3 }}>
      {children}
    </initWeb3Context.Provider>
  )
}

export const useInitWeb3 = () => {
  return useContext(initWeb3Context)
}

import React, { Fragment, ReactNode } from 'react'
import { Container, Row, Offset } from '../Bootstrap'
import { useTranslation } from 'react-i18next'
import Tip, { ITip } from './Tip'
import cx from 'classnames'

interface TipsProps {
  cta_content?: string
  cta_button?: ReactNode
  tips: ITip[]
}

const TipTop = ({ cta_content, cta_button, style }) => (
  <div className='shadow rounded p-3 bg-gradient-secondary' style={style}>
    <p className='text-white'>{cta_content}</p>
    {cta_button}
  </div>
)

export default ({ cta_content, cta_button, tips }: TipsProps) => {
  const { t } = useTranslation()
  return tips.length ? (
    <Fragment>
      <div
        className={cx('mb-5', { 'bg-primary': tips.length })}
        style={{ marginTop: 80 }}
      >
        <Container style={{ height: 55 }}>
          <Row>
            <Offset>
              <TipTop
                cta_content={cta_content}
                cta_button={cta_button}
                style={{ transform: 'translateY(-80px)' }}
              />
            </Offset>
          </Row>
        </Container>
        <Container>
          <Row>
            <Offset>
              <h6 className='text-white mb-0 pt-4 text-uppercase'>
                <strong>{t('useful sentinel tips')}</strong>
              </h6>

              <div
                className='scroll-x row flex-nowrap pb-2 mb-5'
                style={{
                  marginTop: -60,
                  transform: 'translateY(100px)',
                }}
              >
                {tips.map(({ content }, i) => (
                  <div key={i} className='col-5 col-sm-6'>
                    <Tip content={content} />
                  </div>
                ))}
              </div>
            </Offset>
          </Row>
        </Container>
      </div>
      <div style={{ height: 52 }} />
    </Fragment>
  ) : (
    <Container>
      <Row>
        <Offset>
          <TipTop
            cta_content={cta_content}
            cta_button={cta_button}
            style={{}}
          />
        </Offset>
      </Row>
    </Container>
  )
}

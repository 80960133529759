// tslint:disable: import-name
import React from 'react'
import { useTranslation } from 'react-i18next'

const DeviceHeader = ({ name, count }) => {
  const { t } = useTranslation()
  return (
    <div>
      <h4 className='mt-2 text-uppercase'>{t(name)}</h4>
      <p className='text-muted text-capitalize'>
        {count} {t('connected devices')}
      </p>
    </div>
  )
}

export default DeviceHeader

// tslint:disable: align
export default (url: string) => {
  const img = new Image()
  img.src = url

  return new Promise((res, rej) => {
    const id = setTimeout(() => {
      img.removeAttribute('src')
      rej()
    }, 200)

    img.onload = function() {
      clearTimeout(id)
      res()
    }

    img.onerror = function(error) {
      clearTimeout(id)
      rej(error)
    }
  })
}

// tslint:disable: import-name
import React from 'react'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'
import { Footer } from '../../components/Footer'
import { Link } from 'react-router-dom'
import { ScrollPage } from '../../components/Layout'
// import Logo from '../../components/Logo'

const Nav = () => <Navbar goBack={true} />
const Bar = () => <BottomBar className='d-md-none' />

export default () => (
  <ScrollPage navigationBar={Nav} bottomBar={Bar}>
    <div className='col clear-top'>
      <div className='text-center my-5'>
        {/* <Logo /> */}
        <h4>Not Found</h4>
        <Link to='/' className='btn btn-primary'>
          Return Home
        </Link>
      </div>
    </div>
    <Footer />
  </ScrollPage>
)

import React, { useState } from 'react'
import { ApolloError } from 'apollo-client'
import { IBluetoothDevice } from '../../utils/bluetooth'
import getSentinelImage, { Colors } from '../../utils/sentinelImage'
import identity from 'lodash/identity'
import get from 'lodash/get'
import Slider, { Slide } from '../../components/Slider'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import parseError from '../../utils/parseError'

interface Props {
  device: IBluetoothDevice | null
  loading: boolean
  error: ApolloError | undefined
  onSubmit: (ev: any) => void
}

const preventDefault = (fn: (ev: any) => void) => (ev: any) => {
  ev.preventDefault()
  const publicKey = ev.target.publicKey.value.replace(/\//g, '-')
  fn({
    id: publicKey,
    name: ev.target.name.value,
    publicKey,
    photoUrl: ev.target.photoUrl.value,
  })
}

export default ({ device, loading, error, onSubmit }: Props) => {
  const [selectedPhoto, setSelectedPhoto] = useState(
    getSentinelImage(Colors.black)
  )
  const setSelected = (active: number) => {
    const key = Object.keys(Colors)[active]
    const img = getSentinelImage(Colors[key])
    setSelectedPhoto(img)
  }
  return (
    <div>
      <form onSubmit={preventDefault(onSubmit)}>
        <Slider onChange={setSelected}>
          {Object.keys(Colors).map(key => (
            <Slide key={key}>
              <img className='device-img' src={getSentinelImage(Colors[key])} />
            </Slide>
          ))}
        </Slider>
        <div className='form-group'>
          <input
            id='name'
            type='text'
            name='name'
            className='form-control'
            placeholder='Name your device'
          />
          <input
            id='photoUrl'
            type='text'
            name='photoUrl'
            className='form-control'
            placeholder='Photo Url'
            value={selectedPhoto}
            onChange={identity}
            hidden={true}
          />
          <input
            id='publicKey'
            type='text'
            name='publicKey'
            className='form-control'
            placeholder='Name your device'
            value={get(device, 'id')}
            onChange={identity}
            hidden={true}
          />
          <small className='text-muted'>
            <em>(optional)</em>
          </small>
        </div>
        {error && <p className='text-danger'>{parseError(error.message)}</p>}
        {loading ? (
          <div className='text-center'>
            <Loader />
          </div>
        ) : (
          <button
            disabled={loading}
            className='btn btn-success w-100 text-white'
            type='submit'
          >
            Save
          </button>
        )}
      </form>
    </div>
  )
}

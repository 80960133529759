// tslint:disable: import-name
import React from 'react'
import { Mutation } from 'react-apollo'
import { IBluetoothDevice } from '../../utils/bluetooth'
import AddDevice from './AddDevice'
import ADD_SENTINEL from '../../gql/mutations/addSentinel'
import { addedDevice } from '../../gql/cache'
import over from 'lodash/over'

// tslint:disable-next-line: interface-name
interface AddSentinelProps {
  device: IBluetoothDevice | null
  onSuccess: () => void
}

export default ({ device, onSuccess }: AddSentinelProps) => {
  // tslint:disable-next-line: ter-arrow-parens
  const handleSubmit = (submit) => (variables: any) => submit({ variables })
  return (
    <Mutation
      mutation={ADD_SENTINEL}
      update={over([addedDevice('addSentinel', 'mySentinels'), onSuccess])}
    >
      {(addSentinel, { loading, error }: any) => (
        <AddDevice
          device={device}
          onSubmit={handleSubmit(addSentinel)}
          loading={loading}
          error={error}
        />
      )}
    </Mutation>
  )
}

import gql from 'graphql-tag'

export default gql`
  mutation UpdateMyUserInfo(
    $displayName: String
    $photoURL: String
    $publicKey: String
    $isAnonymous: Boolean
    $defaultArchivistId: String
  ) {
    updateMyUserInfo(
      info: {
        displayName: $displayName
        photoURL: $photoURL
        publicKey: $publicKey
        isAnonymous: $isAnonymous
        defaultArchivistId: $defaultArchivistId
      }
    ) {
      id
      displayName
      photoURL
      email
      publicKey
      isAnonymous
      defaultArchivistId
    }
  }
`

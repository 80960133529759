// tslint:disable: import-name
import React, { Fragment } from 'react'
import {
  ScrollPage,
  ScrollDirection,
  ScrollState,
} from '../../components/Layout'
import { Footer } from '../../components/Footer'
import Navbar from '../../components/Navbar'
import BottomBar from '../../components/BottomBar'

export default () => {
  const nav = ({ direction }: ScrollState) => (
    <Navbar hidden={direction === ScrollDirection.down} />
  )
  const bar = ({ direction }: ScrollState) => (
    <BottomBar
      className='d-md-none'
      hidden={direction === ScrollDirection.up}
    />
  )
  return (
    <ScrollPage navigationBar={nav} bottomBar={bar}>
      <Fragment>
        <div className='container pt-3 pb-5 text-white'>
          <div className='clear-top' />
          <h1>Terms and Conditions of Use</h1>

          <p>
            <strong>Last Update: February 1, 2019</strong>
          </p>

          <p>
            These Terms and Conditions of Use (these “Terms”) govern the
            relationship between XY – The Persistent Company (“XY”, “us” or
            “we”) and you regarding your use of this mobile game application and
            related services provided by us (collectively, the “Service”). Use
            of the Service is also governed by our Privacy Policy and other
            relevant terms and policies posted on the Service, which are
            incorporated into these Terms by reference. We reserve the right in
            our discretion to change, add or remove portions of these Terms and
            policies at any time by posting the revised terms on the Service,
            and you will be deemed to have accepted such changes by continuing
            to use the Service.
          </p>

          <p>
            <strong>
              please read these terms carefully. by installing, using or
              accessing the service, you are agreeing to all of these terms. if
              you do not agree to these terms, you must uninstall and
              discontinue using the service. use of the service is void where
              prohibited.
            </strong>
          </p>

          <p>
            1. <u>Electronic Communications</u>
          </p>

          <p>
            When you use the Service, or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate directly
            with you through the Service or by email if provided by you. You
            agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing.
          </p>

          <p>
            2. <u>Limited License</u>
          </p>

          <p>
            Subject to your agreement and continued compliance with these Terms,
            XY grants you a non-exclusive, non-transferable, non-sublicensable,
            revocable, limited license to access and use the Service for your
            own individual, non-commercial purposes.{' '}
          </p>

          <p>
            3. <u>Account and Login</u>
          </p>

          <p>
            The creation of a user account (“Account”) and provision of Account
            login information, such as an email and password, may be required to
            be eligible to participate in all features and promotions associated
            with the Service. You agree not to share access to the Account or
            any Account information with anyone else, or take any actions to
            jeopardize the security of your Account. In the event you suspect or
            are aware of any breach of security or unauthorized access of your
            Account, you agree to immediately notify us and modify your Account
            password.{' '}
          </p>

          <p>
            You are solely responsible for maintaining the confidentiality of
            your Account, and for all actions that take place under your
            Account. You are solely responsible for the access and use of the
            Service by anyone using your Account, whether or not such use and
            access are actually authorized by you, including but not limited to
            all communications, transactions and obligations. You agree to
            accept responsibility for all activities that occur under your
            Account. We shall not be liable or responsible for any loss or
            damage arising from any unauthorized access, use or any other breach
            of security of your Account, including your Account password and
            email address. You acknowledge and accept that we shall have no
            obligation to investigate the authorization or source of any Account
            activity.{' '}
          </p>

          <p>
            You agree that we may, without prior notice, immediately terminate,
            restrict access to, or suspend your Account for any or no reason,
            including any of the following: (a) breach or violation of these
            Terms; (b) upon request by any governmental agency or law
            enforcement; (c) unforeseen technical or security issues; (d)
            extended periods of inactivity; or (e) fraudulent, deceptive or
            illegal activity, or any other activity which we believe is harmful
            to our business interests. You agree that any termination,
            restriction of access, or suspension of your Account shall be made
            by us in our sole discretion, and that we shall not be liable to you
            or any third party for taking such action.
          </p>

          <p>
            4. <u>Prohibited Activities</u>
          </p>

          <p>
            You agree not to engage in any of the following activities in
            connection with the Service:
          </p>

          <ul>
            <li>Violate any applicable laws, rules or regulations.</li>
            <li>Change or remove any copyright or other proprietary notice.</li>
            <li>
              Circumvent, avoid or disable any geographic or other access
              restrictions imposed by us, or any security devices, mechanisms,
              software or protocols.
            </li>
            <li>
              Any activities that we deem to be in conflict with the spirit or
              intent of the Service.
            </li>
            <li>
              Utilize any exploits, automated software, bots, hacks, mods or any
              unauthorized third-party software to modify, exploit or interfere
              with the Service.
            </li>
            <li>
              Modify any files that are a part of the Service without express
              written consent.
            </li>
            <li>
              Disrupt, interfere with or adversely affect the normal operation
              of the Service or any other act that intentionally abuses the
              Service.
            </li>
            <li>
              Initiate or assist in any type of attack on the Service, including
              distributing a virus, denial of service attacks, or other attempts
              to disrupt the Service or others’ use thereof.
            </li>
            <li>
              Attempt to gain unauthorized access to the Service, other user
              Accounts, or to the computers, servers, or networks connected to
              the Service by any means other than the provided user interface.
            </li>
            <li>
              Transmit or link to any information that is abusive, threatening,
              obscene, defamatory, libelous, racist, violent, pornographic, or
              otherwise objectionable or offensive.
            </li>
            <li>
              Harass, threaten, abuse or harm any person or group, including any
              of our employees, or make any attempts or encourage others to do
              so.
            </li>
            <li>
              Transmit any material or information that infringes any copyright,
              trademark, patent, trade secret, right of privacy, right of
              publicity, or any other right of any person or entity.
            </li>
            <li>
              Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for any underlying software or
              other intellectual property used to provide the Service.
            </li>
            <li>
              Solicit or attempt to solicit another user’s Account information
              or any other login credentials or personal information.
            </li>
            <li>
              Impersonate or attempt to impersonate any other person, including
              other users of the Service or any of our employees.
            </li>
            <li>
              Collect or post anyone's private information, including personally
              identifiable information.
            </li>
          </ul>

          <p>
            We reserve the right to determine what conduct we consider to be in
            violation of these Terms or otherwise outside the intent or spirit
            of these Terms or the Service itself. We reserve the right to take
            any action as a result, which may include terminating your Account
            and prohibiting you from using the Service in whole or in part.
          </p>

          <p>
            5. <u>Virtual Content</u>
          </p>

          <p>
            By using the Service you may become eligible to receive digital
            tokens, prizes, bonuses, currencies, or other virtual items
            (“Virtual Content”). You may acquire Virtual Content in the amounts
            and at times permitted by us in our sole discretion. The inclusion
            of any Virtual Content at a particular time does not imply or ensure
            that the same content will be available at any other times. All
            features, content, and specifications of Virtual Content described
            in the Service are subject to change at any time without notice to
            you.{' '}
          </p>

          <p>
            All offers and promotions involving the redemption or exchange of
            Virtual Content are subject to user eligibility requirements, as
            well as the terms and conditions of such offers and promotions, as
            determined by us in our sole discretion. Virtual Content may not be
            redeemed, exchanged, transferred or resold in any manner except as
            explicitly permitted by us. All Virtual Content associated with your
            Account will be automatically and permanently deleted upon
            termination of your Account, uninstalling the Service, or prolonged
            period of user inactivity, including any Virtual Content that is
            eligible for redemption at the time of such termination, deletion or
            inactivity, and we shall not be liable to you or any other party for
            any such loss of your Virtual Content.
          </p>

          <p>
            6. <u>Digital Wallet</u>
          </p>

          <p>
            If you become eligible to receive digital tokens from us, you are
            responsible for maintaining the confidentiality of the keys to the
            digital wallet (“Wallet”) in which your tokens are stored, and you
            agree to accept responsibility for all activities that occur with
            respect to your Wallet. You acknowledge and agree that we are not
            responsible nor liable for any loss or damages related to any
            third-party access of your Wallet, including any theft or
            misappropriation of your Wallet contents.
          </p>

          <p>
            7. <u>User Content</u>
          </p>

          <p>
            Any and all content, comments, feedback, suggestions, ideas,
            concepts, photos, questions or other communications (collectively,
            "User Content") that you submit or post through the Service shall be
            deemed non-confidential and non-proprietary. By submitting or
            posting any User Content, you grant to us and our affiliates a
            perpetual, irrevocable, royalty-free, worldwide, sublicensable and
            transferable license to copy, publish, translate, modify, create
            derivative works from, distribute, reproduce, display, or use the
            User Content in any commercial or non-commercial manner whatsoever.
            We will be free to use any ideas, concepts, know-how or techniques
            contained in such User Content for any purpose whatsoever, including
            but not limited to developing, manufacturing and marketing products
            that incorporate or otherwise rely upon such information. We shall
            have no obligation to monitor User Content, use or display User
            Content, compensate you for submitting User Content, or respond to
            any User Content. We retain the right, in our sole discretion and
            without prior notice, to remove, revise or refuse to post any User
            Content for any or no reason.
          </p>

          <p>
            8. <u>Ownership</u>
          </p>

          <p>
            The Service, and all of its components and contents (including
            without limitation any computer code, template content,
            pre-populated content, concepts, artwork, animations, sounds,
            musical compositions, audio-visual effects and text contained
            within), and all intellectual property rights therein are owned by
            us or our licensors, all of which is protected by United States and
            international patent, copyright, trademark, trade secret, and other
            intellectual property laws.
          </p>

          <p>
            9. <u>Updates to the Service</u>
          </p>

          <p>
            We may, from time to time, issue patches or updates to the Service
            which must be installed to continue using the Service. You consent
            to receive and install those patches and updates with or without
            notice to you. You may need to update third party software from time
            to time in order to receive the Service. You understand and accept
            that all or portions of the Service may experience server downtime
            or other technical issues making the Service temporarily
            unavailable.
          </p>

          <p>
            10. <u>Termination of the Service</u>
          </p>

          <p>
            We reserve the right to terminate the Service or any part thereof at
            any time in our sole discretion. In such event, we shall not be
            required to provide refunds, benefits or other compensation to users
            in connection with such terminated Service. You accept and agree
            that you will have no recourse of any kind against us even if such
            termination causes you to lose Virtual Content or have no further
            access to all or part of the Service.
          </p>

          <p>
            <strong>11. DISCLAIMERS AND LIMITATIONS OF LIABILITY</strong>
          </p>

          <p>
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
            PROVIDED ON AN "AS-IS", "AS AVAILABLE", AND "WITH ALL FAULTS" BASIS,
            AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR
            STATUTORY. YOU AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGES OR LOSSES THAT ARISE FROM YOUR USE OF THE SERVICE.
          </p>

          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL
            WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS, IMPLIED OR
            STATUTORY, IN CONNECTION WITH THE SERVICE, INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT, OR THOSE ARISING FROM COURSE OF DEALING OR USAGE
            OF TRADE.{' '}
          </p>

          <p>
            IN PARTICULAR, WE MAKE NO WARRANTIES OR REPRESENTATIONS THAT (A) THE
            SERVICE WILL OPERATE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
            OR ANY DEFECTS IN THE SERVICE WILL BE CORRECTED, (B) THE SERVICE IS
            FREE FROM VIRUSES, BUGS OR OTHER HARMFUL COMPONENTS, (C) YOU WILL BE
            ABLE TO ACCESS OR USE THE SERVICE AT THE TIME OR LOCATION OF YOUR
            CHOOSING, OR (D) ANY INFORMATION, CONTENT OR MATERIALS MADE
            AVAILABLE THROUGH THE SERVICE WILL BE VALUABLE, ACCURATE, USEFUL,
            TIMELY OR RELIABLE.{' '}
          </p>

          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL XY OR ANY
            SUBSIDIARIES OR AFFILIATES OF XY, OR ANY OFFICERS, DIRECTORS,
            MANAGERS, SHAREHOLDERS, OWNERS, PARTNERS, EMPLOYEES, CONTRACTORS OR
            AGENTS OF ANY OF THEM, BE LIABLE FOR ANY LOSSES OR DAMAGES OF ANY
            KIND IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SERVICE,
            INCLUDING ANY (A) DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR
            CONSEQUENTIAL DAMAGES, INCLUDING ANY LOSS OF REVENUES, LOST PROFITS,
            LOST DATA OR OTHER INTANGIBLE LOSSES, (B) PROPERTY DAMAGES, DATA
            LOSS, PERSONAL INJURY, OR TECHNICAL MALFUNCTIONS OR INTERRUPTIONS,
            OR (C) DAMAGES OR LOSSES RELATED TO THE UNAUTHORIZED ACCESS OR USE
            OF THE SERVICE OR RELATED EQUIPMENT, OR ANY COMPUTER BUGS, VIRUSES,
            HARMFUL PROGRAMS OR SIMILAR MECHANISMS TRANSMITTED THROUGH OR IN
            CONNECTION WITH THE SERVICE. IF YOU ARE DISSATISFIED WITH ANY ASPECT
            OF THE SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE
            OF THE SERVICE.{' '}
          </p>

          <p>
            SOME JURISDICTIONS MAY NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
            OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
            APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR
            LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
            RIGHTS.
          </p>

          <p>
            12. <u>Indemnification</u>
          </p>

          <p>
            To the extent permitted by applicable law, you agree to indemnify
            and hold XY and its subsidiaries and affiliates, and each of their
            officers, directors, managers, shareholders, owners, partners,
            employees, contractors and agents, harmless from any damages,
            liabilities, losses, costs and expenses, including reasonable
            attorneys’ fees, resulting from any claim or demand made by a third
            party against such indemnified party that is based upon facts or
            assertions which, if proven, would constitute the breach or
            violation of these Terms by you.{' '}
          </p>

          <p>
            13. <u>BINDING ARBITRATION AND CLASS ACTION WAIVER</u>
          </p>

          <p>
            PLEASE READ THIS SECTION CAREFULLY. IF YOU DO NOT REJECT IT, THIS
            SECTION WILL APPLY, AND MOST DISPUTES BETWEEN US WILL BE SUBJECT TO
            INDIVIDUAL ARBITRATION. THIS MEANS THAT: (1) NEITHER A COURT NOR A
            JURY WILL RESOLVE ANY SUCH DISPUTE; (2) YOU WILL NOT BE ABLE TO
            PARTICIPATE IN A CLASS ACTION OR SIMILAR PROCEEDING; (3) LESS
            INFORMATION WILL BE AVAILABLE IN DISCOVERY; AND (4) APPEAL RIGHTS
            WILL BE LIMITED.
          </p>

          <p>
            If a dispute arises between you and us, our goal is to provide you
            with a neutral and cost-effective means of resolving the dispute
            quickly. Accordingly, you and XY agree to resolve any claim or
            controversy at law or equity that arises out of these Terms or the
            Service (a “Claim”) in accordance with the provisions below.
          </p>
          <p />
          <p>
            If either you or we make a demand for arbitration, you and we must
            arbitrate any dispute or claim between you or any person who uses
            your Account, and us, our affiliates or agents, if it relates to
            your Account, your use of the Service, or to these Terms, except as
            noted hereafter.{' '}
          </p>

          <p>
            Either party may assert an individual case in small claims court or
            your state’s equivalent court. Any disputes relating to the
            enforcement, protection, or validity of the intellectual property
            rights of either party shall not be subject to arbitration. Nothing
            in this section shall limit either party from seeking injunctive or
            other exigent relief from a court of law.
          </p>

          <p>
            Notwithstanding any other language in this section, only a court,
            not an arbitrator, will decide disputes about the validity,
            enforceability, coverage or scope of this section of these Terms.
            However, any dispute or argument that concerns the validity or
            enforceability of these Terms as a whole is for the arbitrator, not
            a court, to decide. Further, you and we may litigate in court to
            compel arbitration, stay proceedings pending arbitration, or
            confirm, modify, vacate or enter judgment on the award entered by
            the arbitrator.
          </p>
          <p />
          <p>
            YOU AGREE NOT TO PARTICIPATE IN A CLASS, REPRESENTATIVE, OR PRIVATE
            ATTORNEY GENERAL ACTION AGAINST US IN COURT OR ARBITRATION. ALSO,
            YOU MAY NOT BRING CLAIMS AGAINST US ON BEHALF OF ANY OTHER USER OR
            PERSON NOT ON YOUR ACCOUNT.
          </p>

          <p>
            The arbitration administrator shall be JAMS and the arbitration
            shall be conducted under the Streamlined Arbitration Rules and
            Procedures of JAMS (“JAMS Rules”) that are in effect at the time the
            arbitration is initiated. The JAMS Rules can be accessed at
            http://www.jamsadr.com/rules-streamlined-arbitration/. In the event
            of a conflict between the terms set forth in this section and the
            JAMS Rules, the terms in this section of the Terms will control.{' '}
          </p>

          <p>
            The arbitrator must apply the same law and legal principles,
            consistent with the Federal Arbitration Act (“FAA”), that would
            apply in court, but may use different procedural rules. We will pay
            all the fees that the administrator or arbitrator charges. Because
            the Service concerns interstate commerce, the FAA governs the
            arbitrability of all disputes. California law shall apply to the
            extent state law is relevant under the FAA. The arbitrator’s
            decision will be final and binding on all parties and enforceable in
            any court that has jurisdiction, provided that any award may be
            challenged under the provisions of the FAA.
          </p>

          <p>
            Except for claims subject to arbitration as provided for in this
            section (and claims proceeding in any small claims court), all other
            disputes arising out of or related to your Account, your use of the
            Service, or to these Terms shall be subject to the exclusive
            jurisdiction of the state and federal courts located in San Diego,
            California, and you agree to submit to the personal jurisdiction and
            venue of such courts.
          </p>

          <p>
            Any claim or cause of action you may have with respect to the
            Service or these Terms must be commenced within one (1) year after
            such claim or cause of action arose.{' '}
          </p>

          <p>
            14. <u>Severability</u>
          </p>

          <p>
            If any provision of these Terms is determined to be invalid or
            unenforceable, in whole or in part, such provision shall be enforced
            to the maximum extent permissible so as to affect the intent of
            these Terms, and the remainder of these Terms will continue in full
            force and effect.{' '}
          </p>

          <p>
            15. <u>Entire Agreement</u>
          </p>

          <p>
            These Terms (including our Privacy Policy and any additional terms
            posted on the Service), constitute the entire agreement between us
            with respect to your use of the Service, and supersede any prior
            agreements or understandings between us.{' '}
          </p>

          <p>
            16. <u>Assignment and Waiver</u>
          </p>

          <p>
            We may assign or delegate these Terms, in whole or in part, to any
            person or entity at any time without your consent. You may not
            assign or delegate any rights or obligations under these Terms
            without our prior written consent. These Terms inure to the benefit
            of our successors, assigns and licensees. Our failure to exercise or
            enforce any right or provision of these Terms shall not constitute a
            waiver or relinquishment of our right to assert or rely upon any
            such right or provision in that or any other instance.{' '}
          </p>

          <p>
            17. <u>Headings and Interpretation</u>
          </p>

          <p>
            Section headings used in these Terms are for reference purposes only
            and in no way define, limit or construe the scope or extent of such
            section or in any way affect the meaning or interpretation of any
            provision in these Terms. The words “include”, “includes” and
            “including” shall be deemed to be followed by the phrase “without
            limitation.” Defined terms in this Agreement may be used in both
            singular and plural forms.
          </p>
        </div>
        <Footer />
      </Fragment>
    </ScrollPage>
  )
}

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { useXyAccountAccessToken } from '@xyo-network/tool-storybook-react/dist/lib/auth/context'
import createHttpLink from './httpLink'

import introspectionQueryResultData from './../fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const httpLink = createHttpLink(process.env.REACT_APP_APOLLO_SERVER || '')
const httpLinkDev = createHttpLink(
  process.env.REACT_APP_APOLLO_SERVER_DEV || ''
)

const authLink = setContext((o: any, { headers }) => {
  try {
    return {
      headers: {
        'X-Auth-Token': useXyAccountAccessToken(),
        ...headers,
      },
    }
  } catch (e) {
    return { headers }
  }
})

export default new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link: authLink.concat(httpLink),
})

export const devClient = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link: authLink.concat(httpLinkDev),
})

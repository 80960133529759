import React from 'react'
import { Mutation } from 'react-apollo'
import MY_USER_INFO from '../../gql/queries/myUserInfo'
import UPDATE_MY_USER_INFO from '../../gql/mutations/updateMyUserInfo'
import keys from 'lodash/keys'
import get from 'lodash/get'
import set from 'lodash/set'
import isNull from 'lodash/isNull'

const updateProfileCache = (cache, updated) => {
  const data = cache.readQuery({ query: MY_USER_INFO })
  keys(get(updated, 'data.updateMyUserInfo'))
    .filter(
      (key: string) => !isNull(get(updated, `data.updateMyUserInfo.${key}`))
    )
    .forEach((key: string) => {
      set(
        data,
        `myUserInfo.${key}`,
        get(updated, `data.updateMyUserInfo.${key}`)
      )
    })
  cache.writeQuery({ query: MY_USER_INFO, data })
}

export default ({ children }) => {
  return (
    <Mutation mutation={UPDATE_MY_USER_INFO} update={updateProfileCache}>
      {children}
    </Mutation>
  )
}

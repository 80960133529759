export enum Colors {
  black = 'black',
  blue = 'blue',
  green = 'green',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  white = 'white',
  yellow = 'yellow',
}

export default (color: Colors) => {
  return `/images/sentinels/${color}.png`
}

import React from 'react'
// import LineChart from '../LineChart'
import { Query } from 'react-apollo'
import { TOTAL_BLOCKS, MY_TOTAL_BLOCKS } from '../../gql/queries/earningsCard'
import { withMyPublicKeys } from '../../gql/queries/myPublicKeys'
// import { ITimeAggregate } from '../../gql/fragments/timeAggregate'
// import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
// import useBtnSelect from '../ButtonSelect'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
// import group from 'lodash/groupBy'
import {
  SlideInUp,
  FadeIn,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

export enum CurrencySymbol {
  usd = '$',
  euro = '€',
  pound = '£',
  yen = '¥',
}

export const formatFiatCurrency = (value: number): string =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

interface Props {
  currencySymbol: CurrencySymbol
  style?: any
  publicKeys?: string[]
  loading: boolean
}

// type Time = 'year' | 'month' | 'day' | 'hour'
// const HOUR = 60 * 60 * 1000
// const DAY = HOUR * 24
// const MONTH = DAY * 30
// const YEAR = DAY * 365
// const getTimeValue = (range: Time) => {
//   switch (range) {
//     case 'hour':
//       return HOUR
//     case 'day':
//       return DAY
//     case 'month':
//       return MONTH
//     case 'year':
//       return YEAR
//   }
// }
// const trimTime = (groupBy: Time, value?: Date | number) => {
//   const date =
//     value instanceof Date ? value : value ? new Date(value) : new Date()
//   switch (groupBy) {
//     case 'hour':
//       date.setMinutes(0, 0, 0)
//       break
//     case 'day':
//       date.setHours(0, 0, 0, 0)
//       break
//   }
//   return date
// }
// const timeAgo = (range: Time) => {
//   return trimTime('day').valueOf() - getTimeValue(range)
// }
// const createDateRange = (range: Time, groupBy: Time) => {
//   let start = timeAgo(range)
//   const date = trimTime('day').valueOf()
//   const arr: number[] = []
//   while (start <= date) {
//     arr.push(trimTime('day', start).valueOf())
//     start += getTimeValue(groupBy)
//   }
//   return arr
// }

// const daysAgoOptions = [
//   { text: '1d', value: ['day' as Time, 'hour' as Time] },
//   // { text: '1w', value: fromDate(7) },
//   { text: '1m', value: ['month' as Time, 'day' as Time] },
//   { text: '1y', value: ['year' as Time, 'day' as Time] },
// ]

// const toDate = ({ year, month, day, hour }: ITimeAggregate) => {
//   return new Date(year, month - 1, day, hour)
// }

// const fillEmptyRange = (data: ITimeAggregate[], range: Time, groupBy: Time) => {
//   const dateRange = createDateRange(range, groupBy)
//   const timeHash = group(data, d => trimTime(groupBy, toDate(d)).valueOf())
//   return dateRange.map((d: number) => {
//     const value = get(timeHash, `${d}[0].count`)
//     return [d, value || 0]
//   })
// }

export default withMyPublicKeys(
  ({ style, publicKeys, loading: initializing }: Props) => {
    const { t } = useTranslation()
    // const DaysAgo = useBtnSelect({
    //   initial: daysAgoOptions[2].value,
    //   options: daysAgoOptions,
    // })
    const skip = !publicKeys || !publicKeys.length
    return (
      <Query query={TOTAL_BLOCKS}>
        {({ data: totalBlocks, loading: loadingTotal }) => (
          <Query
            errorPolicy='ignore'
            query={MY_TOTAL_BLOCKS}
            skip={skip}
            variables={{ publicKeys }}
          >
            {({ data: myTotalBlocks, loading }) => {
              // const witnessesOverTime = get(data, 'witnessesOverTime') || []
              // const history = witnessesOverTime.length
              //   ? fillEmptyRange(
              //       witnessesOverTime,
              //       DaysAgo.value[0],
              //       DaysAgo.value[1]
              //     )
              //   : []
              return (
                <div style={style}>
                  <div className='d-md-none'>
                    <SlideInUp>
                      <p className='text-white text-left mb-0'>
                        <span className='text-uppercase'>
                          {t('cryptographic bound witnesses')}
                        </span>
                        <small className='text-muted text-capitalize-first text-left d-block'>
                          ({t('across all my devices')})
                        </small>
                      </p>
                    </SlideInUp>
                    <div className='h4 text-success text-left mb-0'>
                      {initializing || loading ? (
                        <span>&nbsp;</span>
                      ) : (
                        <FadeIn>
                          {(
                            get(myTotalBlocks, 'blocks.pagination.count') || 0
                          ).toLocaleString()}{' '}
                          &nbsp;
                        </FadeIn>
                      )}
                    </div>
                    <div className='text-muted text-left mb-0 text-capitalize-first'>
                      <small>
                        {initializing || loadingTotal ? (
                          <span>&nbsp;</span>
                        ) : (
                          <SlideInUp>
                            {t('total in network')}
                            {': '}
                            {(
                              get(totalBlocks, 'countWitnesses.count') || 0
                            ).toLocaleString()}{' '}
                            &nbsp;
                          </SlideInUp>
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4 d-none d-md-block'>
                      <h4 className='text-white text-left mb-0 text-uppercase'>
                        {t('cryptographic  bound witnesses')}
                      </h4>
                      <small className='text-muted text-capitalize-first text-left d-block'>
                        ({t('across all my devices')})
                      </small>
                      <div className='h4 text-success text-left mb-0 display-3'>
                        {initializing || loading ? (
                          <span>&nbsp;</span>
                        ) : (
                          <FadeIn>
                            {(
                              get(myTotalBlocks, 'blocks.pagination.count') || 0
                            ).toLocaleString()}{' '}
                            &nbsp;
                          </FadeIn>
                        )}
                      </div>
                      <div className='text-muted text-small text-left mb-2 text-capitalize-first'>
                        {initializing || loadingTotal ? (
                          <span>&nbsp;</span>
                        ) : (
                          <SlideInUp>
                            {t('total in network')}
                            {': '}
                            {(
                              get(totalBlocks, 'countWitnesses.count') || 0
                            ).toLocaleString()}{' '}
                            &nbsp;
                          </SlideInUp>
                        )}
                      </div>
                      {/* <div className='text-left'>
                    <DaysAgo.Component />
                  </div> */}
                    </div>
                    <div className='col-md-8'>
                      {/* {!loading && !initializing && !history.length && (
                    <SlideInUp>
                      <p className='text-white text-capitalize-first mt-5 position-absolute w-100'>
                        {t('nothing found for selected range')}
                      </p>
                    </SlideInUp>
                  )} */}
                      {/* <LineChart
                    id='device-earnings'
                    data={[{ key: 'XYO', values: history }]}
                  /> */}
                    </div>
                  </div>
                  {/* <div className='d-md-none mt-3'>
                <DaysAgo.Component />
              </div> */}
                </div>
              )
            }}
          </Query>
        )}
      </Query>
    )
  }
)

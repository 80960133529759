// tslint:disable-next-line: import-name
import React, { Fragment, useState, FormEvent } from 'react'
import Modal, {
  ModalContent,
} from '@xyo-network/tool-storybook-react/dist/lib/Modal'
import { useTranslation } from 'react-i18next'
import { Mutation } from 'react-apollo'
import Alert from '@xyo-network/tool-storybook-react/dist/lib/Alert'
import { BootstrapType } from '@xyo-network/tool-storybook-react/dist/lib/bootstrap'
// tslint:disable-next-line: import-name
import ADD_FEEDBACK from '../../gql/mutations/addFeedback'
// tslint:disable-next-line: import-name
import UPDATE_FEEDBACK from '../../gql/mutations/updateFeedback'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import get from 'lodash/get'
import './index.css'

enum Moods {
  good = 4,
  semi_good = 3,
  semi_bad = 2,
  bad = 1,
  none = 0,
}

enum MoodEmojis {
  good = '/images/smiling-face-with-smiling-eyes.png',
  semi_good = '/images/grinning-face.png',
  semi_bad = '/images/weary-face.png',
  bad = '/images/loudly-crying-face.png',
  none = '',
}

export default () => {
  const { t } = useTranslation()
  const [feedbackId, setFeedbackId] = useState(null)
  const [selectedMood, setSelectedMood] = useState('none')
  const onAddFeedback = (
    fn: (arg0: { variables: { value: any } }) => any,
    value: Moods
  ) => () => fn({ variables: { value } })
  const onFeedbackAdded = (cache: any, { data, error }: any) => {
    setFeedbackId(get(data, 'addFeedback.id'))
    const fn = select[get(data, 'addFeedback.value')]
    if (fn) fn()
  }
  const onSubmitFeedbackText = (
    fn: (arg0: { variables: { id: null; text: any } }) => void
  ) => (ev: FormEvent) => {
    ev.preventDefault()
    const id = feedbackId
    const text = get(ev, 'target.text.value')
    fn({ variables: { id, text } })
  }
  const onFeedbackUpdated = (cache: any, { data, error }: any) => {}
  const select = {}
  for (const member in Moods) {
    select[Moods[member]] = () => setSelectedMood(member)
  }
  return (
    <Mutation mutation={ADD_FEEDBACK} update={onFeedbackAdded}>
      {(addFeedback: any, { data, loading }: any) => (
        <Fragment>
          <p className='text-center mt-5'>{t('review_question')}</p>

          <div className='text-center mb-5'>
            <button
              className='btn bg-transparent ml-1'
              onClick={onAddFeedback(addFeedback, Moods.bad)}
            >
              <img className='emoji-icon' src={MoodEmojis.bad} />
            </button>
            <button
              className='btn bg-transparent ml-1'
              onClick={onAddFeedback(addFeedback, Moods.semi_bad)}
            >
              <img className='emoji-icon' src={MoodEmojis.semi_bad} />
            </button>
            <button
              className='btn bg-transparent ml-1'
              onClick={onAddFeedback(addFeedback, Moods.semi_good)}
            >
              <img className='emoji-icon' src={MoodEmojis.semi_good} />
            </button>
            <button
              className='btn bg-transparent'
              onClick={onAddFeedback(addFeedback, Moods.good)}
            >
              <img className='emoji-icon' src={MoodEmojis.good} />
            </button>
          </div>

          <Modal
            open={loading || !!Moods[selectedMood]}
            onClose={select[Moods.none]}
          >
            <ModalContent
              className='overflow-hidden'
              headerClassName='bg-info text-white rounded-0'
              title={
                Moods[selectedMood] === Moods.bad ||
                Moods[selectedMood] === Moods.semi_bad
                  ? t('review_concern')
                  : t('review_thank_you')
              }
            >
              {loading ? (
                <div className='text-center'>
                  <Loader />
                </div>
              ) : (
                <Mutation mutation={UPDATE_FEEDBACK} update={onFeedbackUpdated}>
                  {(updateFeedback: any, { data }: any) => (
                    <div>
                      {/* <img src={MoodEmojis[selectedMood]} /> */}
                      <Alert type={BootstrapType.primary}>
                        {get(data, 'updateFeedback.id')
                          ? t('review_updated')
                          : ''}
                      </Alert>
                      <form onSubmit={onSubmitFeedbackText(updateFeedback)}>
                        <div className='form-group'>
                          <label
                            htmlFor='review-text'
                            className='text-capitalize'
                          >
                            {t('review')}
                          </label>
                          <textarea
                            id='review-text'
                            name='text'
                            className='form-control border-top shadow-sm p-3'
                            placeholder='please share your thoughts here'
                          />
                        </div>
                        <button
                          type='submit'
                          className='btn btn-primary text-capitalize shadow'
                        >
                          {t('submit')}
                        </button>
                      </form>
                    </div>
                  )}
                </Mutation>
              )}
            </ModalContent>
          </Modal>
        </Fragment>
      )}
    </Mutation>
  )
}

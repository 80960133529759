import React from 'react'
import cx from 'classnames'

export interface ITip {
  content: string
}

export interface TipProps extends ITip {
  className?: string
}

export default ({ content, className }: TipProps) => (
  <div
    className={cx(
      'position-relative bg-white shadow-sm rounded h-100',
      className
    )}
  >
    <p className='p-3 mb-5'>{content}</p>
    <div style={{ height: 20 }} />
    <div className='position-absolute w-100 bottom-0 text-center font-lg text-muted display-4 mb-0'>
      <i className='fa fa-user' />
    </div>
  </div>
)

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import MyBoundWitnesses from '../../components/BoundWitness/MyBoundWitnesses'
import NoneFound from '../../components/BoundWitness/NoneFound'
import { BoundWitnessAccordion } from '@xyo-network/tool-storybook-react/dist/lib/BoundWitness'
import Loader from '@xyo-network/tool-storybook-react/dist/lib/Loader'
import last from 'lodash/last'
import size from 'lodash/size'
import get from 'lodash/get'

const lastBoundWitnessDate = (createdAt: string, locale = 'en-us') => {
  const d = new Date(createdAt)
  if (isNaN(d.getTime())) return ''
  const day = d.toLocaleString(locale, { weekday: 'short' })
  const month = d.toLocaleString(locale, { month: 'long' })
  const date = d.getDate()
  const hour = d.getHours()
  const meridiem = hour >= 12 ? 'PM' : 'AM'
  return `${day} ${month} ${date} @${hour % 12} ${meridiem}`
}

const lastRoundedZero = (bw, i, arr) =>
  arr.length - 1 === i ? 'rounded-0' : ''

export default () => {
  const { t } = useTranslation()
  const rowHeight = 62
  const perPage = 5
  return (
    <MyBoundWitnesses perPage={perPage}>
      {({ loading, data }) => {
        const blocks = get(data, 'blocks.items') || []
        const total = get(data, 'blocks.pagination.count') || 0
        return (
          <Fragment>
            <h6 className='text-uppercase'>
              <strong>{t('bound witness')}</strong>
            </h6>
            <p>
              <small className='d-flex text-muted'>
                <em className='text-capitalize'>
                  {size(blocks) ? t('last bound witness') : ''}
                </em>
                <span className='col' />
                <em>{lastBoundWitnessDate(get(last(blocks), 'createdAt'))}</em>
              </small>
            </p>
            <div className='pb-5'>
              <div className='position-relative bg-white shadow-sm rounded overflow-hidden'>
                {loading ? (
                  <div className='card' style={{ height: rowHeight * perPage }}>
                    <div className='text-center p-5'>
                      <Loader />
                    </div>
                  </div>
                ) : blocks.length ? (
                  <BoundWitnessAccordion
                    boundWitnesses={blocks}
                    cardClassName={lastRoundedZero}
                  />
                ) : (
                  <NoneFound className='card' />
                )}

                <div className='d-flex bg-secondary p-3'>
                  <p className='mb-0 text-white text-uppercase'>
                    {t('bound witnesses')}:
                  </p>
                  <div className='col' />
                  <p className='mb-0 text-white'>{loading ? '' : total}</p>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }}
    </MyBoundWitnesses>
  )
}

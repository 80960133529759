// tslint:disable: import-name
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Page, Main } from '../../components/Layout'
import Logo from '../../components/Logo'
// import {
//   Facebook,
// Google,
// } from '@xyo-network/tool-storybook-react/dist/lib/auth'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'
import { useTranslation } from 'react-i18next'

const Welcome = ({ history }) => {
  const { t } = useTranslation()
  // const redirectToMain = () => history.push('/')
  return (
    <Page>
      <Main>
        <div className='flex-grow-3' />
        <SlideInDown>
          <div className='text-center mb-2'>
            <Logo />
          </div>
          <h4 className='text-white text-center'>WELCOME TO XYO</h4>
        </SlideInDown>
        <div className='flex-grow-1' />
        <SlideInUp>
          <div className='p-2'>
            <Link
              className='d-block btn btn-lg btn-info w-100 mb-4 text-white text-uppercase'
              to='/signup'
            >
              {t('sign up')}
            </Link>
            <Link
              className='d-block btn btn-lg w-100 mb-4 text-white text-uppercase'
              to='/login'
            >
              {t('log in')}
            </Link>
            {/* <Link
                className='d-block btn btn-lg btn-info w-100 mb-4'
                to='/signin'
              >
                EMAIL
              </Link>
              <div className='mb-4 d-none'>
                <Google onSuccess={this.redirectToMain}>GOOGLE</Google>
              </div> */}
            {/* <Facebook onSuccess={this.redirectToMain}>FACEBOOK</Facebook> */}
          </div>
        </SlideInUp>
        <div className='flex-grow-3 mb-4' />
      </Main>
    </Page>
  )
}

export default withRouter(Welcome)

// tslint:disable: import-name /
// tslint:disable: interface-name /
import { useXyAccountContext } from '@xyo-network/tool-storybook-react/dist/lib/auth'
import { ErrorMessage, Field, Form, Formik, FormikActions } from 'formik'
import map from 'lodash/map'
import React, { FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import * as Yup from 'yup'
import Loader from '../../../components/Loader'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

interface StepProps {
  step: number
}

interface FormValues {
  email: string
  firstName: string
  lastName: string
  idMethod: string | 'upload' | 'webcam'
  idImage?: string
  issuanceAgreement?: string | 'agree' | 'disagree'
}

interface IField {
  name: string
  id?: string
  placeholder?: string
  type?: string
  autoFocus?: boolean
  render?: (a: { field: any; form: any }) => JSX.Element
}

interface IStep {
  id: string
  title: string
  hint: string
  fields: IField[]
}

const DefaultFieldComp = ({ field, form, ...props }) => {
  return (
    <div className='input-group my-2'>
      <div className='w-100'>
        <input {...field} {...props} className='form-control bg-transparent ' />
        <ErrorMessage name={field.name}>
          {(error) => {
            return <div className='text-danger mt-2'>{error}</div>
          }}
        </ErrorMessage>
      </div>
    </div>
  )
}

const FileUploadSwitcher = ({ field: { value, onChange, ...field }, form }) => {
  return (
    <div className='input-group my-2'>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          id='useWebcam'
          checked={value === 'webcam'}
          value='webcam'
          onChange={onChange}
          {...field}
        />
        <label className='form-check-label' htmlFor='useWebcam'>
          Use webcam
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          id='useUpload'
          checked={value === 'upload'}
          value='upload'
          onChange={onChange}
          {...field}
        />
        <label className='form-check-label' htmlFor='useUpload'>
          Upload photo
        </label>
      </div>
    </div>
  )
}

const FileUploadComp = ({ field, form }) => {
  const setValue = (file) => {
    form.setFieldValue(field.name, file)
  }
  const onDrop = useCallback(([file]) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setValue(reader.result)
      },
      false
    )
    if (file) {
      const { size } = file
      if (size < 3000000) {
        reader.readAsDataURL(file)
      } else {
        form.setError(field.name, 'File too large')
      }
    }
  },                         [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.png, .jpg, .jpeg',
  })

  const onTakePhoto = (dataUri) => {
    setValue(dataUri)
  }

  const { idMethod } = form.values
  const { idImage: error } = form.errors
  return (
    <div className='input-group my-2'>
      {idMethod === 'upload' ? (
        <section className='kyc-container'>
          <div {...getRootProps()} className='dropzone'>
            <input {...getInputProps()} />
            <p>
              Drag and drop an image from your computer, or click to select an
              image from your device. Max filesize 6MB
            </p>
          </div>
        </section>
      ) : idMethod === 'webcam' ? (
        <div>
          <Camera
            onTakePhoto={onTakePhoto}
            isImageMirror={false}
            idealResolution={{ width: 640, height: 480 }}
            imageCompression={1}
            imageType={IMAGE_TYPES.JPG}
          />
        </div>
      ) : null}
      {field.value ? <img src={field.value} className='w-100' /> : null}
      {error ? <div className='text-danger mt-2'>{error}</div> : null}
    </div>
  )
}

const IssuanceAgreementComp = ({
  field: { value, onChange, ...field },
  form,
}) => {
  const { [field.name]: error } = form.errors
  return (
    <div className='input-group my-2'>
      <div>
        <p>
          Please read and agree to the{' '}
          <a
            target='_blank'
            href='https://cdn.xy.company/documents/Token_Purchase_Agreement_March_2019.pdf'
          >
            XYO Token Issuance Agreement
          </a>
        </p>
      </div>
      <div className='form-check my-1'>
        <input
          className='form-check-input'
          type='radio'
          id='agree'
          checked={value === 'agree'}
          value='agree'
          onChange={onChange}
          {...field}
        />
        <label className='form-check-label' htmlFor='agree'>
          I have read and agree to the terms of the XYO Token Issuance Agreement
        </label>
      </div>
      <div className='form-check my-1'>
        <input
          className='form-check-input'
          type='radio'
          id='disagree'
          checked={value === 'disagree'}
          value='disagree'
          onChange={onChange}
          {...field}
        />
        <label className='form-check-label' htmlFor='disagree'>
          I do not agree to the terms of the XYO Token Issuance Agreement and
          forego my eligibility to receive XYO Tokens
        </label>
      </div>
      {error ? <div className='text-danger mt-2'>{error}</div> : null}
    </div>
  )
}

const steps: IStep[] = [
  // {
  //   id: 'email',
  //   title: 'Please enter your email address below:',
  //   hint:
  //     'Make sure to use the same email address you used to make your purchase. Please note that this email address may differ from the one used to login to your XYO Account.',
  //   fields: [
  //     {
  //       name: 'email',
  //       id: 'email',
  //       type: 'email',
  //       autoFocus: true,
  //       placeholder: 'Email Address',
  //     },
  //   ],
  // },
  // {
  //   id: 'name',
  //   title: 'What is your first and last name?',
  //   hint: 'It must match your ID.',
  //   fields: [
  //     {
  //       name: 'firstName',
  //       id: 'firstName',
  //       placeholder: 'First Name',
  //     },
  //     {
  //       name: 'lastName',
  //       id: 'lastName',
  //       placeholder: 'Last Name',
  //     },
  //   ],
  // },
  {
    id: 'photo',
    title: 'Verify your identification',
    hint:
      'Upload or take a webcam photo of a valid government-issued ID (e.g. driver license, identification card, or passport).',
    fields: [
      {
        name: 'idMethod',
        render: FileUploadSwitcher,
      },
      { name: 'idImage', render: FileUploadComp },
      { name: 'issuanceAgreement', render: IssuanceAgreementComp },
    ],
  },
]

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .matches(/[^@]+@[^\.]+\..+/, 'Invalid email'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  idMethod: Yup.string().oneOf(['upload', 'webcam']),
  idImage: Yup.string().required('Please select an image.'),
  issuanceAgreement: Yup.string().oneOf(
    ['agree'],
    'You must agree to the issuance document to claim your XYO.'
  ),
})

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  idMethod: 'webcam',
}

const Step: FC<StepProps> = ({ step }) => {
  const { title, hint, fields } = steps[step]
  return (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>{title}</h4>
      </SlideInDown>
      <SlideInUp timer={200}>
        <p>{hint}</p>
      </SlideInUp>
      <SlideInUp timer={400}>
        <div className='row'>
          <div className='offset-xs-2 col-xs-8 col-sm-12'>
            {fields.map(({ name, id, placeholder, ...f }) => {
              const { render: Render = DefaultFieldComp } = f

              return (
                <Field name={name} key={name}>
                  {({ field, form }) => (
                    <Render field={field} form={form} {...f} />
                  )}
                </Field>
              )
            })}
          </div>
        </div>
      </SlideInUp>
    </>
  )
}

const KYCForm = ({ setIsFinished, history }) => {
  const xya = useXyAccountContext()
  const user = xya.currentUser()!
  const email = user.email!
  const [step, setStep] = useState(0)
  const [error, setError] = useState()
  const nextStep = () => setStep(step + 1)
  const validate = (values) => {
    const { fields } = steps[step]
    const keysToValidate = map(fields, 'name')

    const errs = keysToValidate.reduce((acc, key) => {
      try {
        validationSchema.validateSyncAt(key, values)
        return acc
      } catch (err) {
        return { ...acc, [key]: err.message }
      }
    },                                 {})
    return errs
  }

  const onSubmit = async(
    values: FormValues,
    bag: FormikActions<FormValues>
  ) => {
    const isLastStep = step === steps.length - 1
    if (isLastStep) {
      // handle submit to API
      const { email, idImage } = values
      const [, imageBase64] = idImage!.split('base64,')
      const body = JSON.stringify({ email, imageBase64 })
      try {
        const result = await fetch(
          `${process.env.REACT_APP_CHECKOUT_API}/customers/kyc`,
          {
            body,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        if (!result.ok) throw new Error('An unknown error has occurred')
        const res = await result.json()
        history.push('/kyc/in-progress', { data: res })
      } catch (err) {
        console.error(err)
        setError(err)
      }
      bag.setSubmitting(false)
    } else {
      bag.setTouched({})
      bag.setSubmitting(false)
      nextStep()
    }
  }
  const isLastStep = step === steps.length - 1
  return (
    <Formik<FormValues>
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, email }}
      validate={validate}
    >
      {({ isSubmitting, isValid }) => {
        // const onClick = () => handleSubmit()
        return (
          <Form className='d-flex flex-column'>
            <Step step={step} />
            {error ? <div className='text-danger my-2'>{error}</div> : null}
            <button
              type='submit'
              className='btn w-100 btn-info mt-2'
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? <Loader /> : isLastStep ? 'Submit' : 'Continue'}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default KYCForm

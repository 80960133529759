// tslint:disable: align
// tslint:disable: ter-arrow-parens
import { useEffect } from 'react'
import { useAsyncReducer } from '../../utils/asyncReducer'
import isIOS from '@xyo-network/tool-storybook-react/dist/lib/utils/isIOS'
import get from 'lodash/get'
import first from 'lodash/first'

let stream
let streamPromise
const getUserMedia = async() => {
  // await new Promise((res: any) => {
  //   const resolve = () => {
  //     document.removeEventListener('touchstart', resolve)
  //     document.removeEventListener('mousemove', resolve)
  //     res()
  //   }
  //   document.addEventListener('touchstart', resolve)
  //   document.addEventListener('mousemove', resolve)
  // })
  return navigator.mediaDevices.getUserMedia({ audio: true, video: true })
}

const getRTCPeerConnection = () => {
  return (
    get(window, 'RTCPeerConnection') ||
    get(window, 'webkitRTCPeerConnection') ||
    get(window, 'mozRTCPeerConnection')
  )
}

const isIp = (ip: string) => (ip || '').split('.').length === 4

function useUserMedia() {
  if (stream) return stream
  if (streamPromise) throw streamPromise
  streamPromise = getUserMedia()
    .then((_stream: any) => {
      stream = _stream
      return stream
    })
    .catch((err: any) => {
      alert(err.message)
      stream = { error: err.message }
      return stream
    })
  throw streamPromise
}

export async function getLocalIp() {
  const stream = isIOS(window.navigator) ? await getUserMedia() : null
  const ips = await findLocalIpsOnce(stream)
  return first(ips)
}

export function useLocalIp() {
  const [state, { setLoading, setData, setError }] = useAsyncReducer<string>()
  const stream = isIOS(window.navigator) ? useUserMedia() : null

  useEffect(() => {
    setLoading()
    return findLocalIps((err, ips: string[]) => {
      if (err) {
        setError(err.message)
      } else {
        setData(first(ips) || '')
      }
    }, stream)
  }, [stream])
  return state
}

export function findLocalIpsOnce(stream) {
  return new Promise<string[]>((res, rej) => {
    findLocalIps((e, d) => {
      console.log(e, d)
      if (e) rej(e)
      else res(d)
    }, stream)
  })
}

export default function findLocalIps(cb: (e, d) => void, stream: any) {
  let unsubscribed = false
  const addrs = { '0.0.0.0': false }
  const callback = (e, d) => !unsubscribed && cb(e, d)
  const foundHosts = (hosts: string[]) => {
    hosts.forEach((host: string) => {
      if (!(host in addrs)) {
        addrs[host] = true
      }
    })

    const valid = Object.keys(addrs)
      .filter((key) => addrs[key])
      .filter(isIp)
    if (valid.length) callback(null, valid)
  }

  const RTCPeerConnection = getRTCPeerConnection()
  if (!RTCPeerConnection) {
    return cb(new Error('RTCPeerConnection not enabled'), null)
  }

  const rtc = new RTCPeerConnection({
    iceServers: [],
  })
  rtc.createDataChannel('')
  if (stream && !stream.error) rtc.addTrack(stream.getAudioTracks()[0], stream)
  if (stream && !stream.error) rtc.addTrack(stream.getVideoTracks()[0], stream)
  rtc.onicecandidate = (evt: any) => {
    if (evt.candidate) {
      const hosts = grepSDP(`a=${evt.candidate.candidate}`)
      foundHosts(hosts)
    }
  }

  rtc
    .createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    })
    .then((offerDesc: any) => {
      const hosts = grepSDP(offerDesc.sdp)
      rtc.setLocalDescription(offerDesc)
      foundHosts(hosts)
    })
    .then((e) => callback(e, null))
  return () => {
    unsubscribed = true
  }
}

function grepSDP(sdp) {
  return sdp
    .split('\r\n')
    .map((line: string) => {
      if (~line.indexOf('a=candidate')) {
        const parts = line.split(' ')
        const addr = parts[4]
        const type = parts[7]
        if (type === 'host') return addr
      } else if (~line.indexOf('c=')) {
        const parts = line.split(' ')
        const addr = parts[2]
        return addr
      }
      return ''
    })
    .filter((v) => v)
}

import React, { useState } from 'react'
import { useXyAccountContext } from '@xyo-network/tool-storybook-react/dist/lib/auth/context'
import Loader from '../../../components/Loader'
import {
  SlideInDown,
  SlideInUp,
} from '@xyo-network/tool-storybook-react/dist/lib/Animate'

const KYCEmailVerification = () => {
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState()
  const xya = useXyAccountContext()
  const actionCodeSettings = {
    url: `${window.location.origin}/kyc`,
  }

  const user = xya.currentUser()!
  const onClick = async () => {
    try {
      setLoading(true)
      await user.sendEmailVerification(actionCodeSettings)
      setLoading(false)
      setEmailSent(true)
    } catch (err) {
      console.error(err)
      setError(err)
      setLoading(false)
    }
  }

  return emailSent ? (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>Email sent!</h4>
      </SlideInDown>
      <SlideInUp timer={200}>
        <p>
          Please check your email and click on the verification link we sent.
        </p>
      </SlideInUp>
    </>
  ) : (
    <>
      <SlideInDown timer={200}>
        <h4 className='mt-0 text-uppercase'>
          Your email has not yet been verified
        </h4>
      </SlideInDown>
      <SlideInUp timer={200}>
        <p>
          Please verify your email. If you need to resend the verification
          email, click below.
        </p>
        {error && (
          <p>An error has occurred: {`${error}`}. Please try again later.</p>
        )}
      </SlideInUp>
      <SlideInUp timer={400}>
        <button
          className='btn btn-primary'
          onClick={onClick}
          disabled={loading}
        >
          {loading ? <Loader /> : 'Resend verification email'}
        </button>
      </SlideInUp>
    </>
  )
}

export default KYCEmailVerification

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BoundWitness from '../../icons/BoundWitness'
import { SlideInUp } from '@xyo-network/tool-storybook-react/dist/lib/Animate'

interface Props {
  className?: string
}

export default ({ className }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <div className='text-center p-5'>
        <SlideInUp>
          <BoundWitness className='large-icon' />
        </SlideInUp>
        <SlideInUp timer={200}>
          <h4 className='mb-0 text-capitalize-first'>
            {t('no bound witnesses found')}
          </h4>
        </SlideInUp>
        <SlideInUp timer={400}>
          <p className='mb-4 text-capitalize-first'>
            {t('add a device to create a genesis block.')}
          </p>
        </SlideInUp>
        <SlideInUp timer={600}>
          <Link to='/devices' className='btn bg-transparent text-uppercase'>
            <i className='fa fa-plus-circle' /> {t('connect more devices')}
          </Link>
        </SlideInUp>
      </div>
    </div>
  )
}
